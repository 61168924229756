import {
  GET_PATIENT_REQUEST,
  GET_PATIENT_REQUEST_SUCCESS,
  GET_PATIENT_REQUEST_FAILURE,
  VIEW_PATIENT_REQUEST,
  VIEW_PATIENT_REQUEST_SUCCESS,
  VIEW_PATIENT_REQUEST_FAILURE,
  GET_PATIENT_CACHE_DATA_CLEAR_REQUEST,
  VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST,
} from '../../actions/actionTypes'
import { PatientInterface, patientActions } from '../../schema//patient/patientSchema'

const initialState: PatientInterface = {
  getPatientLoader: false,
  getPatientData: null,
  getPatientError: null,
  viewPatientLoader: false,
  viewPatientData: null,
  viewPatientError: null,
}
const patientReducer = (state = initialState, action: patientActions) => {
  switch (action.type) {
    case GET_PATIENT_REQUEST:
      return {
        ...state,
        getPatientLoader: true,
      }
    case GET_PATIENT_REQUEST_SUCCESS:
      return {
        ...state,
        getPatientLoader: false,
        getPatientData: action.payload?.getPatientData,
        getPatientMeta: action.payload?.getPatientMeta,
        getPatientError: null,
      }
    case GET_PATIENT_REQUEST_FAILURE:
      return {
        ...state,
        getPatientLoader: false,
        getPatientData: null,
        getPatientError: action.payload.getPatientError,
      }

    case GET_PATIENT_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getPatientData: null,
      }
    case VIEW_PATIENT_REQUEST:
      return {
        ...state,
        viewPatientLoader: true,
      }
    case VIEW_PATIENT_REQUEST_SUCCESS:
      return {
        ...state,
        viewPatientLoader: false,
        viewPatientData: action.payload?.viewPatientData,
        viewPatientError: null,
      }
    case VIEW_PATIENT_REQUEST_FAILURE:
      return {
        ...state,
        viewPatientLoader: false,
        viewPatientData: null,
        viewPatientError: action.payload.viewPatientError,
      }
    case VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        viewPatientData: null,
      }
    default:
      return {
        ...state,
      }
  }
}
export default patientReducer
