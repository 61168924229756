import { Grid } from '@mui/material'
import { isSupported } from 'firebase/messaging'
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import Loader from './components/common/Loader'
import { onMessageListener, requestForToken } from './firebase'
import ErrorUi from './pages/ErrorUi'
import MyProfile from './pages/myProfile/MyProfile'
import { updateDeviceToken } from './redux/actions/notification/notificationAction'
import ProtectedRoute from './router/ProtectedRoute'
import TreatmentAndProcedure from './pages/treatmentAndProcedureCodes'

const Login = React.lazy(() => import('./pages/auth/Login'))
const ForgotPassword = React.lazy(() => import('./pages/auth/ForgotPassword'))
const ResetPassword = React.lazy(() => import('./pages/auth/ResetPassword'))
const ChangePassword = React.lazy(() => import('./pages/auth/ChangePassword'))
const PageNotFound = React.lazy(() => import('./components/common/PageNotFound'))
const MainLayout = React.lazy(() => import('./components/layout/MainLayout'))
const LayoutWithAuthHeader = React.lazy(() => import('./components/layout/LayoutWithAuthHeader'))
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'))
const ClinicRequest = React.lazy(() => import('./pages/clinic/clinicRequest/index'))
const ClinicRequestDetails = React.lazy(() => import('./pages/clinic/clinicRequest/ClinicRequestDetails'))
const Clinic = React.lazy(() => import('./pages/clinic/manageClinic/index'))
const ViewClinic = React.lazy(() => import('./pages/clinic/manageClinic/ViewClinic'))
const Member = React.lazy(() => import('./pages/member/index'))
const ViewMember = React.lazy(() => import('./pages/member/ViewMember'))
const Patient = React.lazy(() => import('./pages/patient/index'))
const ViewPatient = React.lazy(() => import('./pages/patient/ViewPatient'))
const Owner = React.lazy(() => import('./pages/owner/index'))
const ViewOwner = React.lazy(() => import('./pages/owner/ViewOwner'))
const Subscription = React.lazy(() => import('./pages/subscription'))
const ManageCMS = React.lazy(() => import('./pages/cms/index'))
const ContactUs = React.lazy(() => import('./pages/contactUs/ContactUs'))
const Resources = React.lazy(() => import('./pages/Resources'))
const EditCMS = React.lazy(() => import('./pages/cms/EditCms'))
const QSurvey = React.lazy(() => import('./components/clinic/QSurvey'))
const MsaAgreement = React.lazy(() => import('./components/clinic/MSAAgreement'))

// tslint:disable-next-line:class-name
interface routeInterface {
  path: string
  name: string
  exact: boolean
  component?: any
  private: boolean
  layout?: any
}

const paths = [
  {
    path: '/login',
    name: 'Login',
    exact: true,
    private: false,
    layout: <LayoutWithAuthHeader component={<Login />} />,
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    exact: true,
    private: false,
    layout: <LayoutWithAuthHeader component={<ForgotPassword />} />,
  },
  {
    path: '/recover-password/:recovery_token',
    name: 'Reset Password',
    exact: true,
    private: false,
    layout: <LayoutWithAuthHeader component={<ResetPassword />} />,
  },
  {
    path: '/change-password',
    name: 'Change Password',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Change Password'} component={<ChangePassword />} />,
  },
  // dashboard
  {
    path: '/dashboard',
    name: 'Dashboard',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Dashboard'} component={<Dashboard />} />,
  },
  // clinic
  {
    path: '/clinics/request',
    name: 'Clinic Request',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Clinic Request'} component={<ClinicRequest />} />,
  },
  {
    path: '/clinics/request/details/:id',
    name: 'Clinic Request Details',
    exact: true,
    private: true,
    layout: (
      <MainLayout restrictHeader={false} restrictSidebar={false} name={'Clinic Request Details'} component={<ClinicRequestDetails />} />
    ),
  },
  {
    path: '/clinics/request/msa-agreement/:id',
    name: 'MSA Agreement',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'MSA Agreement'} component={<MsaAgreement />} />,
  },
  {
    path: '/clinics/request/practice-information/:id',
    name: 'Practice Information',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Practice Information'} component={<QSurvey />} />,
  },
  {
    path: '/clinic',
    name: 'Clinic ',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Manage clinics'} component={<Clinic />} />,
  },
  {
    path: '/clinic/view/:id',
    name: 'Clinic Details',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Clinic Details'} component={<ViewClinic />} />,
  },
  {
    path: '/clinic/view/practice-information/:id',
    name: 'Practice Information',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Practice Information'} component={<QSurvey />} />,
  },
  {
    path: '/clinic/view/msa-agreement/:id',
    name: 'MSA Agreement',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'MSA Agreement'} component={<MsaAgreement />} />,
  },
  // member

  {
    path: '/member',
    name: 'Member ',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Manage members'} component={<Member />} />,
  },

  {
    path: '/member/view/:id',
    name: 'Member Details',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Member Details'} component={<ViewMember />} />,
  },
  // Patient
  {
    path: '/patients',
    name: 'Patient ',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Manage Patients'} component={<Patient />} />,
  },

  {
    path: '/patients/view/:id/:clinicId',
    name: 'Patient Details',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Patient Details'} component={<ViewPatient />} />,
  },
  // Owner
  {
    path: '/owners',
    name: 'Owners ',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Manage Owners'} component={<Owner />} />,
  },

  {
    path: '/owners/view/:id',
    name: 'Owner Details',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Owner Details'} component={<ViewOwner />} />,
  },
  {
    path: '/profile',
    name: 'My Profile',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'My Profile'} component={<MyProfile />} />,
    module: 0,
  },
  // Subscription
  {
    path: '/subscription',
    name: 'Manage Subscription',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Manage Subscription'} component={<Subscription />} />,
    module: 0,
  },
  // manageCMS
  {
    path: '/cms',
    name: 'Manage CMS',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Manage CMS'} component={<ManageCMS />} />,
    module: 0,
  },
  // ContactUs
  {
    path: '/contact-us',
    name: 'ContactUs',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'ContactUs'} component={<ContactUs />} />,
    module: 0,
  },
  // AboutUs
  {
    path: '/cms/edit/:id/:title',
    name: 'EditCMS',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'EditCMS'} component={<EditCMS />} />,
    module: 0,
  },
  {
    path: '/*',
    name: 'Page Not Found',
    exact: false,
    private: false,
    layout: <PageNotFound />,
  },
  {
    path: '/resources',
    name: 'Resources',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Resources'} component={<Resources />} />,
    module: 0,
  },
  {
    path: '/treatment-procedure-codes',
    name: 'Recommendations Mapping',
    exact: true,
    private: true,
    layout: <MainLayout restrictHeader={false} restrictSidebar={false} name={'Recommendations Mapping'} component={<TreatmentAndProcedure />} />,
    module: 0,
  },
]

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    isSupported()?.then((res: any) => {
      if (res) {
        requestForToken().then(() => {
          onMessageListener()
            .then((data: any) => {
              // console.log('data for notification', data)
            })
            .catch(err => {
              // console.log('err while get token', err)
            })
        })
      }
    })
  }, [])

  return (
    <React.Suspense fallback={<Loader />}>
      <ErrorUi>
        <Routes>
          <Route path='/' element={<Navigate to='/login' replace={true} />} />
          {paths?.map((res: routeInterface, i: number) => (
            <Route
              key={i}
              path={res?.path}
              element={
                res.private ? (
                  <ProtectedRoute path={res}>
                    <span>{res?.layout}</span>
                  </ProtectedRoute>
                ) : (
                  <Grid>{res.layout}</Grid>
                )
              }
            />
          ))}
        </Routes>
      </ErrorUi>
    </React.Suspense>
  )
}

export default App
