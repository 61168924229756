import { LOGIN_REQUEST, LOGIN_REQUEST_SUCCESS, LOGIN_REQUEST_FAILURE } from '../actionTypes'
import {
  LoginRequestPayload,
  LoginRequestSuccessPayload,
  LoginFailurePayload,
  LoginRequest,
  LoginRequestSuccess,
  LoginRequestFailure,
} from '../../schema/auth/loginSchema'

export const loginRequest = (payload: LoginRequestPayload): LoginRequest => ({
  type: LOGIN_REQUEST,
  payload,
})

export const loginRequestSuccess = (payload: LoginRequestSuccessPayload): LoginRequestSuccess => ({
  type: LOGIN_REQUEST_SUCCESS,
  payload,
})

export const loginRequestFailure = (payload: LoginFailurePayload): LoginRequestFailure => ({
  type: LOGIN_REQUEST_FAILURE,
  payload,
})
