import { all, call, put, takeEvery } from 'redux-saga/effects'
import { RESET_PASSWORD_REQUEST } from '../../actions/actionTypes'
import { resetPasswordRequestSuccess, resetPasswordRequestFailure } from '../../actions/authActions/resetPasswordAction'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'

function* resetPasswordRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v2/auth/reset/password`, payload.payload)
    successToaster(response.data?.meta?.message)
    yield call(payload.payload.callback, response.data)
    yield put(
      resetPasswordRequestSuccess({
        resetPasswordData: response.data,
      })
    )
  } catch (e: any) {
    //TODO: update toast according to status code
    errorToaster(e?.message)
    yield put(
      resetPasswordRequestFailure({
        resetPasswordError: e?.message,
      })
    )
  }
}

function* postResetPasswordSaga() {
  yield takeEvery(RESET_PASSWORD_REQUEST, resetPasswordRequestSaga)
}

export default function* resetPasswordSaga() {
  yield all([postResetPasswordSaga()])
}
