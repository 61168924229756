import {
  EDIT_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_REQUEST,
  GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE,
  GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS,
  UPDATE_EDIT_SUBSCRIPTION_PLANS,
  PUT_SUBSCRIPTION_PLANS_REQUEST,
  PUT_SUBSCRIPTION_PLANS_REQUEST_SUCCESS,
  PUT_SUBSCRIPTION_PLANS_REQUEST_FAILURE,
  ADD_SUBSCRIPTION_PLAN,
  CHANGE_ADD_MODE_VALUE,
  CHANGE_TRAIL_DAYS,
  GET_TRAIL_DAYS_REQUEST,
  GET_TRAIL_DAYS_REQUEST_SUCCESS,
  GET_TRAIL_DAYS_REQUEST_FAILURE,
  PUT_TRAIL_DAYS_REQUEST,
  PUT_TRAIL_DAYS_REQUEST_SUCCESS,
  PUT_TRAIL_DAYS_REQUEST_FAILURE,
} from '../../actions/actionTypes'
import { SubscriptionPlanInterface } from '../../schema/subscriptionPlans/subscriptionPlansSchema'

const initialState: SubscriptionPlanInterface = {
  getSubscriptionPlanLoader: false,
  getSubscriptionPlanData: [],
  getSubscriptionPlanError: null,
  putSubscriptionPlanLoader: false,
  putSubscriptionPlanData: [],
  putSubscriptionPlanError: null,
  editPlanData: {},
  addMode: false,
  trailDaysData: null,
  trail_days: null,
  allow_trail: false,
  trailDaysLoader: false,
  trailDaysError: null,
  component_id: '',
}
const subscriptionPlansReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SUBSCRIPTION_PLANS_REQUEST:
      return {
        ...state,
        getSubscriptionPlanLoader: true,
      }
    case GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS:
      return {
        ...state,
        getSubscriptionPlanLoader: false,
        getSubscriptionPlanData: action.payload,
        subscriptionPlan: action.payload,
      }
    case GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE:
      return {
        ...state,
        getSubscriptionPlanLoader: false,
        getSubscriptionPlanData: [],
      }
    case EDIT_SUBSCRIPTION_PLANS:
      const oldPlan = state?.getSubscriptionPlanData || []
      oldPlan[oldPlan.length - 1] = {
        product_id: '',
        plan_description: '<p><ul><li>Plan features</ul></li></p>',
        pms_integration: false,
        price: {
          month: null,
          year: null,
        },
        stripe_price_id: '',
        stripe_product_id: '',
        title: 'Plan Title',
      }
      return {
        ...state,
        editPlanData: action?.payload,
        subscriptionPlan: oldPlan,
      }
    case UPDATE_EDIT_SUBSCRIPTION_PLANS:
      const payloadOBJ = action?.payload as any
      return {
        ...state,
        editPlanData: {
          ...state.editPlanData,
          [payloadOBJ.key]: payloadOBJ.value,
        },
      }
    case PUT_SUBSCRIPTION_PLANS_REQUEST:
      return {
        ...state,
        putSubscriptionPlanLoader: true,
      }
    case PUT_SUBSCRIPTION_PLANS_REQUEST_SUCCESS:
      return {
        ...state,
        addMode: false,
        putSubscriptionPlanLoader: false,
        putSubscriptionPlanData: action.payload,
        putSubscriptionPlanError: null,
      }
    case PUT_SUBSCRIPTION_PLANS_REQUEST_FAILURE:
      return {
        ...state,
        putSubscriptionPlanLoader: false,
        putSubscriptionPlanData: [],
        putSubscriptionPlanError: action.payload,
      }
    case ADD_SUBSCRIPTION_PLAN:
      const existingPlan = state?.getSubscriptionPlanData || []
      existingPlan[existingPlan.length - 1] = {
        ID: '',
        plan_description: '',
        pms_integration: false,
        price: {
          month: null,
          year: null,
        },
        price_month: null,
        price_year: null,
        stripe_price_id: '',
        stripe_product_id: '',
        title: '',
      }
      return {
        ...state,
        editPlanData: {
          plan_description: '',
          pms_integration: false,
          price: {
            month: null,
            year: null,
          },
          price_month: null,
          price_year: null,
          stripe_price_id: '',
          stripe_product_id: '',
          title: '',
        },
        addMode: true,
        subscriptionPlan: existingPlan,
      }
    case CHANGE_ADD_MODE_VALUE:
      const planTemp = state?.getSubscriptionPlanData || []
      planTemp[planTemp.length - 1] = {
        product_id: '',
        plan_description: '<p><ul><li>Plan features</ul></li></p>',
        pms_integration: false,
        price: {
          month: null,
          year: null,
        },
        stripe_price_id: '',
        stripe_product_id: '',
        title: 'Plan Title',
      }
      return {
        ...state,
        addMode: false,
        subscriptionPlan: planTemp,
      }

    case CHANGE_TRAIL_DAYS:
      return {
        ...state,
        ...action.payload,
      }
    case GET_TRAIL_DAYS_REQUEST:
      return {
        ...state,
        trailDaysLoader: true,
      }
    case GET_TRAIL_DAYS_REQUEST_SUCCESS:
      return {
        ...state,
        trailDaysLoader: false,
        trailDaysData: action.payload,
        trailDaysError: null,
      }
    case GET_TRAIL_DAYS_REQUEST_FAILURE:
      return {
        ...state,
        trailDaysLoader: false,
        trailDaysData: null,
        trailDaysError: action.payload,
      }
    case PUT_TRAIL_DAYS_REQUEST:
      return {
        ...state,
        trailDaysLoader: true,
      }
    case PUT_TRAIL_DAYS_REQUEST_SUCCESS:
      return {
        ...state,
        trailDaysLoader: false,
        trailDaysData: action.payload,
        trailDaysError: null,
      }
    case PUT_TRAIL_DAYS_REQUEST_FAILURE:
      return {
        ...state,
        trailDaysLoader: false,
        trailDaysData: null,
        trailDaysError: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
export default subscriptionPlansReducer
