export const requireMessage = (msg) => {
    let requiredMsg = `Please enter your ${msg}.`
    return requiredMsg
}
export const notAllowNumberMsg = (field) => {
    const msg = `${field} can not allow numbers.`
    return msg
}
export const notAllowSpecialChar = (field) => {
    const msg = `${field} can not allow special character.`
    return msg
}
export const requireMessageForSelect = (msg) => {
    let requiredMsg = `Please select ${msg}.`
    return requiredMsg
}
export const requireValidMsg = (msg) => {
    let requiredMsg = `Please enter ${msg}.`
    return requiredMsg
}
export const matchPassword = "Password and Confirm password doesn’t match."
export const passwordValidation = 'Password must be minimum eight, at least one uppercase letter, one lowercase letter, one number and one special character.'
export const passwordNotSame = 'New Password cannot be same as old password.'
export const validOTP = 'Please enter valid OTP.'

//add clinic

export const URLValidation = "Please enter valid website"