import {
  GET_MEMBER_REQUEST,
  GET_MEMBER_REQUEST_SUCCESS,
  GET_MEMBER_REQUEST_FAILURE,
  VIEW_MEMBER_REQUEST,
  VIEW_MEMBER_REQUEST_FAILURE,
  VIEW_MEMBER_REQUEST_SUCCESS,
  GET_MEMBER_CACHE_DATA_CLEAR_REQUEST,
  VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST,
} from '../../actions/actionTypes'

import { MemberInterface, memberActions } from '../../schema/member/memberSchema'

const initialState: MemberInterface = {
  getMemberLoader: false,
  getMemberData: null,
  getMemberMeta: null,
  getMemberError: null,
  viewMemberLoader: false,
  viewMemberData: null,
  viewMemberError: null,
}

const memberReducer = (state = initialState, action: memberActions) => {
  switch (action.type) {
    case GET_MEMBER_REQUEST:
      return {
        ...state,
        getMemberLoader: true,
      }
    case GET_MEMBER_REQUEST_SUCCESS:
      return {
        ...state,
        getMemberLoader: false,
        getMemberData: action.payload?.getMemberData,
        getMemberMeta: action.payload?.getMemberMeta,
        getMemberError: null,
      }
    case GET_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        getMemberLoader: false,
        getMemberData: null,
        getMemberError: action.payload.getMemberError,
      }
    case GET_MEMBER_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getMemberData: null,
      }

    case VIEW_MEMBER_REQUEST:
      return {
        ...state,
        viewMemberLoader: true,
      }
    case VIEW_MEMBER_REQUEST_SUCCESS:
      return {
        ...state,
        viewMemberLoader: false,
        viewMemberData: action.payload?.viewMemberData,
        viewMemberError: null,
      }
    case VIEW_MEMBER_REQUEST_FAILURE:
      return {
        ...state,
        viewMemberLoader: false,
        viewMemberData: null,
        viewMemberError: action.payload.viewMemberError,
      }
    case VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        viewMemberData: null,
      }

    default:
      return {
        ...state,
      }
  }
}

export default memberReducer
