export const getYesNo = (val: any) => {
  if (val) {
    return 'yes'
  } else if (val === false) {
    return 'no'
  } else {
    return ''
  }
}

export const passTrueFalse = (val: any) => {
  if (val === 'yes') {
    return true
  } else if (val === 'no') {
    return false
  } else {
    return null
  }
}

export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: '10rem',
      overflow: 'auto',
      boxShadow: '10px 12px 25px 0px rgb(0 0 0 / 25%)',
    },
  },
}

export const countryList = [
  {
    label: 'Canada',
    value: 'CAN',
  },
  {
    label: 'USA',
    value: 'USA',
  },
]

export const isAdditionalList = [
  {
    label: 'Yes',
    value: 'yes',
  },
  {
    label: 'No',
    value: 'no',
  },
]

export const crossFunctionalList = [
  {
    label: 'Yes',
    value: 'true',
  },
  {
    label: 'No',
    value: 'false',
  },
]

export const typeList = [
  {
    label: 'Global',
    value: 'global',
  },
  {
    label: 'OraQ Specific',
    value: 'oraq',
  },
  {
    label: 'Referral',
    value: 'referral',
  },
]

export const dropdownTypeList = [
  {
    label: 'Hygiene',
    value: 'hygiene',
  },
  {
    label: 'Xray',
    value: 'xray',
  },
  {
    label: 'Exam',
    value: 'exam',
  },
  {
    label: 'Adjunctive',
    value: 'adjunctive',
  },
]

export const treatmentTitle = [
  {
    label: 'Recommendation Title',
    value: "recommendation_title"
  },
  {
    label: 'Default Code Description',
    value: "code_description"
  },
  {
    label: 'Other',
    value: 'other'
  },
]
