import { all, put, takeEvery } from 'redux-saga/effects'
import { GET_APPOINTMENT_REQUEST } from '../../actions/actionTypes'
import API from '../../../utils/api'
import {
  getAppointmentRequestSuccess,
  getAppointmentRequestFailure,
  getAppointmentCacheDataClearRequest,
} from '../../actions/appointment/appointmentAction'
import { errorToaster } from '../../../utils/helper'

// Get appointment
function* getAppointmentRequestSaga(payload: any): any {
  yield put(getAppointmentCacheDataClearRequest())
  try {
    const params = [
      { key: 'search', value: payload?.payload?.search },
      { key: 'page', value: payload?.payload?.page },
      { key: 'size', value: payload?.payload?.size },
      { key: 'status', value: payload?.payload?.status },
      { key: 'timezone', value: Intl.DateTimeFormat().resolvedOptions().timeZone },
    ]
    let tmpUrl = `&`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `/v2/appointment/owner/get/all?patient_id=${payload.payload.patient_id}${tmpUrl}`
    let url = newUrl
    const response = yield API.get(url)
    yield put(getAppointmentRequestSuccess({ getAppointmentData: response?.data?.detail, getAppointmentMeta: response?.data?.meta }))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(getAppointmentRequestFailure({ getAppointmentError: e?.message }))
  }
}

function* getAppointmentSaga() {
  yield takeEvery(GET_APPOINTMENT_REQUEST, getAppointmentRequestSaga)
}

export default function* appointmentSaga() {
  yield all([getAppointmentSaga()])
}
