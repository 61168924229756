import { all, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'
import { passTrueFalse } from '../../../utils/ProcedureTreatmentConstant/ProcedureTreatmentConstant'
import { saveAs } from 'file-saver'; // For saving the file as CSV
import {
  ADD_EDIT_PROCEDURE_CODE_REQUEST,
  GET_ALL_PROCEDURE_DATA_REQUEST,
  ADD_EDIT_TREATMENT_REQUEST,
  GET_PROCEDURE_CODE_LIST_REQUEST,
  GET_TREATMENT_LIST_REQUEST,
  DOWNLOAD_CSV_FILE_REQUEST,
  UPLOAD_CSV_FILE_REQUEST,
} from '../../actions/actionTypes'
import {
  getProcedureCodeListRequestFailureAction,
  getProcedureCodeListRequestSuccessAction,
  getTreatmentCodeListRequestSuccessAction,
  getTreatmentCodeListRequestFailureAction,
  AddEditProcedureCodeRequestSuccessAction,
  AddEditProcedureCodeRequestFailureAction,
  getAllProcedureDataRequestSuccessAction,
  getAllProcedureDataRequestFailureAction,
  addEditTreatmentRequestSuccessAction,
  addEditTreatmentRequestFailureAction,
  downloadCsvFileRequestSuccessAction,
  downloadCsvFileRequestFailureAction,
  uploadCsvFileRequestSuccessAction,
  uploadCsvFileRequestFailureAction,
} from '../../actions/treatmentAndProcedureCodes/treatmentAndProcedureCodesAction'

function* getProcedureCodeList(payload: any): any {
  try {
    const params = [
      { key: 'search', value: payload?.payload?.search },
      { key: 'page', value: payload?.payload?.page },
      { key: 'size', value: payload?.payload?.size },
      { key: 'country', value: payload?.payload?.country },
    ]
    let tmpUrl = `&`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `/v2/admin/procedure-codes?${tmpUrl}`
    let url = newUrl
    const response = yield API.get(url)

    yield put(
      getProcedureCodeListRequestSuccessAction({
        data: response?.data?.detail,
        meta: response?.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(getProcedureCodeListRequestFailureAction(e?.message))
  }
}

function* getTreatmentList(payload: any): any {
  try {
    const params = [
      { key: 'search', value: payload?.payload?.search },
      { key: 'page', value: payload?.payload?.page },
      { key: 'size', value: payload?.payload?.size },
      { key: 'country', value: payload?.payload?.country },
    ]
    let tmpUrl = `&`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `/v2/admin/treatments?${tmpUrl}`
    let url = newUrl
    const response = yield API.get(url)
    yield put(
      getTreatmentCodeListRequestSuccessAction({
        data: response?.data?.detail,
        meta: response?.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(getTreatmentCodeListRequestFailureAction(e?.message))
  }
}

function* addEditProcedureCode(payload: any): any {
  const payloadData = {
    country: payload.payload?.country,
    code_type: payload.payload?.code_type,
    hygiene_tag: payload.payload?.hygiene_tag === '' ? null : payload.payload?.hygiene_tag,
    cross_functional: payload.payload?.hygiene_tag === '' ? false : payload?.payload?.cross_functional,
    is_additional: passTrueFalse(payload.payload?.is_additional),
    code: payload.payload?.code,
    fees: payload.payload?.fees,
    description: payload.payload?.description,
  }
  let UpdatedPayload = {}
  if (payload?.payload?.ID) {
    UpdatedPayload = {
      ...payloadData,
      ID: payload?.payload?.ID,
    }
  } else {
    UpdatedPayload = payloadData
  }

  try {
    const response = yield API.post(`v2/admin/procedure-code`, UpdatedPayload)
    yield put(AddEditProcedureCodeRequestSuccessAction(response?.data?.detail))
    if (payload.payload.callback) {
      payload.payload.callback()
    }
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(AddEditProcedureCodeRequestFailureAction(e?.message))
  }
}

function* addEditTreatmentData(payload: any): any {
  const treatmentData = payload?.payload?.treatmentDataLocal
  const handleComma = (data: any) => {
    const newData = data && data?.map((item: any) => item.code).toString()
    return newData
  }

  const handleCustomField = (customData: any) => {
    if (customData !== undefined) {
      const custom_title = {} as any
      treatmentData?.multiple_codes?.forEach((item: any) => {
        custom_title[item.code] = item.description
      })

      return custom_title
    }
    return customData
  }

  const payloadData = {
    default_code: treatmentData?.default_code === undefined ? null : treatmentData?.default_code?.code,
    multiple_codes: handleComma(treatmentData?.multiple_codes),
    additional_codes: handleComma(treatmentData?.additional_codes),
    country: treatmentData?.country,
    title: treatmentData?.title,
    hygiene_tag: treatmentData?.hygiene_tag !== '' ? treatmentData?.hygiene_tag : null,
    tx_title: treatmentData?.tx_title,
    custom_title: handleCustomField(treatmentData?.custom_title),
  }

  let UpdatedPayload = {}
  if (treatmentData?.ID) {
    UpdatedPayload = {
      ...payloadData,
      ID: treatmentData?.ID,
    }
  } else {
    UpdatedPayload = payloadData
  }

  try {
    const response = yield API.post(`v2/admin/treatment`, UpdatedPayload)
    yield put(addEditTreatmentRequestSuccessAction(response?.data?.detail))
    successToaster(response?.data?.meta?.message)
    if (payload.payload.callback) {
      payload.payload.callback()
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(addEditTreatmentRequestFailureAction(e?.message))
  }
}

function* getAllProcedureData(payload: any): any {
  try {
    const newUrl = `/v2/admin/procedure/codes?country=${payload?.payload?.country}&is_additional=${payload?.payload?.is_additional}`
    let isHygieneTag = `${newUrl}&hygiene_tag=${payload?.payload?.hygiene_tag}`

    const response = yield API.get(payload?.payload?.hygiene_tag ? isHygieneTag : newUrl)

    yield put(
      getAllProcedureDataRequestSuccessAction({
        isAdditional: payload?.payload?.is_additional,
        data: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(getAllProcedureDataRequestFailureAction(e?.message))
  }
}

function* getCSVData(): any {
  try {
    const response = yield API.get('/v2/admin/download/procedure-codes-csv')

    // Convert the binary response data to a Blob
    const blob = new Blob([response.data], { type: 'text/csv' });

    // Use the 'file-saver' library to save the Blob as a CSV file
    saveAs(blob, 'data.csv');

    yield put(
      downloadCsvFileRequestSuccessAction(response?.data)
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(downloadCsvFileRequestFailureAction(e?.message))
  }
}

function* uploadCSVData(payload: any): any {
  try {
    const response = yield API.post('/v2/admin/upload/csv/procedure-codes', payload?.payload?.file)
    yield put(
      uploadCsvFileRequestSuccessAction(response?.data?.detail)
    )
    successToaster(response?.data?.meta?.message)
    if (payload.payload.callback) {
      payload.payload.callback()
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(uploadCsvFileRequestFailureAction(e?.message))
  }
}

function* getProcedureCodeListWatch() {
  yield takeEvery(GET_PROCEDURE_CODE_LIST_REQUEST, getProcedureCodeList)
}

function* getTreatmentListWatch() {
  yield takeEvery(GET_TREATMENT_LIST_REQUEST, getTreatmentList)
}

function* addEditProcedureCodeWatch() {
  yield takeEvery(ADD_EDIT_PROCEDURE_CODE_REQUEST, addEditProcedureCode)
}

function* addEditTreatmentDataWatch() {
  yield takeEvery(ADD_EDIT_TREATMENT_REQUEST, addEditTreatmentData)
}

function* getAllProcedureDataWatch() {
  yield takeEvery(GET_ALL_PROCEDURE_DATA_REQUEST, getAllProcedureData)
}

function* getCSVDataWatch() {
  yield takeEvery(DOWNLOAD_CSV_FILE_REQUEST, getCSVData)
}

function* uploadCSVDataWatch() {
  yield takeEvery(UPLOAD_CSV_FILE_REQUEST, uploadCSVData)
}

export default function* treatmentAndProcedureCodesSaga() {
  yield all([
    getProcedureCodeListWatch(),
    getTreatmentListWatch(),
    addEditProcedureCodeWatch(),
    addEditTreatmentDataWatch(),
    getAllProcedureDataWatch(),
    getCSVDataWatch(),
    uploadCSVDataWatch()
  ])
}
