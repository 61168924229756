import { all, call, put, takeEvery } from 'redux-saga/effects'
import { successToaster } from '../../../utils/helper';
import API from '../../../utils/api'
import {
  GET_RESOURCE_DATA_REQUEST,
  ADD_RESOURCE_DATA_REQUEST,
  PUT_RESOURCE_DATA_REQUEST,
  DELETE_RESOURCE_DATA_REQUEST,
  REMOVE_RESOURCE_THUMBNAIL_REQUEST
} from '../../actions/actionTypes'
import {
  getResourceListTypeSuccessAction,
  getResourceListTypeFailureAction,
  getResourceListCacheDataClearRequest,
  addResourceListTypeSuccessAction,
  addResourceListTypeFailureAction,
  putResourceListTypeSuccessAction,
  putResourceListTypeFailureAction,
  deleteResourceListTypeSuccessAction,
  deleteResourceListTypeFailureAction,
  removeResourceThumbnailTypeSuccessAction,
  removeResourceThumbnailTypeFailureAction
} from '../../actions/Resources/resourceAction'
import { errorToaster } from '../../../utils/helper'

// get resources data
function* getResourcesTypeRequest(payload: any): any {
  yield put(getResourceListCacheDataClearRequest())
  try {
    const params = [
      { key: 'portal', value: payload?.payload?.portalName }
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })

    const newUrl = `v2/resources${tmpUrl}`
    let url = newUrl
    const response = yield API.get(url)

    yield put(
      getResourceListTypeSuccessAction({
        resourceData: response?.data?.detail,
        resourceMeta: response?.data?.meta,
      })
    )
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getResourceListTypeFailureAction({
        resourceError: e?.message,
      })
    )
  }
}

// Create resources
function* createResourcesRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v2/resources`, payload?.payload?.addResourceData)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
    yield put(addResourceListTypeSuccessAction({ addResourceData: response?.data }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(addResourceListTypeFailureAction({ addResourceError: e?.message }))
  }
}

// Put resources
function* putResourcesRequestSaga(payload: any): any {
  try {
    const response = yield API.patch(`/v2/resources/${payload?.payload?.resourceId}`, payload?.payload?.putResourceData)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
    yield put(putResourceListTypeSuccessAction({ putResourceData: response?.data }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(putResourceListTypeFailureAction({ putResourceError: e?.message }))
  }
}

// Delete resources
function* deleteResourcesRequestSaga(payload: any): any {
  try {
    const response = yield API.delete(`/v2/resources/${payload?.payload?.deleteResourceID}`)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
    yield put(deleteResourceListTypeSuccessAction({ deleteResourceData: response?.data }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(deleteResourceListTypeFailureAction({ deleteResourceError: e?.message }))
  }
}

// Remove resources thumbnail
function* removeResourcesThumbnailRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v2/resources/remove/thumbnail/${payload?.payload?.resourceID}`)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
    yield put(removeResourceThumbnailTypeSuccessAction({ removeResourceThumbnailData: response?.data }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(removeResourceThumbnailTypeFailureAction({ removeResourceThumbnailError: e?.message }))
  }
}

function* getResourcesSaga() {
  yield takeEvery(GET_RESOURCE_DATA_REQUEST, getResourcesTypeRequest)
}

function* createResourcesSaga() {
  yield takeEvery(ADD_RESOURCE_DATA_REQUEST, createResourcesRequestSaga)
}

function* putResourcesSaga() {
  yield takeEvery(PUT_RESOURCE_DATA_REQUEST, putResourcesRequestSaga)
}

function* deleteResourcesSaga() {
  yield takeEvery(DELETE_RESOURCE_DATA_REQUEST, deleteResourcesRequestSaga)
}

function* removeResourceThumbnailSaga() {
  yield takeEvery(REMOVE_RESOURCE_THUMBNAIL_REQUEST, removeResourcesThumbnailRequestSaga)
}

export default function* resourcesSaga() {
  yield all([
    getResourcesSaga(),
    createResourcesSaga(),
    putResourcesSaga(),
    deleteResourcesSaga(),
    removeResourceThumbnailSaga()
  ])
}
