import axios from 'axios'
import { getLocalStorageItem, url } from './helper'

const api = axios.create({
  baseURL: url,
  headers: {
    'Access-Control-Allow-Origin': process.env.REACT_APP_API_ENDPOINT as string,
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  },
})
api.interceptors.request.use(
  function (config: any) {
    config.headers.Authorization = `Bearer ${getLocalStorageItem('adminToken')}`
    return config
  },
  function (error: any) {
    return Promise.reject(error)
  }
)
api.interceptors.response.use(
  (response: any) => {
    return response
  },
  function (error: any) {
    if (error?.response?.status === 401) {
      localStorage.removeItem('adminToken')
      return Promise.reject(error?.response?.data)
    } else {
      return Promise.reject(error?.response?.data)
    }
  }
)

export default api
