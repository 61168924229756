// Login
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_REQUEST_SUCCESS = 'LOGIN_REQUEST_SUCCESS'
export const LOGIN_REQUEST_FAILURE = 'LOGIN_REQUEST_FAILURE'

// Forgot Password
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_REQUEST_SUCCESS = 'FORGOT_PASSWORD_REQUEST_SUCCESS'
export const FORGOT_PASSWORD_REQUEST_FAILURE = 'FORGOT_PASSWORD_REQUEST_FAILURE'

// Reset Password
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST'
export const RESET_PASSWORD_REQUEST_SUCCESS = 'RESET_PASSWORD_REQUEST_SUCCESS'
export const RESET_PASSWORD_REQUEST_FAILURE = 'RESET_PASSWORD_REQUEST_FAILURE'

// get all clinic request listing

export const GET_CLINIC_REQ_LIST_REQUEST = 'GET_CLINIC_REQ_LIST_REQUEST'
export const GET_CLINIC_REQ_LIST_REQUEST_SUCCESS = 'GET_CLINIC_REQ_LIST_REQUEST_SUCCESS'
export const GET_CLINIC_REQ_LIST_REQUEST_FAILURE = 'GET_CLINIC_REQ_LIST_REQUEST_FAILURE'
export const GET_CLINIC_REQ_LIST_CACHE_DATA_CLEAR_REQUEST = 'GET_CLINIC_REQ_LIST_CACHE_DATA_CLEAR_REQUEST'

// get clinic request details
export const CLINIC_REQ_DETAILS_REQUEST = 'CLINIC_REQ_DETAILS_REQUEST'
export const CLINIC_REQ_DETAILS_REQUEST_SUCCESS = 'CLINIC_REQ_DETAILS_REQUEST_SUCCESS'
export const CLINIC_REQ_DETAILS_REQUEST_FAILURE = 'CLINIC_REQ_DETAILS_REQUEST_FAILURE'
export const CLINIC_REQ_DETAILS_CACHE_DATA_CLEAR_REQUEST = 'CLINIC_REQ_DETAILS_CACHE_DATA_CLEAR_REQUEST'

// approve clinic request
export const APPROVE_CLINIC_REQUEST = 'APPROVE_CLINIC_REQUEST'
export const APPROVE_CLINIC_SUCCESS = 'APPROVE_CLINIC_SUCCESS'
export const APPROVE_CLINIC_FAILURE = 'APPROVE_CLINIC_FAILURE'

// reject the clinic request
export const REJECT_CLINIC_REQUEST = 'REJECT_CLINIC_REQUEST'
export const REJECT_CLINIC_REQUEST_SUCCESS = 'REJECT_CLINIC_REQUEST_SUCCESS'
export const REJECT_CLINIC_REQUEST_FAILURE = 'REJECT_CLINIC_REQUEST_FAILURE'

// Get all clinic
export const GET_CLINIC_REQUEST = 'GET_CLINIC_REQUEST'
export const GET_CLINIC_REQUEST_SUCCESS = 'GET_CLINIC_REQUEST_SUCCESS'
export const GET_CLINIC_REQUEST_FAILURE = 'GET_CLINIC_REQUEST_FAILURE'
export const GET_CLINIC_CACHE_DATA_CLEAR_REQUEST = 'GET_CLINIC_CACHE_DATA_CLEAR_REQUEST'

// view clinic details
export const VIEW_CLINIC_REQUEST = 'VIEW_CLINIC_REQUEST'
export const VIEW_CLINIC_REQUEST_SUCCESS = 'VIEW_CLINIC_REQUEST_SUCCESS'
export const VIEW_CLINIC_REQUEST_FAILURE = 'VIEW_CLINIC_REQUEST_FAILURE'
export const VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST = 'VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST'

// update status (clinic)
export const UPDATE_CLINIC_STATUS_REQUEST = 'UPDATE_CLINIC_STATUS_REQUEST'
export const UPDATE_CLINIC_STATUS_REQUEST_SUCCESS = 'UPDATE_CLINIC_STATUS_REQUEST_SUCCESS'
export const UPDATE_CLINIC_STATUS_REQUEST_FAILURE = 'UPDATE_CLINIC_STATUS_REQUEST_FAILURE'

//update clinic status local

export const UPDATE_CLINIC_STATUS_LOCAL = 'UPDATE_CLINIC_STATUS_LOCAL'

//CMS
// Get all cms
export const GET_CMS_REQUEST = 'GET_CMS_REQUEST'
export const GET_CMS_REQUEST_SUCCESS = 'GET_CMS_REQUEST_SUCCESS'
export const GET_CMS_REQUEST_FAILURE = 'GET_CMS_REQUEST_FAILURE'
export const GET_CMS_CACHE_DATA_CLEAR_REQUEST = 'GET_CMS_CACHE_DATA_CLEAR_REQUEST'

// view cms details
export const VIEW_CMS_REQUEST = 'VIEW_CMS_REQUEST'
export const VIEW_CMS_REQUEST_SUCCESS = 'VIEW_CMS_REQUEST_SUCCESS'
export const VIEW_CMS_REQUEST_FAILURE = 'VIEW_CMS_REQUEST_FAILURE'
export const VIEW_CMS_CACHE_DATA_CLEAR_REQUEST = 'VIEW_CMS_CACHE_DATA_CLEAR_REQUEST'

export const EDIT_CMS_DATA = 'EDIT_CMS_DATA'

// update status (cms)
export const UPDATE_CMS_STATUS_REQUEST = 'UPDATE_CMS_STATUS_REQUEST'
export const UPDATE_CMS_STATUS_REQUEST_SUCCESS = 'UPDATE_CMS_STATUS_REQUEST_SUCCESS'
export const UPDATE_CMS_STATUS_REQUEST_FAILURE = 'UPDATE_CMS_STATUS_REQUEST_FAILURE'

// get Member all
export const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST'
export const GET_MEMBER_REQUEST_SUCCESS = 'GET_MEMBER_REQUEST_SUCCESS'
export const GET_MEMBER_REQUEST_FAILURE = 'GET_MEMBER_REQUEST_FAILURE'
export const GET_MEMBER_CACHE_DATA_CLEAR_REQUEST = 'GET_MEMBER_CACHE_DATA_CLEAR_REQUEST'

// View member details
export const VIEW_MEMBER_REQUEST = 'VIEW_MEMBER_REQUEST'
export const VIEW_MEMBER_REQUEST_SUCCESS = 'VIEW_MEMBER_REQUEST_SUCCESS'
export const VIEW_MEMBER_REQUEST_FAILURE = 'VIEW_MEMBER_REQUEST_FAILURE'
export const VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST = 'VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST'

// get Patient all
export const GET_PATIENT_REQUEST_SUCCESS = 'GET_PATIENT_REQUEST_SUCCESS'
export const GET_PATIENT_REQUEST = 'GET_PATIENT_REQUEST'
export const GET_PATIENT_REQUEST_FAILURE = 'GET_PATIENT_REQUEST_FAILURE'
export const GET_PATIENT_CACHE_DATA_CLEAR_REQUEST = 'GET_PATIENT_CACHE_DATA_CLEAR_REQUEST'

// view Patient details
export const VIEW_PATIENT_REQUEST = 'VIEW_PATIENT_REQUEST'
export const VIEW_PATIENT_REQUEST_SUCCESS = 'VIEW_PATIENT_REQUEST_SUCCESS'
export const VIEW_PATIENT_REQUEST_FAILURE = 'VIEW_PATIENT_REQUEST_FAILURE'
export const VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST = 'VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST'

// Get all appointment
export const GET_APPOINTMENT_REQUEST = 'GET_APPOINTMENT_REQUEST'
export const GET_APPOINTMENT_REQUEST_SUCCESS = 'GET_APPOINTMENT_REQUEST_SUCCESS'
export const GET_APPOINTMENT_REQUEST_FAILURE = 'GET_APPOINTMENT_REQUEST_FAILURE'
export const GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST = 'GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST'

// get owner list
export const GET_OWNER_REQUEST = 'GET_OWNER_REQUEST'
export const GET_OWNER_REQUEST_SUCCESS = 'GET_OWNER_REQUEST_SUCCESS'
export const GET_OWNER_REQUEST_FAILURE = 'GET_OWNER_REQUEST_FAILURE'
export const GET_OWNER_CACHE_DATA_CLEAR_REQUEST = 'GET_OWNER_CACHE_DATA_CLEAR_REQUEST'

// view owner details
export const VIEW_OWNER_REQUEST = 'VIEW_OWNER_REQUEST'
export const VIEW_OWNER_REQUEST_SUCCESS = 'VIEW_OWNER_REQUEST_SUCCESS'
export const VIEW_OWNER_REQUEST_FAILURE = 'VIEW_OWNER_REQUEST_FAILURE'
export const VIEW_OWNER_CACHE_DATA_CLEAR_REQUEST = 'VIEW_OWNER_CACHE_DATA_CLEAR_REQUEST'

// update owner status
export const UPDATE_OWNER_STATUS_REQUEST = 'UPDATE_OWNER_STATUS_REQUEST'
export const UPDATE_OWNER_STATUS_REQUEST_SUCCESS = 'UPDATE_OWNER_STATUS_REQUEST_SUCCESS'
export const UPDATE_OWNER_STATUS_REQUEST_FAILURE = 'UPDATE_OWNER_STATUS_REQUEST_FAILURE'

export const UPDATE_OWNER_STATUS_LOCAL = 'UPDATE_OWNER_STATUS_LOCAL'

// get practice id using master id
export const GET_PMS_PRACTICE_REQUEST = 'GET_PMS_PRACTICE_REQUEST'
export const GET_PMS_PRACTICE_REQUEST_SUCCESS = 'GET_PMS_PRACTICE_REQUEST_SUCCESS'
export const GET_PMS_PRACTICE_REQUEST_FAILURE = 'GET_PMS_PRACTICE_REQUEST_FAILURE'

// my profile
// Get  profile
export const GET_PROFILE_INFO_REQUEST = 'GET_PROFILE_INFO_REQUEST'
export const GET_PROFILE_INFO_REQUEST_SUCCESS = 'GET_PROFILE_INFO_REQUEST_SUCCESS'
export const GET_PROFILE_INFO_REQUEST_FAILURE = 'GET_PROFILE_INFO_REQUEST_FAILURE'
export const GET_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST = 'GET_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST'

// EDIT  profile
export const EDIT_PROFILE_INFO_REQUEST = 'EDIT_PROFILE_INFO_REQUEST'
export const EDIT_PROFILE_INFO_REQUEST_SUCCESS = 'EDIT_PROFILE_INFO_REQUEST_SUCCESS'
export const EDIT_PROFILE_INFO_REQUEST_FAILURE = 'EDIT_PROFILE_INFO_REQUEST_FAILURE'
export const EDIT_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST = 'EDIT_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST'

// verify mobile number
// -verify OTP
export const VERIFY_OTP_REQUEST = 'VERIFY_OTP_REQUEST'
export const VERIFY_OTP_REQUEST_SUCCESS = 'VERIFY_OTP_REQUEST_SUCCESS'
export const VERIFY_OTP_REQUEST_FAILURE = 'VERIFY_OTP_REQUEST_FAILURE'

// - send OTP
export const SEND_OTP_REQUEST = 'SEND_OTP_REQUEST'
export const SEND_OTP_REQUEST_SUCCESS = 'SEND_OTP_REQUEST_SUCCESS'
export const SEND_OTP_REQUEST_FAILURE = 'SEND_OTP_REQUEST_FAILURE'

// Change password
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_REQUEST_SUCCESS = 'CHANGE_PASSWORD_REQUEST_SUCCESS'
export const CHANGE_PASSWORD_REQUEST_FAILURE = 'CHANGE_PASSWORD_REQUEST_FAILURE'

// clinic subscription
export const GET_SUBSCRIPTION_PLANS_REQUEST = 'GET_SUBSCRIPTION_PLANS_REQUEST'
export const GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS = 'GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS'
export const GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE = 'GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE'

// edit subscription plan
export const EDIT_SUBSCRIPTION_PLANS = 'EDIT_SUBSCRIPTION_PLANS'
export const UPDATE_EDIT_SUBSCRIPTION_PLANS = 'UPDATE_EDIT_SUBSCRIPTION_PLANS'

//put subscription plan
export const PUT_SUBSCRIPTION_PLANS_REQUEST = 'PUT_SUBSCRIPTION_PLANS_REQUEST'
export const PUT_SUBSCRIPTION_PLANS_REQUEST_SUCCESS = 'PUT_SUBSCRIPTION_PLANS_REQUEST_SUCCESS'
export const PUT_SUBSCRIPTION_PLANS_REQUEST_FAILURE = 'PUT_SUBSCRIPTION_PLANS_REQUEST_FAILURE'

// add plan in local
export const ADD_SUBSCRIPTION_PLAN = 'ADD_SUBSCRIPTION_PLAN'
export const CHANGE_ADD_MODE_VALUE = 'CHANGE_ADD_MODE_VALUE'

//trail days
export const CHANGE_TRAIL_DAYS = 'CHANGE_TRAIL_DAYS'

// get trail days
export const GET_TRAIL_DAYS_REQUEST = 'GET_TRAIL_DAYS_REQUEST'
export const GET_TRAIL_DAYS_REQUEST_SUCCESS = 'GET_TRAIL_DAYS_REQUEST_SUCCESS'
export const GET_TRAIL_DAYS_REQUEST_FAILURE = 'GET_TRAIL_DAYS_REQUEST_FAILURE'

// put trail days
export const PUT_TRAIL_DAYS_REQUEST = 'PUT_TRAIL_DAYS_REQUEST'
export const PUT_TRAIL_DAYS_REQUEST_SUCCESS = 'PUT_TRAIL_DAYS_REQUEST_SUCCESS'
export const PUT_TRAIL_DAYS_REQUEST_FAILURE = 'PUT_TRAIL_DAYS_REQUEST_FAILURE'

// get clinic details list by owner id
export const GET_CLINIC_LIST_BY_OWNER_REQUEST = 'GET_CLINIC_LIST_BY_OWNER_REQUEST'
export const GET_CLINIC_LIST_BY_OWNER_REQUEST_SUCCESS = 'GET_CLINIC_LIST_BY_OWNER_REQUEST_SUCCESS'
export const GET_CLINIC_LIST_BY_OWNER_REQUEST_FAILURE = 'GET_CLINIC_LIST_BY_OWNER_REQUEST_FAILURE'
//contactUs
// get contact details
export const GET_CONTACT_REQUEST = 'GET_CONTACT_REQUEST'
export const GET_CONTACT_REQUEST_SUCCESS = 'GET_CONTACT_REQUEST_SUCCESS'
export const GET_CONTACT_REQUEST_FAILURE = 'GET_CONTACT_REQUEST_FAILURE'
export const GET_CONTACT_CACHE_DATA_CLEAR_REQUEST = 'GET_CONTACT_CACHE_DATA_CLEAR_REQUEST'

//update contact details
export const UPDATE_CONTACT_REQUEST = 'UPDATE_CONTACT_REQUEST'
export const UPDATE_CONTACT_REQUEST_SUCCESS = 'UPDATE_CONTACT_REQUEST_SUCCESS'
export const UPDATE_CONTACT_REQUEST_FAILURE = 'UPDATE_CONTACT_REQUEST_FAILURE'

// contact us
export const CHANGE_CONTACT_US = 'CHANGE_CONTACT_US'
export const UPDATE_CONTACT_US = 'UPDATE_CONTACT_US'

//update device token for the notification
export const UPDATE_DEVICE_TOKEN = 'UPDATE_DEVICE_TOKEN'

// get modules
export const GET_MODULES_REQUEST = 'GET_MODULES_REQUEST'
export const GET_MODULES_REQUEST_SUCCESS = 'GET_MODULES_REQUEST_SUCCESS'
export const GET_MODULES_REQUEST_FAILURE = 'GET_MODULES_REQUEST_FAILURE'

// update resource data locally
export const UPDATE_RESOURCE_DATA_REQUEST = 'UPDATE_RESOURCE_DATA_REQUEST'

//update resources errors
export const UPDATE_RESOURCES_VALIDATIONS = 'UPDATE_RESOURCES_VALIDATIONS'

//resource id
export const STORE_RESOURCES_ID = 'STORE_RESOURCES_ID'

//get resource data
export const GET_RESOURCE_DATA_REQUEST = 'GET_RESOURCE_DATA_REQUEST'
export const GET_RESOURCE_DATA_SUCCESS = 'GET_RESOURCE_DATA_SUCCESS'
export const GET_RESOURCE_DATA_FAIL = 'GET_RESOURCE_DATA_FAIL'
export const GET_RESOURCE_CACHE_DATA_CLEAR_REQUEST = 'GET_RESOURCE_CACHE_DATA_CLEAR_REQUEST'

//add resource data
export const ADD_RESOURCE_DATA_REQUEST = 'ADD_RESOURCE_DATA_REQUEST'
export const ADD_RESOURCE_DATA_SUCCESS = 'ADD_RESOURCE_DATA_SUCCESS'
export const ADD_RESOURCE_DATA_FAIL = 'ADD_RESOURCE_DATA_FAIL'

//put resource data
export const PUT_RESOURCE_DATA_REQUEST = 'PUT_RESOURCE_DATA_REQUEST'
export const PUT_RESOURCE_DATA_SUCCESS = 'PUT_RESOURCE_DATA_SUCCESS'
export const PUT_RESOURCE_DATA_FAIL = 'PUT_RESOURCE_DATA_FAIL'

//delete resource data
export const DELETE_RESOURCE_DATA_REQUEST = 'DELETE_RESOURCE_DATA_REQUEST'
export const DELETE_RESOURCE_DATA_SUCCESS = 'DELETE_RESOURCE_DATA_SUCCESS'
export const DELETE_RESOURCE_DATA_FAIL = 'DELETE_RESOURCE_DATA_FAIL'

//remove resource thumbnail data
export const REMOVE_RESOURCE_THUMBNAIL_REQUEST = 'REMOVE_RESOURCE_THUMBNAIL_REQUEST'
export const REMOVE_RESOURCE_THUMBNAIL_SUCCESS = 'REMOVE_RESOURCE_THUMBNAIL_SUCCESS'
export const REMOVE_RESOURCE_THUMBNAIL_FAIL = 'REMOVE_RESOURCE_THUMBNAIL_FAIL'

// update procedure code data locally
export const UPDATE_PROCEDURE_CODE_DATA_REQUEST = 'UPDATE_PROCEDURE_CODE_DATA_REQUEST'

// update treatment data locally
export const UPDATE_TREATMENT_DATA_REQUEST = 'UPDATE_TREATMENT_DATA_REQUEST'

//get listing of procedure codes
export const GET_PROCEDURE_CODE_LIST_REQUEST = 'GET_PROCEDURE_CODE_LIST_REQUEST'
export const GET_PROCEDURE_CODE_LIST_SUCCESS = 'GET_PROCEDURE_CODE_LIST_SUCCESS'
export const GET_PROCEDURE_CODE_LIST_FAIL = 'GET_PROCEDURE_CODE_LIST_FAIL'

//get listing of recommendation treatment mapping
export const GET_TREATMENT_LIST_REQUEST = 'GET_TREATMENT_LIST_REQUEST'
export const GET_TREATMENT_LIST_SUCCESS = 'GET_TREATMENT_LIST_SUCCESS'
export const GET_TREATMENT_LIST_FAIL = 'GET_TREATMENT_LIST_FAIL'

//add edit for recommendation treatment mapping
export const ADD_EDIT_TREATMENT_REQUEST = 'ADD_EDIT_TREATMENT_REQUEST'
export const ADD_EDIT_TREATMENT_SUCCESS = 'ADD_EDIT_TREATMENT_SUCCESS'
export const ADD_EDIT_TREATMENT_FAIL = 'ADD_EDIT_TREATMENT_FAIL'

// create or update procedure codes
export const ADD_EDIT_PROCEDURE_CODE_REQUEST = 'ADD_EDIT_PROCEDURE_CODE_REQUEST'
export const ADD_EDIT_PROCEDURE_CODE_SUCCESS = 'ADD_EDIT_PROCEDURE_CODE_SUCCESS'
export const ADD_EDIT_PROCEDURE_CODE_FAIL = 'ADD_EDIT_PROCEDURE_CODE_FAIL'

// get data of procedure without pagination
export const GET_ALL_PROCEDURE_DATA_REQUEST = 'GET_ALL_PROCEDURE_DATA_REQUEST'
export const GET_ALL_PROCEDURE_DATA_REQUEST_SUCCESS = 'GET_ALL_PROCEDURE_DATA_REQUEST_SUCCESS'
export const GET_ALL_PROCEDURE_DATA_REQUEST_FAIL = 'GET_ALL_PROCEDURE_DATA_REQUEST_FAIL'

export const UPDATE_PROCEDURE_DATA_VALIDATION = 'UPDATE_PROCEDURE_DATA_VALIDATION'
export const UPDATE_TREATMENT_DATA_VALIDATION = 'UPDATE_TREATMENT_DATA_VALIDATION'

// download csv file
export const DOWNLOAD_CSV_FILE_REQUEST = 'DOWNLOAD_CSV_FILE_REQUEST'
export const DOWNLOAD_CSV_FILE_REQUEST_SUCCESS = 'DOWNLOAD_CSV_FILE_REQUEST_SUCCESS'
export const DOWNLOAD_CSV_FILE_REQUEST_FAIL = 'DOWNLOAD_CSV_FILE_REQUEST_FAIL'

// upload csv file
export const UPLOAD_CSV_FILE_REQUEST = 'UPLOAD_CSV_FILE_REQUEST'
export const UPLOAD_CSV_FILE_REQUEST_SUCCESS = 'UPLOAD_CSV_FILE_REQUEST_SUCCESS'
export const UPLOAD_CSV_FILE_REQUEST_FAIL = 'UPLOAD_CSV_FILE_REQUEST_FAIL'

export const GET_PRACTICE_INFORMATION_REQUEST = 'GET_PRACTICE_INFORMATION_REQUEST'
export const GET_PRACTICE_INFORMATION_REQUEST_SUCCESS = 'GET_PRACTICE_INFORMATION_REQUEST_SUCCESS'
export const GET_PRACTICE_INFORMATION_REQUEST_FAIL = 'GET_PRACTICE_INFORMATION_REQUEST_FAIL'

// MSA Agreement
export const CHANGE_MSA_AGREEMENT = 'CHANGE_MSA_AGREEMENT'

export const UPDATE_MSA_AGREEMENT_VALIDATION = 'UPDATE_MSA_AGREEMENT_VALIDATION'

export const PUT_MSA_AGREEMENT_REQUEST = 'PUT_MSA_AGREEMENT_REQUEST'
export const PUT_MSA_AGREEMENT_REQUEST_SUCCESS = 'PUT_MSA_AGREEMENT_REQUEST_SUCCESS'
export const PUT_MSA_AGREEMENT_REQUEST_FAIL = 'PUT_MSA_AGREEMENT_REQUEST_FAIL'

export const GET_MSA_AGREEMENT_REQUEST = 'GET_MSA_AGREEMENT_REQUEST'
export const GET_MSA_AGREEMENT_REQUEST_SUCCESS = 'GET_MSA_AGREEMENT_REQUEST_SUCCESS'
export const GET_MSA_AGREEMENT_REQUEST_FAIL = 'GET_MSA_AGREEMENT_REQUEST_FAIL'
