import {
  UPDATE_PROCEDURE_CODE_DATA_REQUEST,
  UPDATE_TREATMENT_DATA_REQUEST,
  GET_PROCEDURE_CODE_LIST_REQUEST,
  GET_PROCEDURE_CODE_LIST_SUCCESS,
  GET_PROCEDURE_CODE_LIST_FAIL,
  GET_TREATMENT_LIST_REQUEST,
  GET_TREATMENT_LIST_SUCCESS,
  GET_TREATMENT_LIST_FAIL,
  ADD_EDIT_TREATMENT_REQUEST,
  ADD_EDIT_TREATMENT_SUCCESS,
  ADD_EDIT_TREATMENT_FAIL,
  ADD_EDIT_PROCEDURE_CODE_REQUEST,
  ADD_EDIT_PROCEDURE_CODE_SUCCESS,
  ADD_EDIT_PROCEDURE_CODE_FAIL,
  GET_ALL_PROCEDURE_DATA_REQUEST,
  GET_ALL_PROCEDURE_DATA_REQUEST_SUCCESS,
  GET_ALL_PROCEDURE_DATA_REQUEST_FAIL,
  UPDATE_PROCEDURE_DATA_VALIDATION,
  UPDATE_TREATMENT_DATA_VALIDATION,
  DOWNLOAD_CSV_FILE_REQUEST,
  DOWNLOAD_CSV_FILE_REQUEST_SUCCESS,
  DOWNLOAD_CSV_FILE_REQUEST_FAIL,
  UPLOAD_CSV_FILE_REQUEST,
  UPLOAD_CSV_FILE_REQUEST_SUCCESS,
  UPLOAD_CSV_FILE_REQUEST_FAIL,
} from '../actionTypes'
import {
  GetProcedureCodeListRequest,
  GetProcedureCodeListRequestPayload,
  UpdateProcedureCodePayload,
  UpdateProcedureCodeRequest,
  UpdateTreatmentPayload,
  UpdateTreatmentRequest,
  GetProcedureCodeListRequestSuccess,
  GetProcedureCodeListRequestSuccessPayload,
  GetProcedureCodeListRequestFailurePayload,
  GetProcedureCodeListRequestFailure,
  getTreatmentCodeListRequestPayload,
  getTreatmentCodeListRequest,
  getTreatmentCodeListRequestSuccessPayload,
  getTreatmentCodeListRequestSuccess,
  getTreatmentCodeListRequestFailurePayload,
  getTreatmentCodeListRequestFailure,
  addEditTreatmentRequestPayload,
  addEditTreatmentRequest,
  addEditTreatmentRequestSuccessPayload,
  addEditTreatmentRequestSuccess,
  addEditTreatmentRequestFailurePayload,
  addEditTreatmentRequestFailure,
  AddEditProcedureCodeRequestPayload,
  AddEditProcedureCodeRequest,
  AddEditProcedureCodeRequestSuccessPayload,
  AddEditProcedureCodeRequestFailure,
  AddEditProcedureCodeRequestFailurePayload,
  AddEditProcedureCodeRequestSuccess,
  GetAllProcedureDataRequestPayload,
  GetAllProcedureDataRequest,
  GetAllProcedureDataRequestSuccessPayload,
  GetAllProcedureDataRequestSuccess,
  GetAllProcedureDataRequestFailurePayload,
  GetAllProcedureDataRequestFailure,
  UpdateProcedureDataValidations,
  UpdateTreatmentDataValidations,
  DownloadCsvFileRequest,
  DownloadCsvFileRequestSuccessPayload,
  DownloadCsvFileRequestSuccess,
  DownloadCsvFileRequestFailurePayload,
  DownloadCsvFileRequestFailure,
  UploadCsvFileRequest,
  UploadCsvFileRequestSuccessPayload,
  UploadCsvFileRequestSuccess,
  UploadCsvFileRequestFailurePayload,
  UploadCsvFileRequestFailure,
} from '../../schema/treatmentAndProcedureCodes/treatmentAndProcedureCodesSchema'

export const updateTreatmentProcedureAction = (payload: UpdateProcedureCodePayload): UpdateProcedureCodeRequest => ({
  type: UPDATE_PROCEDURE_CODE_DATA_REQUEST,
  payload,
})

export const updateTreatmentAction = (payload: UpdateTreatmentPayload): UpdateTreatmentRequest => ({
  type: UPDATE_TREATMENT_DATA_REQUEST,
  payload,
})

export const getProcedureCodeListRequestAction = (payload: GetProcedureCodeListRequestPayload): GetProcedureCodeListRequest => ({
  type: GET_PROCEDURE_CODE_LIST_REQUEST,
  payload,
})
export const getProcedureCodeListRequestSuccessAction = (
  payload: GetProcedureCodeListRequestSuccessPayload
): GetProcedureCodeListRequestSuccess => ({
  type: GET_PROCEDURE_CODE_LIST_SUCCESS,
  payload,
})
export const getProcedureCodeListRequestFailureAction = (
  payload: GetProcedureCodeListRequestFailurePayload
): GetProcedureCodeListRequestFailure => ({
  type: GET_PROCEDURE_CODE_LIST_FAIL,
  payload,
})

//add or edit procedure code

export const AddEditProcedureCodeRequestAction = (payload: AddEditProcedureCodeRequestPayload): AddEditProcedureCodeRequest => ({
  type: ADD_EDIT_PROCEDURE_CODE_REQUEST,
  payload,
})
export const AddEditProcedureCodeRequestSuccessAction = (
  payload: AddEditProcedureCodeRequestSuccessPayload
): AddEditProcedureCodeRequestSuccess => ({
  type: ADD_EDIT_PROCEDURE_CODE_SUCCESS,
  payload,
})
export const AddEditProcedureCodeRequestFailureAction = (
  payload: AddEditProcedureCodeRequestFailurePayload
): AddEditProcedureCodeRequestFailure => ({
  type: ADD_EDIT_PROCEDURE_CODE_FAIL,
  payload,
})

//get listing of recommendation mapping
export const getTreatmentListRequestAction = (payload: getTreatmentCodeListRequestPayload): getTreatmentCodeListRequest => ({
  type: GET_TREATMENT_LIST_REQUEST,
  payload,
})
export const getTreatmentCodeListRequestSuccessAction = (
  payload: getTreatmentCodeListRequestSuccessPayload
): getTreatmentCodeListRequestSuccess => ({
  type: GET_TREATMENT_LIST_SUCCESS,
  payload,
})
export const getTreatmentCodeListRequestFailureAction = (
  payload: getTreatmentCodeListRequestFailurePayload
): getTreatmentCodeListRequestFailure => ({
  type: GET_TREATMENT_LIST_FAIL,
  payload,
})

//add edit for recommendation mapping
export const addEditTreatmentRequestAction = (payload: addEditTreatmentRequestPayload): addEditTreatmentRequest => ({
  type: ADD_EDIT_TREATMENT_REQUEST,
  payload,
})
export const addEditTreatmentRequestSuccessAction = (payload: addEditTreatmentRequestSuccessPayload): addEditTreatmentRequestSuccess => ({
  type: ADD_EDIT_TREATMENT_SUCCESS,
  payload,
})
export const addEditTreatmentRequestFailureAction = (payload: addEditTreatmentRequestFailurePayload): addEditTreatmentRequestFailure => ({
  type: ADD_EDIT_TREATMENT_FAIL,
  payload,
})

// get data of procedure without pagination
export const getAllProcedureDataRequestAction = (payload: GetAllProcedureDataRequestPayload): GetAllProcedureDataRequest => ({
  type: GET_ALL_PROCEDURE_DATA_REQUEST,
  payload,
})
export const getAllProcedureDataRequestSuccessAction = (
  payload: GetAllProcedureDataRequestSuccessPayload
): GetAllProcedureDataRequestSuccess => ({
  type: GET_ALL_PROCEDURE_DATA_REQUEST_SUCCESS,
  payload,
})
export const getAllProcedureDataRequestFailureAction = (
  payload: GetAllProcedureDataRequestFailurePayload
): GetAllProcedureDataRequestFailure => ({
  type: GET_ALL_PROCEDURE_DATA_REQUEST_FAIL,
  payload,
})

export const updateProcedureDataValidationsAction = (payload: any): UpdateProcedureDataValidations => ({
  type: UPDATE_PROCEDURE_DATA_VALIDATION,
  payload,
})

export const updateTreatmentDataValidationsAction = (payload: any): UpdateTreatmentDataValidations => ({
  type: UPDATE_TREATMENT_DATA_VALIDATION,
  payload,
})

// download csv file
export const downloadCsvFileRequestAction = (payload?: any): DownloadCsvFileRequest => ({
  type: DOWNLOAD_CSV_FILE_REQUEST,
  payload,
})
export const downloadCsvFileRequestSuccessAction = (payload: DownloadCsvFileRequestSuccessPayload): DownloadCsvFileRequestSuccess => ({
  type: DOWNLOAD_CSV_FILE_REQUEST_SUCCESS,
  payload,
})
export const downloadCsvFileRequestFailureAction = (payload: DownloadCsvFileRequestFailurePayload): DownloadCsvFileRequestFailure => ({
  type: DOWNLOAD_CSV_FILE_REQUEST_FAIL,
  payload,
})

// upload csv file
export const uploadCsvFileRequestAction = (payload: any): UploadCsvFileRequest => ({
  type: UPLOAD_CSV_FILE_REQUEST,
  payload,
})
export const uploadCsvFileRequestSuccessAction = (payload: UploadCsvFileRequestSuccessPayload): UploadCsvFileRequestSuccess => ({
  type: UPLOAD_CSV_FILE_REQUEST_SUCCESS,
  payload,
})
export const uploadCsvFileRequestFailureAction = (payload: UploadCsvFileRequestFailurePayload): UploadCsvFileRequestFailure => ({
  type: UPLOAD_CSV_FILE_REQUEST_FAIL,
  payload,
})
