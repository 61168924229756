import { all, call, put, takeEvery } from 'redux-saga/effects'
import { GET_MEMBER_REQUEST, VIEW_MEMBER_REQUEST } from '../../actions/actionTypes'
import API from '../../../utils/api'
import {
  getMemberRequestSuccess,
  getMemberRequestFailure,
  viewMemberDetailRequestSuccess,
  viewMemberDetailRequestFailure,
  getMemberCacheDataClearRequest,
  viewMemberCacheDataClearRequest,
} from '../../actions/member/memberAction'
import { errorToaster } from '../../../utils/helper'

// Get member
function* getMemberRequestSaga(payload: any): any {
  yield put(getMemberCacheDataClearRequest())
  try {
    const params = [
      { key: 'search', value: payload?.payload?.search },
      { key: 'page', value: payload?.payload?.page },
      { key: 'size', value: payload?.payload?.size },
    ]
    if (payload?.payload?.fromClinic && payload?.payload?.clinic_id) {
      params.push({ key: 'clinic_id', value: payload?.payload?.clinic_id })
    }
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })

    const newUrl = `/v2/staff/get/all${tmpUrl}`
    let url = newUrl
    const response = yield API.get(url)
    yield put(
      getMemberRequestSuccess({
        getMemberData: response?.data?.detail,
        getMemberMeta: response?.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getMemberRequestFailure({
        getMemberError: e?.message,
      })
    )
  }
}

// view member details
function* viewMemberRequestSaga(payload: any): any {
  yield put(viewMemberCacheDataClearRequest())
  try {
    const response = yield API.get(`/v2/staff/${payload?.payload?.staff_id}`)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(
      viewMemberDetailRequestSuccess({
        viewMemberData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      viewMemberDetailRequestFailure({
        viewMemberError: e?.message,
      })
    )
  }
}

function* getMemberSaga() {
  yield takeEvery(GET_MEMBER_REQUEST, getMemberRequestSaga)
}
function* viewMemberDetailSaga() {
  yield takeEvery(VIEW_MEMBER_REQUEST, viewMemberRequestSaga)
}

export default function* memberSaga() {
  yield all([getMemberSaga(), viewMemberDetailSaga()])
}
