import { LOGIN_REQUEST, LOGIN_REQUEST_SUCCESS, LOGIN_REQUEST_FAILURE } from '../../actions/actionTypes'
import { LoginInterface, LoginActions } from '../../schema/auth/loginSchema'

const initialState: LoginInterface = {
  loginLoader: false,
  loginData: null,
  loginError: null,
}

const LoginReducer = (state = initialState, action: LoginActions) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loginLoader: true,
      }
    case LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        loginLoader: false,
        loginData: action?.payload,
        loginError: null,
      }
    case LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        loginLoader: false,
        loginData: null,
        loginError: action?.payload?.loginError,
      }
    default:
      return {
        ...state,
      }
  }
}

export default LoginReducer
