import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST_SUCCESS, RESET_PASSWORD_REQUEST_FAILURE } from '../../actions/actionTypes'
import { ResetPasswordInterface, ResetPasswordActions } from '../../schema/auth/resetPasswordSchema'

const initialState: ResetPasswordInterface = {
  resetPasswordLoader: false,
  resetPasswordData: null,
  resetPasswordError: '',
}
const resetPasswordReducer = (state = initialState, action: ResetPasswordActions) => {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        resetPasswordLoader: true,
      }
    case RESET_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        resetPasswordLoader: false,
        resetPasswordData: action.payload,
        resetPasswordError: null,
      }
    case RESET_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        resetPasswordLoader: false,
        resetPasswordData: null,
        resetPasswordError: action.payload.resetPasswordError,
      }
    default:
      return {
        ...state,
      }
  }
}
export default resetPasswordReducer
