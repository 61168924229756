import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { CustomRouter } from './components/common/CustomRouter'
import store from './redux/store'
import './index.css'
import theme from './theme'
import Loader from './components/common/Loader'

ReactDOM.render(
  <React.Suspense fallback={<Loader />}>
    <CookiesProvider>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ToastContainer limit={0} position={'top-right'} hideProgressBar={true} autoClose={2000} />
          <CustomRouter>
            <App />
          </CustomRouter>
        </ThemeProvider>
      </Provider>
    </CookiesProvider>
  </React.Suspense>,
  document.getElementById('root')
)
serviceWorker.unregister()
