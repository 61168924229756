import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_REQUEST_SUCCESS, FORGOT_PASSWORD_REQUEST_FAILURE } from '../../actions/actionTypes'
import { ForgotPasswordInterface, ForgotPasswordActions } from '../../schema/auth/forgotPasswordSchema'

const initialState: ForgotPasswordInterface = {
  forgotPasswordLoader: false,
  forgotPasswordData: null,
  forgotPasswordError: '',
}
const forgotPasswordReducer = (state = initialState, action: ForgotPasswordActions) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPasswordLoader: true,
      }
    case FORGOT_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        forgotPasswordLoader: false,
        forgotPasswordData: action.payload,
        forgotPasswordError: null,
      }
    case FORGOT_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        forgotPasswordLoader: false,
        forgotPasswordData: null,
        forgotPasswordError: action.payload.forgotPasswordError,
      }
    default:
      return {
        ...state,
      }
  }
}
export default forgotPasswordReducer
