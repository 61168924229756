import React, { useState } from 'react'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import { Box, CircularProgress, TextField, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import theme from '../../theme'
import { formatPhoneNumber } from '../../utils/helper'
import { mobileVerifyValidator } from '../../validation/auth/verifyMobileNumber'
import { sendOtpRequest, verifyOtpRequest } from '../../redux/actions/authActions/otpAction'
import { RootState } from '../../redux/reducers/rootReducer'

const useStyles = makeStyles((themes: any) => ({
  headingText: {
    fontSize: '30px !important',
    fontWeight: 600,
    lineHeight: '38px',
    marginBottom: '5px !important',
    marginTop: '10px !important',
    color: themes.palette.secondary.main,
  },

  cancelButton: {
    width: 'auto',
    minWidth: 'auto',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
    color: `${themes.palette.otherColor.main} !important`,
  },
  button: {
    background: themes.palette.secondary.main,
    color: themes.palette.common.white,
    padding: '10px 20px',
    textDecoration: 'none',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '600',
  },
  submitButtonAction: {
    padding: '0px',
    marginBottom: '30px',
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: `${themes.palette.secondary.main} !important`,
  },
  floatingLabelRootStyle_full: {
    color: `${themes.palette.otherColor.floatingBorder} !important`,
    opacity: '50%',
    top: '-6px !important',
  },
}))
const VerifyMobilePopUP = (props: any) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [otpTimer, setOtpTimer] = useState(0)
  const handleClose = () => {
    props.setOpen(false)
  }
  const [formData, setFormData] = useState({
    otp: '',
  })
  const [error, setError]: any = useState({})
  const profileData = useSelector((state: any) => state?.profile?.profileData)

  const continueHandler = () => {
    const { errors, isValid } = mobileVerifyValidator(formData)
    if (isValid) {
      setError('')
      dispatch(
        verifyOtpRequest({
          mobile: props.formData?.mobile_phone,
          otp: formData?.otp,
          isOwner: false,
          callback: (data: any) => {
            props.setOpen(false)
            localStorage.setItem('adminData', JSON.stringify(data?.data?.detail))
          },
        })
      )
    } else {
      setError(errors)
    }
  }
  const resendOtp = () => {
    dispatch(
      sendOtpRequest({
        mobile: props.formData?.mobile_phone,
        profile_id: profileData?.id,
        callback: () => {
          if (!sendOTPLoader) {
            setOtpTimer(45)
            let count = 45
            let otpInterval = setInterval(() => {
              setOtpTimer(count)
              count--
              if (count < 1) {
                clearInterval(otpInterval)
              }
              setOtpTimer(count)
            }, 1000)
          }
        },
      })
    )
  }
  const { verifyOTPLoader, sendOTPLoader } = useSelector((state: RootState) => state.otp)

  return (
    <Box
      sx={{
        display: { lg: 'flex', md: 'inline' },
        justifyContent: 'flex-end',
      }}
    >
      <Dialog
        style={{
          width: '502px !important',
          height: '400px !important',
          boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.07)',
          borderRadius: '20px !important',
          display: 'flex !important',
          alignItems: 'center !important',
          justifyContent: 'center !important',
        }}
        sx={{
          '& .MuiDialogContent-root': {
            padding: '0px 30px 20px 30px !important',
          },
          '& .MuiPaper-root ': {
            width: '502px !important',

            background: `${theme.palette.common.white}!important`,
            boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.07)',
            borderRadius: '30px !important',
            display: 'flex !important',
            alignItems: 'center !important',
            justifyContent: 'center !important',
          },
        }}
        open={props.open}
        onClose={handleClose}
      >
        <DialogActions className={`w-100 justify-end d-flex`}>
          <Button disableRipple className={`${classes.cancelButton} d-flex justify-end buttonHoverNone`} onClick={handleClose}>
            <CancelOutlinedIcon />
          </Button>
        </DialogActions>
        <DialogContent>
          <Box className={`flex-col d-flex-all-center`}>
            <Typography className={classes.headingText} variant={'h6'}>
              Verify Your Number
            </Typography>
            <Typography my={3} variant={'subtitle3' as any}>
              +1 {formatPhoneNumber(props.formData?.mobile_phone)}
            </Typography>
            <Typography mb={3} variant={'subtitle3' as any}>
              {' '}
              OTP send on your registered mobile number, Please verify it.
            </Typography>
          </Box>
          <Box mb={1.25}>
            <TextField
              label="OTP"
              sx={{ fontVariant: 'small-cap !important' }}
              value={formData?.otp}
              variant="outlined"
              fullWidth
              inputProps={{
                maxLength: 6,
              }}
              InputProps={{
                classes: {
                  notchedOutline: error?.otp ? 'danger' : classes.notchedOutline,
                  root: 'cssOutlinedInput',
                },
              }}
              InputLabelProps={{
                classes: {
                  root: classes.floatingLabelRootStyle_full,
                  focused: 'floatingLabelFocusStyle',
                },
              }}
              onChange={(e) => {
                setFormData((prevState: any) => {
                  return { ...prevState, otp: e.target.value }
                })
              }}
              onInput={(e: any) => {
                formData.otp = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)
              }}
              error={!!error?.otp}
              helperText={error?.otp}
            />
          </Box>
          <Box className="d-flex align-center justify-content-between">
            <Typography color={'secondary'}> {otpTimer <= 0 ? '' : `00:${otpTimer <= 9 ? `0${otpTimer}` : otpTimer}`}</Typography>
            <Button
              variant={'text'}
              color={'secondary'}
              disableRipple
              className={`buttonHoverNone`}
              sx={{
                padding: '0px !important',
                fontWeight: 400,
              }}
              disabled={otpTimer > 0}
              onClick={resendOtp}
            >
              Resend OTP
            </Button>
          </Box>
        </DialogContent>
        <DialogActions className={`${classes.submitButtonAction} `}>
          <Link to={''} className={`${classes.button} border-radius-10`} onClick={() => continueHandler()}>
            {' '}
            {verifyOTPLoader ? <CircularProgress color={'primary'} size={19} /> : 'Continue'}
          </Link>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
export default VerifyMobilePopUP
