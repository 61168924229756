import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyle = makeStyles((theme: any) => ({
  mainWrap: {
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: '0',
    right: '0',
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: 1026,
    overflow: 'hidden'
  },
}))

const FullScreenLoader = () => {
  const classes = useStyle();
  return (
    <Box className={classes.mainWrap}>
      <CircularProgress
        color={"secondary"}
        size={50}
      />
    </Box>
  )
}

export default FullScreenLoader;