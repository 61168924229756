import {
  GET_CLINIC_REQ_LIST_REQUEST,
  GET_CLINIC_REQ_LIST_REQUEST_SUCCESS,
  GET_CLINIC_REQ_LIST_REQUEST_FAILURE,
  GET_CLINIC_REQ_LIST_CACHE_DATA_CLEAR_REQUEST,
  CLINIC_REQ_DETAILS_REQUEST,
  CLINIC_REQ_DETAILS_REQUEST_SUCCESS,
  CLINIC_REQ_DETAILS_REQUEST_FAILURE,
  CLINIC_REQ_DETAILS_CACHE_DATA_CLEAR_REQUEST,
  APPROVE_CLINIC_REQUEST,
  APPROVE_CLINIC_SUCCESS,
  APPROVE_CLINIC_FAILURE,
  REJECT_CLINIC_REQUEST,
  REJECT_CLINIC_REQUEST_SUCCESS,
  REJECT_CLINIC_REQUEST_FAILURE,
  GET_PMS_PRACTICE_REQUEST,
  GET_PMS_PRACTICE_REQUEST_SUCCESS,
  GET_PMS_PRACTICE_REQUEST_FAILURE,
} from '../../actions/actionTypes'
import { ClinicInterface, ClinicReqActions } from '../../schema/clinic/clinicRequestSchema'

const initialState: ClinicInterface = {
  getClinicReqLoader: false,
  getClinicReqData: null,
  getClinicReqError: null,
  getClinicReqDetailsLoader: false,
  getClinicReqDetailsData: null,
  getClinicReqDetailsError: null,
  approveClinicLoader: false,
  approveClinicData: null,
  approveClinicError: null,
  rejectClinicLoader: false,
  rejectClinicData: null,
  rejectClinicError: null,
  getPmsPracticeLoader: false,
  getPmsPracticeData: null,
  getPmsPracticeError: null,
}

const clinicReqListReducer = (state = initialState, action: ClinicReqActions) => {
  switch (action.type) {
    case GET_CLINIC_REQ_LIST_REQUEST:
      return {
        ...state,
        getClinicReqLoader: true,
      }
    case GET_CLINIC_REQ_LIST_REQUEST_SUCCESS:
      return {
        ...state,
        getClinicReqLoader: false,
        getClinicReqData: action?.payload?.getClinicReqData,
        getClinicReqMeta: action?.payload?.getClinicReqMeta,
        getClinicReqError: null,
      }
    case GET_CLINIC_REQ_LIST_REQUEST_FAILURE:
      return {
        ...state,
        getClinicReqLoader: false,
        getClinicReqData: null,
        getClinicReqError: action?.payload?.getClinicReqError,
      }
    case GET_CLINIC_REQ_LIST_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getClinicReqData: null,
      }
    case CLINIC_REQ_DETAILS_REQUEST:
      return {
        ...state,
        getClinicReqDetailsLoader: true,
      }
    case CLINIC_REQ_DETAILS_REQUEST_SUCCESS:
      return {
        ...state,
        getClinicReqDetailsLoader: false,
        getClinicReqDetailsData: action?.payload?.getClinicReqDetailsData,
        getClinicReqDetailsError: null,
      }
    case CLINIC_REQ_DETAILS_REQUEST_FAILURE:
      return {
        ...state,
        getClinicReqDetailsLoader: false,
        getClinicReqDetailsData: null,
        getClinicReqDetailsError: action?.payload?.getClinicReqDetailsError,
      }
    case CLINIC_REQ_DETAILS_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getClinicReqDetailsData: null,
      }
    case APPROVE_CLINIC_REQUEST:
      return {
        ...state,
        approveClinicLoader: true,
      }
    case APPROVE_CLINIC_SUCCESS:
      return {
        ...state,
        approveClinicLoader: false,
        approveClinicData: action?.payload,
        approveClinicError: null,
      }
    case APPROVE_CLINIC_FAILURE:
      return {
        ...state,
        approveClinicLoader: false,
        approveClinicData: null,
        approveClinicError: action?.payload?.approveClinicError,
      }
    case REJECT_CLINIC_REQUEST:
      return {
        ...state,
        rejectClinicLoader: true,
      }
    case REJECT_CLINIC_REQUEST_SUCCESS:
      return {
        ...state,
        rejectClinicLoader: false,
        rejectClinicData: action?.payload,
        rejectClinicError: null,
      }
    case REJECT_CLINIC_REQUEST_FAILURE:
      return {
        ...state,
        rejectClinicLoader: false,
        rejectClinicData: null,
        rejectClinicError: action?.payload?.rejectClinicError,
      }
    case GET_PMS_PRACTICE_REQUEST:
      return {
        ...state,
        getPmsPracticeLoader: true,
      }
    case GET_PMS_PRACTICE_REQUEST_SUCCESS:
      return {
        ...state,
        getPmsPracticeLoader: false,
        getPmsPracticeData: action?.payload?.getPmsPracticeData,
        getPmsPracticeError: null,
      }
    case GET_PMS_PRACTICE_REQUEST_FAILURE:
      return {
        ...state,
        getPmsPracticeLoader: false,
        getPmsPracticeData: null,
        getPmsPracticeError: action?.payload?.getPmsPracticeError,
      }
    default:
      return {
        ...state,
      }
  }
}
export default clinicReqListReducer
