import validator from "validator";
import { notAllowNumericRegex, notAllowSpecialCharRegex } from "../../utils/helper";
import { notAllowNumberMsg, notAllowSpecialChar, requireMessage } from "../validationMassages";

export const myProfileValidator = (data) => {
    let errors = {}
    if (validator.isEmpty(data.firstName))
        errors.firstName = requireMessage("first name");
    if (validator.isEmpty(data.lastName))
        errors.lastName = requireMessage("Last name");
    if (data?.firstName !== "" && notAllowSpecialCharRegex.test(data?.firstName))
        errors.firstName = notAllowSpecialChar("First name");
    if (data?.firstName !== "" && notAllowNumericRegex.test(data?.firstName))
        errors.firstName = notAllowNumberMsg("First name")
    if (data?.lastName !== "" && notAllowSpecialCharRegex.test(data?.lastName))
        errors.lastName = notAllowSpecialChar("Last name");
    if (data?.lastName !== "" && notAllowNumericRegex.test(data?.lastName))
        errors.lastName = notAllowNumberMsg("Last name");
    if (data?.mobile_phone.length !== 14)
        errors.mobile_phone = requireMessage("valid mobile number");
    if (validator.isEmpty(data.mobile_phone))
        errors.mobile_phone = requireMessage("mobile number");


    return { errors, isValid: Object.keys(errors).length <= 0 };
};