import {
  GET_CMS_REQUEST,
  GET_CMS_REQUEST_SUCCESS,
  GET_CMS_REQUEST_FAILURE,
  GET_CMS_CACHE_DATA_CLEAR_REQUEST,
  VIEW_CMS_REQUEST,
  VIEW_CMS_REQUEST_SUCCESS,
  VIEW_CMS_REQUEST_FAILURE,
  VIEW_CMS_CACHE_DATA_CLEAR_REQUEST,
  UPDATE_CMS_STATUS_REQUEST,
  UPDATE_CMS_STATUS_REQUEST_SUCCESS,
  UPDATE_CMS_STATUS_REQUEST_FAILURE,
  EDIT_CMS_DATA
} from '../actionTypes'
import {
  GetCmsRequest,
  GetCmsRequestSuccess,
  GetCmsRequestFailure,
  getCmsRequestPayload,
  getCmsRequestSuccessPayload,
  getCmsFailurePayload,
  ViewCmsRequest,
  ViewCmsRequestSuccess,
  ViewCmsRequestFailure,
  ViewCmsRequestPayload,
  ViewCmsRequestSuccessPayload,
  ViewCmsFailurePayload,
  CmsStatusUpdateRequest,
  CmsStatusUpdateRequestSuccess,
  CmsStatusUpdateRequestFailure,
  CmsStatusUpdateRequestPayload,
  CmsStatusUpdateRequestSuccessPayload,
  CmsStatusUpdateFailurePayload,
  EditCmsData,
  EditCmsDataPayload,
} from '../../schema/cms/cmsSchema'

//get cms list
export const getCmsRequest = (payload: getCmsRequestPayload): GetCmsRequest => ({
  type: GET_CMS_REQUEST,
  payload,
})
export const getCmsRequestSuccess = (payload: getCmsRequestSuccessPayload): GetCmsRequestSuccess => ({
  type: GET_CMS_REQUEST_SUCCESS,
  payload,
})
export const getCmsRequestFailure = (payload: getCmsFailurePayload): GetCmsRequestFailure => ({
  type: GET_CMS_REQUEST_FAILURE,
  payload,
})

export const getCmsCacheDataClearRequest = (): any => ({
  type: GET_CMS_CACHE_DATA_CLEAR_REQUEST,
})

//view cms
export const viewCmsRequest = (payload: ViewCmsRequestPayload): ViewCmsRequest => ({
  type: VIEW_CMS_REQUEST,
  payload,
})
export const viewCmsRequestSuccess = (payload: ViewCmsRequestSuccessPayload): ViewCmsRequestSuccess => ({
  type: VIEW_CMS_REQUEST_SUCCESS,
  payload,
})
export const viewCmsRequestFailure = (payload: ViewCmsFailurePayload): ViewCmsRequestFailure => ({
  type: VIEW_CMS_REQUEST_FAILURE,
  payload,
})
export const viewCmsCacheDataClearRequest = (): any => ({
  type: VIEW_CMS_CACHE_DATA_CLEAR_REQUEST,
})

//update cms status
export const cmsUpdateStatusRequest = (payload: CmsStatusUpdateRequestPayload): CmsStatusUpdateRequest => ({
  type: UPDATE_CMS_STATUS_REQUEST,
  payload,
})
export const cmsUpdateStatusRequestSuccess = (payload: CmsStatusUpdateRequestSuccessPayload): CmsStatusUpdateRequestSuccess => ({
  type: UPDATE_CMS_STATUS_REQUEST_SUCCESS,
  payload,
})
export const cmsUpdateStatusRequestFailure = (payload: CmsStatusUpdateFailurePayload): CmsStatusUpdateRequestFailure => ({
  type: UPDATE_CMS_STATUS_REQUEST_FAILURE,
  payload,
})

//edit cmsData at a time

export const editCmsData = (payload: EditCmsDataPayload): EditCmsData => ({
  type: EDIT_CMS_DATA,
  payload,
})
