import {
  GET_PROFILE_INFO_REQUEST,
  GET_PROFILE_INFO_REQUEST_SUCCESS,
  GET_PROFILE_INFO_REQUEST_FAILURE,
  GET_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
  EDIT_PROFILE_INFO_REQUEST,
  EDIT_PROFILE_INFO_REQUEST_SUCCESS,
  EDIT_PROFILE_INFO_REQUEST_FAILURE,
  EDIT_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
} from '../actionTypes'
import {
  GetProfileInfoRequest,
  GetProfileInfoRequestSuccess,
  GetProfileInfoRequestFailure,
  EditProfileInfoRequest,
  EditProfileInfoRequestSuccess,
  EditProfileInfoRequestFailure,
  GetProfileInfoRequestPayload,
  GetProfileInfoRequestSuccessPayload,
  GetProfileInfoRequestFailurePayload,
  EditProfileInfoRequestPayload,
  EditProfileInfoRequestSuccessPayload,
  EditProfileInfoRequestFailurePayload,
} from '../../schema/myProfile/profileSchema'

// GET  profile
export const getProfileInfoRequest = (payload: GetProfileInfoRequestPayload): GetProfileInfoRequest => ({
  type: GET_PROFILE_INFO_REQUEST,
  payload,
})
export const getProfileInfoRequestSuccess = (payload: GetProfileInfoRequestSuccessPayload): GetProfileInfoRequestSuccess => ({
  type: GET_PROFILE_INFO_REQUEST_SUCCESS,
  payload,
})
export const getProfileInfoRequestFailure = (payload: GetProfileInfoRequestFailurePayload): GetProfileInfoRequestFailure => ({
  type: GET_PROFILE_INFO_REQUEST_FAILURE,
  payload,
})
export const getProfileInfoCacheDataClearRequest = () => ({
  type: GET_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
})

// EDIT  profile
export const editProfileInfoRequest = (payload: EditProfileInfoRequestPayload): EditProfileInfoRequest => ({
  type: EDIT_PROFILE_INFO_REQUEST,
  payload,
})
export const editProfileInfoRequestSuccess = (payload: EditProfileInfoRequestSuccessPayload): EditProfileInfoRequestSuccess => ({
  type: EDIT_PROFILE_INFO_REQUEST_SUCCESS,
  payload,
})
export const editProfileInfoRequestFailure = (payload: EditProfileInfoRequestFailurePayload): EditProfileInfoRequestFailure => ({
  type: EDIT_PROFILE_INFO_REQUEST_FAILURE,
  payload,
})
export const editProfileInfoCacheDataClearRequest = () => ({
  type: EDIT_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
})
