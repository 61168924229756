import validator from 'validator'
import { icRegex, alphabetRegex } from '../../utils/helper'

export const addEditProcedureValidator = data => {
  let errors = {}
  if (data?.code === null || data?.code?.trim() === '' || validator.isEmpty(data?.code)) errors.code = 'Please enter procedure code.'
  if (data?.code?.includes(' ')) errors.code = 'Please remove white-space.'
  if (data?.code_type === null || validator.isEmpty(data?.code_type)) errors.code_type = 'Please select type.'
  if (data?.country === null || validator.isEmpty(data?.country)) errors.country = 'Please select country.'
  if (data?.description === null || data?.description?.trim() === '' || validator.isEmpty(data?.description))
    errors.description = 'Please enter description.'

  const numReg = /^[-+]?(\d+(\.\d+)?)?$/

  if (data?.fees === null || data?.fees === '') {
    errors.fees = 'Please enter fees.'
  } else if (parseFloat(data?.fees) < 0) {
    errors.fees = 'Please enter valid fee.'
  } else if (data?.fees?.includes("-")) {
    const numberOfHyphens = data?.fees.split('-').length - 1;
    if (numberOfHyphens === 1) {
      const newData = data?.fees.split('-')
      if (!(numReg.test(newData[0])) || !(numReg.test(newData[1]))) {
        errors.fees = 'Please enter valid range.'
      } else if (parseInt(newData[0]) >= parseInt(newData[1])) {
        errors.fees = 'Right side value should be greater than left side value.'
      }
    } else {
      errors.fees = 'The fee should be a numeric value from 0 to 9, or a range specified with a hyphen, such as 10-100, or I.C.'
    }
  } else if (alphabetRegex.test(data?.fees)) {
    if (!icRegex.test(data?.fees?.toLowerCase())) {
      errors.fees = 'The fee should be a numeric value from 0 to 9, or a range specified with a hyphen, such as 10-100, or I.C.'
    }
  } else if (!(numReg.test(data?.fees))) {
    errors.fees = 'The fee should be a numeric value from 0 to 9, or a range specified with a hyphen, such as 10-100, or I.C.'
  }

  if (data?.is_additional === null) errors.is_additional = 'This field is required'
  if (data?.hygiene_tag_checked && (data?.hygiene_tag === null || data?.hygiene_tag === '')) errors.hygiene_tag = 'This field is required'
  if (data?.hygiene_tag && data?.cross_functional === null) errors.cross_functional = 'This field is required'

  return { errors, isValid: Object.keys(errors).length <= 0 }
}
