import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import FormGroup from '@mui/material/FormGroup'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import QIcon from '../../../assets/images/QIcon.png'
import {
  AddEditProcedureCodeRequestAction,
  getProcedureCodeListRequestAction,
  updateProcedureDataValidationsAction,
  updateTreatmentProcedureAction,
} from '../../../redux/actions/treatmentAndProcedureCodes/treatmentAndProcedureCodesAction'
import { RootState } from '../../../redux/reducers/rootReducer'
import theme from '../../../theme'
import { AvoidSpace, MenuProps } from '../../../utils/helper'
import {
  countryList,
  dropdownTypeList,
  isAdditionalList,
  typeList,
} from '../../../utils/ProcedureTreatmentConstant/ProcedureTreatmentConstant'
import { addEditProcedureValidator } from '../../../validation/treatmentAndProcedureCodes/addEditProcedureValidator'

const useStyles = makeStyles((theme: any) => ({
  cancelButton: {
    borderColor: theme.palette.secondary.main,
    background: theme.palette.common.white,
    color: theme.palette.secondary.main,
    height: '40px',
    boxShadow: 'none !important',
    '&:hover': {
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      background: theme.palette.common.white,
    },
  },
  confirmButton: {
    background: theme.palette.secondary.main,
    color: theme.palette.common.white,
    height: '40px',
    boxShadow: 'none !important',
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },
  },
  dialogTitle: {
    padding: '0 0 0 8px',
    fontSize: '16px',
  },
  label: {
    marginBottom: '8px',
    color: theme.palette.common.black50,
  },
  textField: {
    '& .MuiInputBase-input': {
      fontFamily: 'FavoritPro-Regular',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      display: 'none',
    },
  },
  radioButton: {
    display: 'flex',
    flexDirection: 'row',
    '& .Mui-checked': {
      color: theme.palette.secondary.main,
    },
  },
  scheduleAppointment: {
    height: '40px',
  },
  DialogActionsClass: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
}))

type Props = {
  open: boolean
  handleClose: any
  isEdit: boolean
  setOpen: any
  skip: number
  limit: number
  search: string
}

const AddEditProcedurePopup = (props: Props) => {
  const { open, handleClose, isEdit, setOpen, skip, limit, search } = props as Props
  const classes = useStyles()
  const palette = theme.palette
  const dispatch = useDispatch()
  const {
    treatmentAndProcedureCodes: { procedureDataLocal, procedureDataError },
  } = useSelector((state: RootState) => state) as any

  const handleChange = (e: any) => {
    const { value, name, checked } = e?.target as any

    const updatedTreatmentProcedureData = procedureDataLocal
    if (name === 'cross_functional') {
      updatedTreatmentProcedureData['cross_functional'] = checked
    } else {
      updatedTreatmentProcedureData[name] = value
    }

    dispatch(updateTreatmentProcedureAction(updatedTreatmentProcedureData))

    // for the update error
    const procedureDataValid = addEditProcedureValidator(updatedTreatmentProcedureData) as any

    if (procedureDataError?.[name]) {
      dispatch(
        updateProcedureDataValidationsAction({
          ...procedureDataError,
          [name]: procedureDataValid[name],
        })
      )
    }
  }

  const handleConfirm = () => {
    const { errors, isValid } = addEditProcedureValidator(procedureDataLocal)
    dispatch(updateProcedureDataValidationsAction(errors))
    if (isValid) {
      dispatch(
        AddEditProcedureCodeRequestAction({
          code: procedureDataLocal?.code,
          description: procedureDataLocal?.description,
          fees: procedureDataLocal?.fees,
          country: procedureDataLocal?.country,
          is_additional: procedureDataLocal?.is_additional,
          code_type: procedureDataLocal?.code_type,
          ID: procedureDataLocal?.ID,
          hygiene_tag: procedureDataLocal?.hygiene_tag,
          cross_functional: procedureDataLocal?.cross_functional,
          callback: () => {
            dispatch(
              getProcedureCodeListRequestAction({
                page: skip,
                size: limit,
                search,
              })
            )
          },
        })
      )
      setOpen(false)
      dispatch(updateProcedureDataValidationsAction({}))
      const initialVal = {
        country: null,
        code_type: null,
        hygiene_tag: null,
        cross_functional: null,
        is_additional: null,
        code: null,
        fees: null,
        description: null,
      } as any
      dispatch(updateTreatmentProcedureAction(initialVal))
    }
  }

  return (
    <div>
      <Dialog
        sx={{
          '& .MuiDialogContent-root': {
            padding: '0 16px 20px 16px !important',
          },
          '& .MuiPaper-root ': {
            width: '471px !important',
            background: theme.palette.common.white,
            boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.07)',
            borderRadius: '8px !important',
            display: 'flex !important',
            alignItems: 'center !important',
            justifyContent: 'center !important',
          },
        }}
        open={open}
        onClose={() => handleClose()}
      >
        <DialogActions className={classes.DialogActionsClass}>
          <Box className={'d-flex align-center w-100'} padding={'16px'}>
            <img src={QIcon} alt="QIcon" height={'20px'} width={'20px'} />
            <DialogTitle className={`${classes.dialogTitle} fw-medium`} id="alert-dialog-title">
              {isEdit ? 'Edit Procedure Code' : 'Add New Procedure Code'}
            </DialogTitle>
          </Box>
          <Divider sx={{ width: '100%', color: palette.text.secondary }} />
        </DialogActions>
        <DialogContent sx={{ width: '100%' }}>
          <Grid container={true} spacing={1.5} mt={'1px'}>
            <Grid item={true} xs={12}>
              <FormControl>
                <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                  Select Country
                </FormLabel>
                <RadioGroup
                  name="country"
                  className={`fw-regular`}
                  onChange={(e) => handleChange(e)}
                  value={procedureDataLocal?.country}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    '& .Mui-checked': {
                      color: isEdit ? `${palette.text.secondary} !important` : `${palette.secondary.main} !important`,
                    },
                    '& .MuiButtonBase-root': {
                      color: isEdit ? `${palette.text.secondary} !important` : `${palette.common.black50} !important`,
                    },
                  }}
                >
                  {countryList?.map((res: any) => (
                    <FormControlLabel disabled={isEdit} key={res?.value} value={res?.value} control={<Radio />} label={res?.label} />
                  ))}
                </RadioGroup>
              </FormControl>
              {procedureDataError?.country && (
                <Typography color={theme.palette.error.main} className="f-12 lh-14">
                  {procedureDataError?.country}
                </Typography>
              )}
            </Grid>

            <Grid item={true} xs={12}>
              <FormControl>
                <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                  Type
                </FormLabel>
                <RadioGroup
                  name="code_type"
                  onChange={(e) => handleChange(e)}
                  className={`${classes.radioButton} fw-regular`}
                  value={procedureDataLocal?.code_type}
                >
                  {typeList?.map((res: any) => (
                    <FormControlLabel key={res?.value} value={res?.value} control={<Radio />} label={res?.label} />
                  ))}
                </RadioGroup>
              </FormControl>
              {procedureDataError?.code_type && (
                <Typography color={theme.palette.error.main} className="f-12 lh-14">
                  {procedureDataError?.code_type}
                </Typography>
              )}
            </Grid>

            <Grid item={true} xs={12}>
              <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                Hygiene Tag
              </FormLabel>
              <FormControl fullWidth={true} sx={{ borderRadius: '8px', marginTop: '8px' }}>
                <Select
                  name="hygiene_tag"
                  value={procedureDataLocal?.hygiene_tag || ''}
                  displayEmpty={true}
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={(e) => handleChange(e)}
                  className={`${classes.scheduleAppointment} fw-regular f-w-350 border-radius-8`}
                  sx={{
                    '& fieldset': {
                      borderColor: procedureDataError?.hygiene_tag ? theme.palette.error.main : theme.palette.secondary.main,
                    },
                    '&:hover fieldset': {
                      borderColor: palette.secondary.main,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: `${palette.secondary.main} !important`,
                    },
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem className={`fw-regular`} value={''}>
                    Select
                  </MenuItem>
                  {dropdownTypeList &&
                    dropdownTypeList?.map((res: any) => (
                      <MenuItem key={res?.value} className={`fw-regular`} value={res?.value}>
                        {res?.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {procedureDataError?.hygiene_tag && (
                <Typography color={theme.palette.error.main} className="f-12 lh-14">
                  {procedureDataError?.hygiene_tag}
                </Typography>
              )}
            </Grid>

            {procedureDataLocal?.hygiene_tag && (
              <Grid item={true} xs={12} sx={{ paddingTop: '8px !important' }}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={procedureDataLocal?.cross_functional}
                        onChange={(e) => handleChange(e)}
                        name="cross_functional"
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label="Hygiene Regime and Treatment Both"
                    sx={{
                      '& .MuiButtonBase-root': {
                        color: procedureDataLocal?.cross_functional ? palette.secondary.main : '',
                      },
                    }}
                  />
                </FormGroup>
              </Grid>
            )}

            <Grid item={true} xs={12}>
              <FormControl>
                <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                  Is Additional
                </FormLabel>
                <RadioGroup
                  name="is_additional"
                  className={`${classes.radioButton} fw-regular`}
                  value={procedureDataLocal?.is_additional}
                  onChange={(e) => handleChange(e)}
                >
                  {isAdditionalList?.map((res: any) => (
                    <FormControlLabel key={res?.value} value={res?.value} control={<Radio />} label={res?.label} />
                  ))}
                </RadioGroup>
              </FormControl>
              {procedureDataError?.is_additional && (
                <Typography color={theme.palette.error.main} className="f-12 lh-14">
                  {procedureDataError?.is_additional}
                </Typography>
              )}
            </Grid>

            <Grid item={true} xs={12} pt={'1px !important'} color={palette.grey['A700']}>
              *The fee should be a numeric value from 0 to 9, or a range specified with a hyphen, such as 10-100, or I.C.
            </Grid>

            <Grid item={true} xs={6}>
              <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                Procedure Code
              </FormLabel>
              <TextField
                disabled={isEdit}
                fullWidth={true}
                name="code"
                onChange={(e) => handleChange(e)}
                color="secondary"
                value={procedureDataLocal?.code}
                inputProps={{
                  maxLength: 15,
                }}
                sx={{
                  marginTop: '8px',
                  '& .Mui-focused': {
                    borderColor: `${palette.secondary.main} !important`,
                  },
                }}
                error={procedureDataError?.code}
                helperText={procedureDataError?.code}
              />
            </Grid>

            <Grid item={true} xs={6}>
              <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                Fees
              </FormLabel>
              <TextField
                name="fees"
                fullWidth={true}
                onChange={(e) => handleChange(e)}
                color="secondary"
                value={procedureDataLocal?.fees}
                sx={{
                  marginTop: '8px',
                  '& .Mui-focused': {
                    borderColor: `${palette.secondary.main} !important`,
                  },
                }}
                error={procedureDataError?.fees}
                helperText={procedureDataError?.fees}
              />
            </Grid>

            <Grid item={true} xs={12} display={'flex'} flexDirection={'column'}>
              <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                Description
              </FormLabel>
              <TextField
                onKeyPress={(e: any) => AvoidSpace(e)}
                onChange={(e) => handleChange(e)}
                fullWidth={true}
                name="description"
                color="secondary"
                value={procedureDataLocal?.description}
                sx={{
                  marginTop: '8px',
                  '& .Mui-focused': {
                    borderColor: `${palette.secondary.main} !important`,
                  },
                }}
                inputProps={{
                  maxLength: 225,
                }}
                error={procedureDataError?.description}
                helperText={procedureDataError?.description}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '16px' }}>
          <Divider sx={{ width: '100%', color: palette.text.secondary }} />
          <Box className={`d-flex align-center justify-content-between w-100`} mt={1.5}>
            <Button
              onClick={() => handleClose()}
              variant="outlined"
              className={`${classes.cancelButton} border-radius-10 text-decoration-none`}
            >
              Cancel
            </Button>
            <Button onClick={() => handleConfirm()} className={`${classes.confirmButton} border-radius-10 text-decoration-none`}>
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddEditProcedurePopup
