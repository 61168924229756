import {
  CHANGE_CONTACT_US,
  UPDATE_CONTACT_US,
  GET_CONTACT_REQUEST,
  GET_CONTACT_REQUEST_SUCCESS,
  GET_CONTACT_REQUEST_FAILURE,
  GET_CONTACT_CACHE_DATA_CLEAR_REQUEST,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_REQUEST_SUCCESS,
  UPDATE_CONTACT_REQUEST_FAILURE,
} from '../../actions/actionTypes'
import { ContactUsInterface, ContactUsAction } from '../../schema/contactUS/contactUsSchema'

const initialState: ContactUsInterface = {
  ContactUsDataLocal: null,
  email: '',
  contact_number: '',

  getContactLoader: false,
  getContactData: null,
  getContactError: null,

  contactUpdateLoader: false,
  contactUpdateData: undefined,
  contactUpdateError: null,
}

const contactUsReducer = (state = initialState, action: ContactUsAction) => {
  switch (action.type) {
    case UPDATE_CONTACT_US:
      return {
        ...state,
        ContactUsDataLocal: action.payload,
      }
    case CHANGE_CONTACT_US:
      return {
        ...state,
        getContactData: action?.payload,
      }

    //get contact details
    case GET_CONTACT_REQUEST:
      return {
        ...state,
        getContactLoader: true,
      }
    case GET_CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        getContactLoader: false,
        getContactData: action?.payload?.getContactData,
        getContactMeta: action?.payload?.getContactMeta,
        getContactError: null,
      }
    case GET_CONTACT_REQUEST_FAILURE:
      return {
        ...state,
        getContactLoader: false,
        getContactData: null,
        getContactError: action?.payload?.getContactError,
      }
    case GET_CONTACT_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getCmsData: null,
      }

    //update
    case UPDATE_CONTACT_REQUEST:
      return {
        ...state,
        contactUpdateLoader: true,
      }
    case UPDATE_CONTACT_REQUEST_SUCCESS:
      return {
        ...state,
        contactUpdateLoader: false,
        contactUpdateData: action.payload,
        contactUpdateError: null,
      }
    case UPDATE_CONTACT_REQUEST_FAILURE:
      return {
        ...state,
        contactUpdateLoader: false,
        contactUpdateData: null,
        contactUpdateError: action.payload.contactUpdateError,
      }

    default:
      return {
        ...state,
      }
  }
}

export default contactUsReducer
