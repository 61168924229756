import API from '../../../utils/api'
import { all, put, call, takeEvery } from 'redux-saga/effects'
import { GET_PROFILE_INFO_REQUEST, EDIT_PROFILE_INFO_REQUEST } from '../../actions/actionTypes'
import {
  getProfileInfoRequestSuccess,
  getProfileInfoRequestFailure,
  editProfileInfoRequestSuccess,
  editProfileInfoRequestFailure,
  getProfileInfoCacheDataClearRequest,
  editProfileInfoCacheDataClearRequest,
} from '../../actions/myProfile/profile'
import { errorToaster, successToaster } from '../../../utils/helper'

function* getProfileInfoRequestSaga(payload: any): any {
  yield put(getProfileInfoCacheDataClearRequest())
  try {
    const response = yield API.get(`/v2/admin/profile/${payload?.payload?.admin_id}`)
    yield put(getProfileInfoRequestSuccess({ profileData: response?.data?.detail }))
  } catch (e: any) {
    yield put(getProfileInfoRequestFailure({ profileError: e?.detail }))
  }
}

function* editProfileInfoRequestSaga(payload: any): any {
  yield put(editProfileInfoCacheDataClearRequest())
  try {
    const editProfilePayload = payload?.payload?.profile_picture ? payload?.payload?.profile_picture : payload?.payload?.data
    const response = yield API.put(`/v2/admin/profile`, editProfilePayload)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(editProfileInfoRequestSuccess({ editProfileData: response?.data?.detail }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    if (payload?.payload?.errorCallback) {
      payload?.payload?.errorCallback()
    }
    errorToaster(e?.message)
    yield put(editProfileInfoRequestFailure({ editProfileError: e?.message }))
  }
}

function* getProfileInfoSaga() {
  yield takeEvery(GET_PROFILE_INFO_REQUEST, getProfileInfoRequestSaga)
}
function* editProfileInfoSaga() {
  yield takeEvery(EDIT_PROFILE_INFO_REQUEST, editProfileInfoRequestSaga)
}

export default function* profileSaga() {
  yield all([getProfileInfoSaga(), editProfileInfoSaga()])
}
