import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster } from '../../../utils/helper'
import { GET_PATIENT_REQUEST, VIEW_PATIENT_REQUEST } from '../../actions/actionTypes'
import {
  getPatientCacheDataClearRequest,
  getPatientRequestFailure,
  getPatientRequestSuccess,
  viewPatientCacheDataClearRequest,
  viewPatientRequestFailure,
  viewPatientRequestSuccess,
} from '../../actions/patient/patientAction'

// get all the patient (patient list)
function* getPatientRequestSaga(payload: any): any {
  yield put(getPatientCacheDataClearRequest())
  try {
    const params = [
      { key: 'search', value: payload.payload.search },
      { key: 'page', value: payload.payload.page },
      { key: 'size', value: payload.payload.size },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `/v2/patient/get/all${tmpUrl}`
    const url = newUrl
    const response = yield API.get(url)
    yield put(
      getPatientRequestSuccess({
        getPatientData: response.data?.detail,
        getPatientMeta: response?.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getPatientRequestFailure({
        getPatientError: e?.message,
      })
    )
  }
}

// view patient details
function* viewPatientRequestSaga(payload: any): any {
  yield put(viewPatientCacheDataClearRequest())
  const ownerUrl = `/v2/patient/${payload?.payload?.patient_id}?clinic_id=${payload?.payload?.clinic_id}`
  try {
    const response = yield API.get(ownerUrl)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(
      viewPatientRequestSuccess({
        viewPatientData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      viewPatientRequestFailure({
        viewPatientError: e?.message,
      })
    )
  }
}

function* getPatientSaga() {
  yield takeEvery(GET_PATIENT_REQUEST, getPatientRequestSaga)
}

function* viewPatientSaga() {
  yield takeEvery(VIEW_PATIENT_REQUEST, viewPatientRequestSaga)
}

export default function* patientSaga() {
  yield all([getPatientSaga(), viewPatientSaga()])
}
