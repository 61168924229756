import {
  GET_CMS_REQUEST,
  GET_CMS_REQUEST_SUCCESS,
  GET_CMS_REQUEST_FAILURE,
  GET_CMS_CACHE_DATA_CLEAR_REQUEST,
  VIEW_CMS_REQUEST,
  VIEW_CMS_REQUEST_SUCCESS,
  VIEW_CMS_REQUEST_FAILURE,
  VIEW_CMS_CACHE_DATA_CLEAR_REQUEST,
  UPDATE_CMS_STATUS_REQUEST,
  UPDATE_CMS_STATUS_REQUEST_SUCCESS,
  UPDATE_CMS_STATUS_REQUEST_FAILURE,
  EDIT_CMS_DATA,
} from '../../actions/actionTypes'
import { CmsInterface, CmsReqActions } from '../../schema/cms/cmsSchema'

const initialState: CmsInterface = {
  getCmsLoader: false,
  getCmsData: null,
  getCmsError: null,

  viewCmsLoader: false,
  viewCmsData: null,
  viewCmsError: null,

  cmsStatusUpdateLoader: false,
  cmsStatusUpdateData: undefined,
  cmsStatusUpdateError: null
}

const cmsReducer = (state = initialState, action: CmsReqActions) => {
  switch (action.type) {
    case GET_CMS_REQUEST:
      return {
        ...state,
        getCmsLoader: true,
      }
    case GET_CMS_REQUEST_SUCCESS:
      return {
        ...state,
        getCmsLoader: false,
        getCmsData: action?.payload?.getCmsData,
        getCmsMeta: action?.payload?.getCmsMeta,
        getCmsReqError: null,
      }
    case GET_CMS_REQUEST_FAILURE:
      return {
        ...state,
        getCmsLoader: false,
        getCmsData: null,
        getCmsError: action?.payload?.getCmsError,
      }
    case GET_CMS_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getCmsData: null,
      }

    //view
    case VIEW_CMS_REQUEST:
      return {
        ...state,
        viewCmsLoader: true,
      }
    case VIEW_CMS_REQUEST_SUCCESS:
      return {
        ...state,
        viewCmsLoader: false,
        viewCmsData: action.payload?.viewCmsData,
        viewCmsError: null,
      }
    case EDIT_CMS_DATA:
      return {
        ...state,
        viewCmsData: { ...state.viewCmsData, description: action.payload?.viewCmsData },
      }
    case VIEW_CMS_REQUEST_FAILURE:
      return {
        ...state,
        viewCmsLoader: false,
        viewCmsData: null,
        viewCmsError: action.payload.viewCmsError,
      }
    case VIEW_CMS_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        viewCmsData: null,
      }

    //update
    case UPDATE_CMS_STATUS_REQUEST:
      return {
        ...state,
        cmsStatusUpdateLoader: true,
      }
    case UPDATE_CMS_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        cmsStatusUpdateLoader: false,
        cmsStatusUpdateData: action.payload,
        cmsStatusUpdateError: null,
      }
    case UPDATE_CMS_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        cmsStatusUpdateLoader: false,
        cmsStatusUpdateData: null,
        cmsStatusUpdateError: action.payload.cmsStatusUpdateError,
      }

    default:
      return {
        ...state,
      }
  }
}
export default cmsReducer
