import { removeRefFromJson } from '../../../utils/helper'
import {
  UPDATE_PROCEDURE_CODE_DATA_REQUEST,
  UPDATE_TREATMENT_DATA_REQUEST,
  GET_PROCEDURE_CODE_LIST_REQUEST,
  GET_PROCEDURE_CODE_LIST_SUCCESS,
  GET_PROCEDURE_CODE_LIST_FAIL,
  GET_TREATMENT_LIST_REQUEST,
  GET_TREATMENT_LIST_SUCCESS,
  GET_TREATMENT_LIST_FAIL,
  ADD_EDIT_PROCEDURE_CODE_REQUEST,
  ADD_EDIT_PROCEDURE_CODE_SUCCESS,
  ADD_EDIT_PROCEDURE_CODE_FAIL,
  GET_ALL_PROCEDURE_DATA_REQUEST,
  GET_ALL_PROCEDURE_DATA_REQUEST_SUCCESS,
  GET_ALL_PROCEDURE_DATA_REQUEST_FAIL,
  UPDATE_PROCEDURE_DATA_VALIDATION,
  UPDATE_TREATMENT_DATA_VALIDATION,
  DOWNLOAD_CSV_FILE_REQUEST,
  DOWNLOAD_CSV_FILE_REQUEST_SUCCESS,
  DOWNLOAD_CSV_FILE_REQUEST_FAIL,
  UPLOAD_CSV_FILE_REQUEST,
UPLOAD_CSV_FILE_REQUEST_SUCCESS,
UPLOAD_CSV_FILE_REQUEST_FAIL,
} from '../../actions/actionTypes'
import {
  TreatmentProcedureAction,
  TreatmentProcedureInterface,
} from '../../schema/treatmentAndProcedureCodes/treatmentAndProcedureCodesSchema'

const initialState: TreatmentProcedureInterface = {
  procedureDataLocal: {
    country: null,
    code_type: null,
    hygiene_tag: null,
    cross_functional: null,
    is_additional: null,
    code: null,
    fees: null,
    description: null,
  },
  treatmentDataLocal: {
    default_code: null,
    multiple_codes: null,
    additional_codes: null,
    country: 'CAN',
    title: null,
    hygiene_tag: '',
    tx_title: null,
  },

  getProcedureCodeListLoader: false,
  getProcedureCodeListData: null,
  getProcedureCodeListError: null,

  addEditProcedureData: null,
  addEditProcedureLoader: false,
  addEditProcedureError: null,

  getTreatmentListLoader: false,
  getTreatmentListData: null,
  getTreatmentListMeta: null,
  getTreatmentListError: null,

  getAllProcedureLoader: false,
  getAllProcedureData: {
    getAllAdditionalProcedureData: null,
    allProcedureData: null,
  },

  downloadCsvLoader: false,
  downloadCsvData: null,
  downloadCsvError: null,

  uploadCsvLoader: false,
  uploadCsvData: null,
  uploadCsvError: null,

  getAllProcedureError: null,
  procedureDataError: {},
  treatmentDataError: {},
}

const treatmentAndProcedureCodesReducer = (state = initialState, action: TreatmentProcedureAction) => {
  switch (action.type) {
    case UPDATE_PROCEDURE_CODE_DATA_REQUEST:
      return {
        ...state,
        procedureDataLocal: action.payload,
      }
    case UPDATE_TREATMENT_DATA_REQUEST:
      return {
        ...state,
        treatmentDataLocal: action.payload,
      }

    case GET_PROCEDURE_CODE_LIST_REQUEST:
      return {
        ...state,
        getProcedureCodeListLoader: true,
        getProcedureCodeListData: null,
        getProcedureCodeListError: null,
      }
    case GET_PROCEDURE_CODE_LIST_SUCCESS:
      return {
        ...state,
        getProcedureCodeListLoader: false,
        getProcedureCodeListData: action.payload,
        getProcedureCodeListError: null,
      }
    case GET_PROCEDURE_CODE_LIST_FAIL:
      return {
        ...state,
        getProcedureCodeListLoader: false,
        getProcedureCodeListData: null,
        getProcedureCodeListError: action.payload,
      }
    case ADD_EDIT_PROCEDURE_CODE_REQUEST:
      return {
        ...state,
        addEditProcedureData: true,
        addEditProcedureLoader: null,
        addEditProcedureError: null,
      }
    case ADD_EDIT_PROCEDURE_CODE_SUCCESS:
      return {
        ...state,
        addEditProcedureData: false,
        addEditProcedureLoader: action.payload,
        addEditProcedureError: null,
      }
    case ADD_EDIT_PROCEDURE_CODE_FAIL:
      return {
        ...state,
        addEditProcedureData: false,
        addEditProcedureLoader: null,
        addEditProcedureError: action.payload,
      }
    case GET_TREATMENT_LIST_REQUEST:
      return {
        ...state,
        getTreatmentListLoader: true,
        getTreatmentListData: null,
        getTreatmentListMeta: null,
        getTreatmentListError: null,
      }
    case GET_TREATMENT_LIST_SUCCESS:
      return {
        ...state,
        getTreatmentListLoader: false,
        getTreatmentListData: action.payload,
        getTreatmentListError: null,
      }
    case GET_TREATMENT_LIST_FAIL:
      return {
        ...state,
        getTreatmentListLoader: false,
        getTreatmentListData: null,
        getTreatmentListMeta: null,
        getTreatmentListError: action.payload,
      }

    case GET_ALL_PROCEDURE_DATA_REQUEST:
      return {
        ...state,
        getAllProcedureLoader: true,
        getAllProcedureError: null,
      }
    case GET_ALL_PROCEDURE_DATA_REQUEST_SUCCESS:
      let newState = removeRefFromJson(state.getAllProcedureData) as any
      const { isAdditional, data } = action.payload as any
      if (isAdditional) {
        newState.getAllAdditionalProcedureData = data
      } else {
        newState.allProcedureData = data
      }

      return {
        ...state,
        getAllProcedureLoader: false,
        getAllProcedureData: newState,
        getAllProcedureError: null,
      }
    case GET_ALL_PROCEDURE_DATA_REQUEST_FAIL:
      return {
        ...state,
        getAllProcedureLoader: false,
        getAllProcedureError: action.payload,
      }
    case UPDATE_PROCEDURE_DATA_VALIDATION:
      return {
        ...state,
        procedureDataError: action.payload,
      }
    case UPDATE_TREATMENT_DATA_VALIDATION:
      return {
        ...state,
        treatmentDataError: action.payload,
      }

    // Download CSV
    case DOWNLOAD_CSV_FILE_REQUEST:
      return {
        ...state,
        downloadCsvLoader: true,
        downloadCsvData: null,
        downloadCsvError: null,
      }
    case DOWNLOAD_CSV_FILE_REQUEST_SUCCESS:
      return {
        ...state,
        downloadCsvLoader: false,
        downloadCsvData: action.payload,
        downloadCsvError: null,
      }
    case DOWNLOAD_CSV_FILE_REQUEST_FAIL:
      return {
        ...state,
        downloadCsvLoader: false,
        downloadCsvData: null,
        downloadCsvError: action.payload,
      }

    // Download CSV
    case UPLOAD_CSV_FILE_REQUEST:
      return {
        ...state,
        uploadCsvLoader: true,
        uploadCsvData: null,
        uploadCsvError: null,
      }
    case UPLOAD_CSV_FILE_REQUEST_SUCCESS:
      return {
        ...state,
        uploadCsvLoader: false,
        uploadCsvData: action.payload,
        uploadCsvError: null,
      }
    case UPLOAD_CSV_FILE_REQUEST_FAIL:
      return {
        ...state,
        uploadCsvLoader: false,
        uploadCsvData: null,
        uploadCsvError: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}

export default treatmentAndProcedureCodesReducer
