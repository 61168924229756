import {
  GET_CLINIC_REQ_LIST_REQUEST,
  GET_CLINIC_REQ_LIST_REQUEST_SUCCESS,
  GET_CLINIC_REQ_LIST_REQUEST_FAILURE,
  GET_CLINIC_REQ_LIST_CACHE_DATA_CLEAR_REQUEST,
  CLINIC_REQ_DETAILS_REQUEST,
  CLINIC_REQ_DETAILS_REQUEST_SUCCESS,
  CLINIC_REQ_DETAILS_REQUEST_FAILURE,
  CLINIC_REQ_DETAILS_CACHE_DATA_CLEAR_REQUEST,
  APPROVE_CLINIC_REQUEST,
  APPROVE_CLINIC_SUCCESS,
  APPROVE_CLINIC_FAILURE,
  REJECT_CLINIC_REQUEST,
  REJECT_CLINIC_REQUEST_SUCCESS,
  REJECT_CLINIC_REQUEST_FAILURE,
  GET_PMS_PRACTICE_REQUEST,
  GET_PMS_PRACTICE_REQUEST_SUCCESS,
  GET_PMS_PRACTICE_REQUEST_FAILURE,
} from '../actionTypes'
import {
  GetClinicReqListRequest,
  GetClinicReqListRequestSuccess,
  GetClinicReqListRequestFailure,
  getClinicReqListRequestPayload,
  getClinicReqListRequestSuccessPayload,
  getClinicReqListRequestFailurePayload,
  GetClinicReqDetailsRequest,
  GetClinicReqDetailsRequestSuccess,
  GetClinicReqDetailsRequestFailure,
  getClinicReqDetailsRequestPayload,
  getClinicReqDetailsRequestSuccessPayload,
  getClinicReqDetailsRequestFailurePayload,
  ApproveClinicRequest,
  ApproveClinicRequestSuccess,
  ApproveClinicRequestFailure,
  ApproveClinicRequestPayload,
  ApproveClinicRequestSuccessPayload,
  ApproveClinicRequestFailurePayload,
  RejectClinicRequest,
  RejectClinicRequestSuccess,
  RejectClinicRequestFailure,
  RejectClinicRequestPayload,
  RejectClinicRequestSuccessPayload,
  RejectClinicRequestFailurePayload,
  GetPmsPracticeRequest,
  GetPmsPracticeRequestSuccess,
  GetPmsPracticeRequestFailure,
  GetPmsPracticeRequestPayload,
  GetPmsPracticeRequestSuccessPayload,
  GetPmsPracticeRequestFailurePayload,
} from '../../schema/clinic/clinicRequestSchema'

export const clinicReqListRequest = (payload: getClinicReqListRequestPayload): GetClinicReqListRequest => ({
  type: GET_CLINIC_REQ_LIST_REQUEST,
  payload,
})
export const clinicReqListRequestSuccess = (payload: getClinicReqListRequestSuccessPayload): GetClinicReqListRequestSuccess => ({
  type: GET_CLINIC_REQ_LIST_REQUEST_SUCCESS,
  payload,
})
export const clinicReqListRequestFailure = (payload: getClinicReqListRequestFailurePayload): GetClinicReqListRequestFailure => ({
  type: GET_CLINIC_REQ_LIST_REQUEST_FAILURE,
  payload,
})
export const getClinicReqListCacheDataClearRequest = (): any => ({
  type: GET_CLINIC_REQ_LIST_CACHE_DATA_CLEAR_REQUEST,
})

// get clinic request details
export const clinicReqDetailsRequest = (payload: getClinicReqDetailsRequestPayload): GetClinicReqDetailsRequest => ({
  type: CLINIC_REQ_DETAILS_REQUEST,
  payload,
})
export const clinicReqDetailsRequestSuccess = (payload: getClinicReqDetailsRequestSuccessPayload): GetClinicReqDetailsRequestSuccess => ({
  type: CLINIC_REQ_DETAILS_REQUEST_SUCCESS,
  payload,
})
export const clinicReqDetailsRequestFailure = (payload: getClinicReqDetailsRequestFailurePayload): GetClinicReqDetailsRequestFailure => ({
  type: CLINIC_REQ_DETAILS_REQUEST_FAILURE,
  payload,
})
export const getClinicReqDetailsCacheDataClearRequest = (): any => ({
  type: CLINIC_REQ_DETAILS_CACHE_DATA_CLEAR_REQUEST,
})

// approve clinic
export const approveClinicRequest = (payload: ApproveClinicRequestPayload): ApproveClinicRequest => ({
  type: APPROVE_CLINIC_REQUEST,
  payload,
})
export const approveClinicRequestSuccess = (payload: ApproveClinicRequestSuccessPayload): ApproveClinicRequestSuccess => ({
  type: APPROVE_CLINIC_SUCCESS,
  payload,
})
export const approveClinicRequestFailure = (payload: ApproveClinicRequestFailurePayload): ApproveClinicRequestFailure => ({
  type: APPROVE_CLINIC_FAILURE,
  payload,
})

//reject clinic
export const rejectClinicRequest = (payload: RejectClinicRequestPayload): RejectClinicRequest => ({
  type: REJECT_CLINIC_REQUEST,
  payload,
})
export const rejectClinicRequestSuccess = (payload: RejectClinicRequestSuccessPayload): RejectClinicRequestSuccess => ({
  type: REJECT_CLINIC_REQUEST_SUCCESS,
  payload,
})
export const rejectClinicRequestFailure = (payload: RejectClinicRequestFailurePayload): RejectClinicRequestFailure => ({
  type: REJECT_CLINIC_REQUEST_FAILURE,
  payload,
})

// get practice id using master id
export const getPmsPracticeRequest = (payload: GetPmsPracticeRequestPayload): GetPmsPracticeRequest => ({
  type: GET_PMS_PRACTICE_REQUEST,
  payload,
})
export const getPmsPracticeRequestSuccess = (payload: GetPmsPracticeRequestSuccessPayload): GetPmsPracticeRequestSuccess => ({
  type: GET_PMS_PRACTICE_REQUEST_SUCCESS,
  payload,
})
export const getPmsPracticeRequestFailure = (payload: GetPmsPracticeRequestFailurePayload): GetPmsPracticeRequestFailure => ({
  type: GET_PMS_PRACTICE_REQUEST_FAILURE,
  payload,
})
