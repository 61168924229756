import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Box } from '@mui/system'
import { TableCell, TableRow } from '@mui/material'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((themes: any) => ({
  th: {
    padding: '10px 16px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '150%',
    color: themes.palette.common.white,
    borderBottom: 'none',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  tr: {
    borderRadius: '10px !important',
    '& td:first-child': {
      borderRadius: '10px 0 0 10px !important',
    },
    '& td:last-child': {
      borderRadius: '0 10px 10px 0 !important',
    },
  },
}))

type Props = {
  column?: number
}

const TableSkeleton = (props: Props) => {
  const classes = useStyles()
  const { column } = props

  return (
    <TableRow classes={{ root: classes.tr }}>
      {Array(column)
        ?.fill('value1', 1, column)
        .map((res: any, idx: number) => (
          <TableCell key={idx} classes={{ root: classes.th }} align='left'>
            <Box>
              <Skeleton variant='text' width={'100%'} height={40} className={'border-radius-10'} />
            </Box>
          </TableCell>
        ))}
    </TableRow>
  )
}

export default TableSkeleton
