import { CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_REQUEST_SUCCESS, CHANGE_PASSWORD_REQUEST_FAILURE } from '../actionTypes'
import {
  ChangePasswordRequest,
  ChangePasswordRequestSuccess,
  ChangePasswordRequestFailure,
  ChangePasswordRequestPayload,
  ChangePasswordRequestSuccessPayload,
  ChangePasswordFailurePayload,
} from '../../schema/auth/changePasswordSchema'

export const changePasswordRequest = (payload: ChangePasswordRequestPayload): ChangePasswordRequest => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload,
})

export const changePasswordRequestSuccess = (payload: ChangePasswordRequestSuccessPayload): ChangePasswordRequestSuccess => ({
  type: CHANGE_PASSWORD_REQUEST_SUCCESS,
  payload,
})

export const changePasswordRequestFailure = (payload: ChangePasswordFailurePayload): ChangePasswordRequestFailure => ({
  type: CHANGE_PASSWORD_REQUEST_FAILURE,
  payload,
})
