import { all, call, put, takeEvery } from 'redux-saga/effects'
import {
  GET_CLINIC_REQUEST,
  VIEW_CLINIC_REQUEST,
  UPDATE_CLINIC_STATUS_REQUEST,
  GET_PRACTICE_INFORMATION_REQUEST,
  PUT_MSA_AGREEMENT_REQUEST,
  GET_MSA_AGREEMENT_REQUEST,
} from '../../actions/actionTypes'
import {
  getClinicRequestSuccess,
  getClinicRequestFailure,
  viewClinicRequestSuccess,
  viewClinicRequestFailure,
  getClinicCacheDataClearRequest,
  viewClinicCacheDataClearRequest,
  clinicUpdateStatusRequestSuccess,
  clinicUpdateStatusRequestFailure,
  getPracticeInformationRequestSuccess,
  getPracticeInformationRequestFailure,
  putMsaAgreementRequestSuccess,
  putMsaAgreementRequestFailure,
  getMsaAgreementRequestSuccess,
  changeMsaAgreementRequest,
  getMsaAgreementRequestFailure,
} from '../../actions/clinic/clinicAction'
import API from '../../../utils/api'
import { errorToaster, successToaster } from '../../../utils/helper'

//get all the clinic (clinic list)
function* getClinicRequestSaga(payload: any): any {
  yield put(getClinicCacheDataClearRequest())
  try {
    const params = [
      { key: 'search', value: payload.payload.search },
      { key: 'page', value: payload.payload.page },
      { key: 'size', value: payload.payload.size },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `/v2/clinic/get/all${tmpUrl}`
    let url = newUrl
    const response = yield API.get(url)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(
      getClinicRequestSuccess({
        getClinicData: response.data?.detail,
        getClinicMeta: response.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getClinicRequestFailure({
        getClinicError: e?.message,
      })
    )
  }
}

// view clinic details
function* viewClinicRequestSaga(payload: any): any {
  yield put(viewClinicCacheDataClearRequest())
  try {
    const response = yield API.get(`/v2/clinic/${payload?.payload?.clinic_id}`)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    } // if needed in future
    // successToaster(response.data?.meta?.message)
    yield put(
      viewClinicRequestSuccess({
        viewClinicData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      viewClinicRequestFailure({
        viewClinicError: e?.message,
      })
    )
  }
}

//update clinic status
function* updateClinicStatusRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`v2/clinic/${payload.payload?.clinic_id}/status`, payload.payload)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    } // if needed in future
    // successToaster(response.data?.meta?.message)
    yield put(
      clinicUpdateStatusRequestSuccess({
        clinicStatusUpdateData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      clinicUpdateStatusRequestFailure({
        clinicStatusUpdateError: e?.message,
      })
    )
  }
}

// Get practice information
function* getPracticeInformation(payload: any): any {
  try {
    const response = yield API.get(`/v2/clinic/${payload?.payload?.clinic_id}/practice-information`)
    const data = response.data?.detail

    const payloadData = {
      email: data?.email,
      pm_software: data?.pm_software,
      imaging_software: data?.imaging_software,
      scheduling_software: data?.scheduling_software,
      intra_oral_3d_scanner: data?.intra_oral_3d_scanner,
      practice_days_no: data?.practice_days_no,
      dentists_no: data?.dentists_no?.toString(),
      assistants_no: data?.assistants_no?.toString(),
      hygienists_no: data?.hygienists_no?.toString(),
      npe_patients_no: data?.npe_patients_no?.toString(),
      recall_patient_no: data?.recall_patient_no?.toString(),
      other_technologies_used: data?.other_technologies_used,
      extra_comments: data?.extra_comments,
      exam_duration_new_patient: data?.exam_duration_new_patient?.split(',')?.filter((f: string) => f?.length),
      is_photos_taken: data?.is_photos_taken?.split(',')?.filter((f: string) => f?.length),
      how_preintake_data_collected: data?.how_preintake_data_collected?.split(',')?.filter((f: string) => f?.length),
      how_often_md_history_updated: data?.how_often_md_history_updated?.split(',')?.filter((f: string) => f?.length),
    } as any

    yield put(getPracticeInformationRequestSuccess(payloadData))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(getPracticeInformationRequestFailure(e?.message))
  }
}

function* putMsaAgreementSaga(payload: any): any {
  const payloadData = payload?.payload?.file
  try {
    const response = yield API.post(`/v2/clinic/${payload?.payload?.file?.get('clinic_id')}/msa`, payloadData)
    yield put(putMsaAgreementRequestSuccess(response.data?.detail))
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }
  } catch (e: any) {
    yield put(putMsaAgreementRequestFailure(e?.message))
  }
}

function* getMsaAgreementSaga(payload: any): any {
  const payloadData = payload?.payload

  try {
    const response = yield API.get(`/v2/clinic/${payload?.payload?.clinic_id}/msa`)
    yield put(getMsaAgreementRequestSuccess(response.data?.detail))

    yield put(changeMsaAgreementRequest(response.data?.detail))
    if (payloadData?.callback) {
      yield call(payloadData?.callback)
    }
  } catch (e: any) {
    yield put(getMsaAgreementRequestFailure(e?.message))
  }
}

function* getClinicSaga() {
  yield takeEvery(GET_CLINIC_REQUEST, getClinicRequestSaga)
}

function* viewClinicSaga() {
  yield takeEvery(VIEW_CLINIC_REQUEST, viewClinicRequestSaga)
}

function* updateClinicStatusSaga() {
  yield takeEvery(UPDATE_CLINIC_STATUS_REQUEST, updateClinicStatusRequestSaga)
}

function* getPracticeInformationWatch() {
  yield takeEvery(GET_PRACTICE_INFORMATION_REQUEST, getPracticeInformation)
}

function* putMsaAgreementWatch() {
  yield takeEvery(PUT_MSA_AGREEMENT_REQUEST, putMsaAgreementSaga)
}

function* getMsaAgreementWatch() {
  yield takeEvery(GET_MSA_AGREEMENT_REQUEST, getMsaAgreementSaga)
}

export default function* clinicSaga() {
  yield all([
    getClinicSaga(),
    viewClinicSaga(),
    updateClinicStatusSaga(),
    getPracticeInformationWatch(),
    putMsaAgreementWatch(),
    getMsaAgreementWatch(),
  ])
}
