import {
  PutSubscriptionPlanRequest,
  PutSubscriptionPlanRequestSuccess,
  PutSubscriptionPlanRequestFailure,
  PutSubscriptionPlanRequestPayload,
  PutSubscriptionPlanRequestSuccessPayload,
  PutSubscriptionPlanRequestFailurePayload,
  EditSubscriptionPlans,
  UpdateEditSubscriptionPlans,
  editSubscriptionPlansPayload,
  updateEditSubscriptionPlansPayload,
  ChangeTrailDayRequest,
  ChangeTrailDayPayload,
  GetTrailDaysRequest,
  GetTrailDaysRequestSuccess,
  GetTrailDaysRequestFailure,
  GetTrailDaysRequestPayload,
  GetTrailDaysRequestSuccessPayload,
  GetTrailDaysRequestFailurePayload,
  PutTrailDaysRequestPayload,
  PutTrailDaysRequest,
  PutTrailDaysRequestSuccessPayload,
  PutTrailDaysRequestSuccess,
  PutTrailDaysRequestFailurePayload,
  PutTrailDaysRequestFailure,
} from '../../schema/subscriptionPlans/subscriptionPlansSchema'
import {
  EDIT_SUBSCRIPTION_PLANS,
  GET_SUBSCRIPTION_PLANS_REQUEST,
  GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE,
  GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS,
  UPDATE_EDIT_SUBSCRIPTION_PLANS,
  PUT_SUBSCRIPTION_PLANS_REQUEST,
  PUT_SUBSCRIPTION_PLANS_REQUEST_SUCCESS,
  PUT_SUBSCRIPTION_PLANS_REQUEST_FAILURE,
  ADD_SUBSCRIPTION_PLAN,
  CHANGE_ADD_MODE_VALUE,
  CHANGE_TRAIL_DAYS,
  GET_TRAIL_DAYS_REQUEST,
  GET_TRAIL_DAYS_REQUEST_SUCCESS,
  GET_TRAIL_DAYS_REQUEST_FAILURE,
  PUT_TRAIL_DAYS_REQUEST,
  PUT_TRAIL_DAYS_REQUEST_SUCCESS,
  PUT_TRAIL_DAYS_REQUEST_FAILURE,
} from '../actionTypes'

export const getSubscriptionPlansAction = (payload: any) => ({
  type: GET_SUBSCRIPTION_PLANS_REQUEST,
  payload,
})

export const getSubscriptionPlansSuccess = (payload: any): any => ({
  type: GET_SUBSCRIPTION_PLANS_REQUEST_SUCCESS,
  payload,
})

export const getSubscriptionPlansFailure = (payload: any): any => ({
  type: GET_SUBSCRIPTION_PLANS_REQUEST_FAILURE,
  payload,
})

//edit plan action for the local
export const editSubscriptionPlans = (payload: editSubscriptionPlansPayload): EditSubscriptionPlans => ({
  type: EDIT_SUBSCRIPTION_PLANS,
  payload,
})

export const updateEditSubscriptionPlans = (payload: updateEditSubscriptionPlansPayload): UpdateEditSubscriptionPlans => ({
  type: UPDATE_EDIT_SUBSCRIPTION_PLANS,
  payload,
})

//edit plan action for the API
export const putSubscriptionPlansAction = (payload: PutSubscriptionPlanRequestPayload): PutSubscriptionPlanRequest => ({
  type: PUT_SUBSCRIPTION_PLANS_REQUEST,
  payload,
})
export const putSubscriptionPlansSuccess = (payload: PutSubscriptionPlanRequestSuccessPayload): PutSubscriptionPlanRequestSuccess => ({
  type: PUT_SUBSCRIPTION_PLANS_REQUEST_SUCCESS,
  payload,
})
export const putSubscriptionPlansFailure = (payload: PutSubscriptionPlanRequestFailurePayload): PutSubscriptionPlanRequestFailure => ({
  type: PUT_SUBSCRIPTION_PLANS_REQUEST_FAILURE,
  payload,
})

// add plan action for the local

export const addSubscriptionPlans = (): any => ({
  type: ADD_SUBSCRIPTION_PLAN,
})

export const changeAddModeValue = (): any => ({
  type: CHANGE_ADD_MODE_VALUE,
})

export const changeTrailDay = (payload: ChangeTrailDayPayload): ChangeTrailDayRequest => ({
  type: CHANGE_TRAIL_DAYS,
  payload,
})

// get trail days

export const getTrailDaysRequestAction = (payload: GetTrailDaysRequestPayload): GetTrailDaysRequest => ({
  type: GET_TRAIL_DAYS_REQUEST,
  payload,
})
export const getTrailDaysRequestSuccess = (payload: GetTrailDaysRequestSuccessPayload): GetTrailDaysRequestSuccess => ({
  type: GET_TRAIL_DAYS_REQUEST_SUCCESS,
  payload,
})
export const getTrailDaysRequestFailure = (payload: GetTrailDaysRequestFailurePayload): GetTrailDaysRequestFailure => ({
  type: GET_TRAIL_DAYS_REQUEST_FAILURE,
  payload,
})

export const putTrailDaysRequestAction = (payload: PutTrailDaysRequestPayload): PutTrailDaysRequest => ({
  type: PUT_TRAIL_DAYS_REQUEST,
  payload,
})
export const putTrailDaysRequestSuccess = (payload: PutTrailDaysRequestSuccessPayload): PutTrailDaysRequestSuccess => ({
  type: PUT_TRAIL_DAYS_REQUEST_SUCCESS,
  payload,
})
export const putTrailDaysRequestFailure = (payload: PutTrailDaysRequestFailurePayload): PutTrailDaysRequestFailure => ({
  type: PUT_TRAIL_DAYS_REQUEST_FAILURE,
  payload,
})
