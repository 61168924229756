import { createTheme } from '@mui/material/styles'

// A custom theme for this app
// @ts-ignore
const theme = createTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },

  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: 56,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 48,
      },
      '@media (min-width:600px)': {
        minHeight: 64,
      },
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#FFFFFF',
      black50: '#333333'
    },
    primary: {
      light: '#87ead7',
      main: '#40C1AC',
      dark: '#0aa991',
      contrastText: '#fff',
      otherColor: '#9FE0D5',
    },
    secondary: {
      light: '#F8F9FF',
      main: '#4A3699',
      dark: '#2c0cae',
      lessOpacity: '#C8BEF2',
      contrastText: '#fff',
      lightShade: '#F3F0FF',
    },
    error: {
      light: '#f57e7e',
      main: '#FF4A4A',
      dark: '#f80a0a',
      contrastText: '#fff',
    },
    success: {
      light: '#91b484',
      main: '#67AB50',
      dark: '#4bbb24',
      contrastText: '#fff',
      active: '#A9C33E',
    },
    warning: {
      light: '#f8d376',
      main: '#FEC022',
      dark: '#D9A313',
      contrastText: '#fff',
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
      A800: '#616161',
      A900: '#DDDDDD',
      A1000: '#ABAAAA',
    },
    otherColor: {
      border: '#D4D6E1',
      main: '#666666',
      floatingBorder: '#9D9D9D',
      contrastText: '#fff',
      switch: '#5E72FA',
      greenSuccess: '#A9C33E',
      hrefLink: '#0000FF',
      disableColor: '#0000001f'
    },
    admin: {
      loginBg: '#043F35',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: '#000000',
      secondary: '#999999',
      disabled: 'rgba(0, 0, 0, 0.50)',
    },

    divider: 'rgba(0, 0, 0, 0.12)',
    dividerColor: '#EBEBEB',
    background: {
      paper: '#F8F9FF',
      default: '#fafafa',
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.08)',
      hoverOpacity: 0.08,
      selected: 'rgba(0, 0, 0, 0.14)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
    },
    v2: {
      labelGrey: '#848484',
      primary: {
        main: '#077178',
        primary25: '#CFEFEA'
      },
      secondary: '#483698',
      dark: {
        grey25: 'rgba(51, 51, 51, 0.25)' /*Grey with 0.25 opacity */,
        labelGrey: '#848484'
      }
    }
  },
  shadows: [
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',

    // "0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)",
    // "0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
    // "0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)",
    // "0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
    // "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)",
    // "0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)",
    // "0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)",
    // "0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)",
    // "0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)",
    // "0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)",
    // "0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)",
    // "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)",
    // "0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)",
    // "0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)",
    // "0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)",
    // "0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)",
    // "0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)",
    // "0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)",
    // "0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)",
    // "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)",
    // "0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)",
    // "0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)",
    // "0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)",
    // "0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)"
  ],
  typography: {
    fontFamily: 'Source Sans Pro',
    fontStyle: 'normal',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,

    h1: {
      fontWeight: 600,
      fontSize: '30px',
      lineHeight: '38px',
      color: '#4A3699',
    },
    h2: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '24px',
      color: '#4A3699',
    },
    h5: {
      fontWeight: 600,
      fontSize: '18px',
      lineHeight: '24px',
      color: ' #FFFFFF',
    },
    h6: {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000',
    },
    subtitle1: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
      color: '#000000',
    },
    subtitle2: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
      color: '#4A3699',
    },
    subtitle3: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#000000',
    },
    body1: {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '18px',
      primary: '#000000',
    },
    button: {
      textTransform: 'capitalize',
      borderRadius: '10px',
    },
  },

  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },

  zIndex: {
    mobileStepper: 1000,
    appBar: 99,
    drawer: 99,
    modal: 1300,
    snackbar: 1400,
    tooltip: 100,
  },
} as any) as any

export default theme
