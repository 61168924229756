import React from 'react'
import { Typography, OutlinedInput, Box } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import { makeStyles } from '@mui/styles'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import Pagination from '@mui/material/Pagination'
import theme from '../../theme'

const useStyle = makeStyles((themes: any) => ({
  paginationMain: {
    marginLeft: '0px !important',
    '& .Mui-selected': {
      backgroundColor: `${themes.palette.secondary.main} !important`,
      color: theme.palette.common.white,
    },
  },
  paginationContainer: {
    background: `${themes.palette.secondary.light} !important`,
    paddingBottom: '11px',
  },
}))

const PaginationComponent = (props: any) => {
  const classes = useStyle()
  const rowsValue = [
    {
      label: 25,
      value: 25,
    },
    {
      label: 50,
      value: 50,
    },
    {
      label: 100,
      value: 100,
    },
    {
      label: 150,
      value: 150,
    },
    {
      label: 200,
      value: 200,
    },
    {
      label: 250,
      value: 250,
    },
  ]
  return (
    <Box className={`${classes.paginationContainer} d-flex align-center justify-content-between`} p={'10px 30px'} mb={'20px'}>
      <Box />
      <Pagination
        count={Math.ceil(props?.totalCount / props?.limit)}
        shape='rounded'
        page={props?.page}
        className={classes.paginationMain}
        onChange={props?.handlePagination}
        sx={{ marginLeft: 25 }}
      />

      <Box className={`d-flex align-center`}>
        <Typography component='span' mr={'10px'}>
          Rows per page
        </Typography>
        <FormControl>
          {/* <InputLabel id='demo-multiple-name-label'>Raws</InputLabel> */}
          <Select
            sx={{ width: 70, height: 30 }}
            labelId='demo-multiple-name-label'
            id='demo-multiple-name'
            value={props?.limit}
            onChange={e => props?.handlePerPage(e)}
          // label='Rows'
          // input={<OutlinedInput label='Name' />}
          >
            {rowsValue?.map((res: any, i: number) => {
              return (
                <MenuItem value={res?.value} key={i}>
                  {res?.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export default PaginationComponent
