import { all, call, put, takeEvery } from 'redux-saga/effects'
import { successToaster } from '../../../utils/helper';
import API from '../../../utils/api'
import {
  GET_CONTACT_REQUEST, UPDATE_CONTACT_REQUEST,
} from '../../actions/actionTypes'
import {
  getContactCacheDataClearRequest,
  getContactRequestFailure,
  getContactRequestSuccess,
  updateContactRequestFailure,
  updateContactRequestSuccess,
} from '../../actions/contactUs/contactUsAction'
import { errorToaster } from '../../../utils/helper'

// get contact request list
function* getContactRequestSaga(payload: any): any {
  yield put(getContactCacheDataClearRequest())
  try {
    const newUrl = `/admin/contact`
    let url = newUrl
    const response = yield API.get(url)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(
      getContactRequestSuccess({
        getContactData: response.data?.detail,
        getContactMeta: response.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getContactRequestFailure({
        getContactError: e?.message,
      })
    )
  }
}

//update contact
function* updateContactRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`admin/contact`, payload.payload)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback)
    }

    successToaster(response.data?.meta?.message)
    yield put(
      updateContactRequestSuccess({
        contactUpdateData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      updateContactRequestFailure({
        contactUpdateError: e?.message,
      })
    )
  }
}

function* getContactSaga() {
  yield takeEvery(GET_CONTACT_REQUEST, getContactRequestSaga)
}

function* updateContactSaga() {
  yield takeEvery(UPDATE_CONTACT_REQUEST, updateContactRequestSaga)
}

export default function* contactUsSaga() {
  yield all([
    getContactSaga(),
    updateContactSaga()
  ])
}
