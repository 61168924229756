import MailOutlineOutlinedIcon from '@mui/icons-material/MailOutlineOutlined'
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined'
import { Box, Button, Grid, InputAdornment, InputLabel, TextField, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { createRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import profileBg from '../../assets/images/profile_bg.png'
import editIcon from '../../assets/images/profile_edit.png'
import FullScreenLoader from '../../components/common/FullScreenLoader'
import HelmetComponent from '../../components/common/HelmetComponent'
import RequiredStar from '../../components/common/RequiredStar'
import { sendOtpRequest } from '../../redux/actions/authActions/otpAction'
import { editProfileInfoRequest, getProfileInfoRequest } from '../../redux/actions/myProfile/profile'
import { RootState } from '../../redux/reducers/rootReducer'
import { adminData, AvoidSpace, formatPhoneNumber, getAdminData, getLocalStorageItem } from '../../utils/helper'
import { myProfileValidator } from '../../validation/auth/myProfile'
import VerifyMobilePopUP from './VerifyMobilePopup'

const useStyles = makeStyles((themes: any) => ({
  profileBg: {
    backgroundSize: '100% 100%',
    borderRadius: 10,
    backgroundImage: `url(${profileBg})`,
    padding: '29px 49px',
    justifyContent: 'start',
    [themes.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  formClass: {
    padding: '30px 30px 30px 30px',
  },
  profileForm: {
    border: `2px solid ${themes.palette.secondary.main}`,
    borderRadius: 10,
    marginTop: '-13px',
    [themes.breakpoints.down(1617)]: {
      marginTop: '-14px',
    },
  },
  datePickerTextField: {
    width: '100% !important',
  },

  label: {
    color: themes.palette.common.black,
    [themes.breakpoints.down('sm')]: {
      marginBottom: '6px !important',
    },
  },
  userprofile: {
    padding: '9px',
    border: `2px solid ${themes.palette.common.white}`,
    borderRadius: '50%',
    position: 'relative',
    width: 150,
    height: 150,
  },
  editIcon: {
    position: 'absolute',
    bottom: '-11px',
    right: 0,
    cursor: 'pointer',
  },
  profileNameInitial: {
    borderRadius: '50%',
    border: `1px solid ${themes.palette.background.paper}`,
    backgroundColor: themes.palette.grey[400],
    width: '150px',
    height: '150px',
    opacity: 1,
  },
  paper: {
    background: themes.palette.background.paper,
    minHeight: `calc(100vh - 174px)`,
  },
  nameTextField: {
    textTransform: 'uppercase',
    color: themes.palette.secondary.main,
    fontWeight: 900,
    fontSize: 47,
  },
  iconColor: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
}))

const MyProfile = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    profile_picture: '',
    email: '',
    mobile_phone: '',
  })
  const [image, setImage]: any = useState(null)
  const [error, setError]: any = useState({})
  const inputFileRef = createRef<any>()
  const form = new FormData()
  const profileData = useSelector((state: any) => state?.profile?.profileData)
  const {
    profile: { profileLoader, editProfileLoader },
  } = useSelector((state: RootState) => state) as any;

  useEffect(() => {
    dispatch(getProfileInfoRequest({ admin_id: getAdminData()?.id }))
  }, [dispatch])

  const changeHandler = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e?.target?.id || e?.target?.name]: e.target.value,
    }))
    setFormData((prevState: any) => ({
      ...prevState,
      [e.target.id]: e.target.id === 'mobile_phone' ? formatPhoneNumber(e.target.value) : e.target.value,
    }))
  }

  const updateProfilePicture = (e: any) => {
    form.append('profile_picture', e.target?.files?.[0])
    setFormData((prevState: any) => ({ ...prevState, profile_picture: form }))
    dispatch(
      editProfileInfoRequest({
        admin_id: profileData?.id,
        profile_picture: form,
        callback: (data: any) => {
          dispatch(getProfileInfoRequest({ admin_id: getAdminData()?.id }))

          const mergeData = (currentData: any, newData: any) => {
            const profileKeys = ["profile_picture"];

            const mergedData = { ...currentData };

            // Update profile keys
            for (const key of profileKeys) {
              if (newData.hasOwnProperty(key)) {
                mergedData.profile[key] = newData[key];
              }
            }

            return mergedData;
          };

          const currentAdminData = getLocalStorageItem('adminData') as any;
          const mergedData = mergeData(JSON.parse(currentAdminData), data?.detail);

          localStorage.setItem('adminData', JSON.stringify(mergedData))

          if (profileData?.mobile_number !== data?.detail?.mobile_number) {
            dispatch(
              sendOtpRequest({
                mobile: formData?.mobile_phone,
                profile_id: profileData?.id,
                callback: () => {
                  setOpen(true)
                },
              })
            )
          }
        },
        errorCallback: () => {
          dispatch(getProfileInfoRequest({ admin_id: getAdminData()?.id }))
        },
      })
    )
    const newImage = e.target?.files?.[0]
    if (newImage && (newImage?.type === 'image/jpeg' || newImage?.type === 'image/jpg' || newImage?.type === 'image/png')) {
      setImage(URL.createObjectURL(newImage))
    }
  }
  const updateProfile = (e: any) => {
    e.preventDefault()
    const { errors, isValid } = myProfileValidator(formData)
    if (isValid) {
      form.append('first_name', formData?.firstName)
      form.append('last_name', formData?.lastName)
      form.append('email', formData?.email)
      form.append('mobile_number', formData?.mobile_phone)
      dispatch(
        editProfileInfoRequest({
          admin_id: profileData?.id,
          profile_picture: form,
          callback: (data: any) => {
            dispatch(getProfileInfoRequest({ admin_id: getAdminData()?.id }))

            const mergeData = (currentData: any, newData: any) => {
              const profileKeys = ["first_name", "last_name", "mobile_number"];
              const mainKeys = ["is_mobile_verified"];

              const mergedData = { ...currentData };

              // Update profile keys
              for (const key of profileKeys) {
                if (newData.hasOwnProperty(key)) {
                  mergedData.profile[key] = newData[key];
                }
              }

              // Update main keys
              for (const key of mainKeys) {
                if (newData.hasOwnProperty(key)) {
                  mergedData[key] = newData[key];
                }
              }

              return mergedData;
            };

            const currentAdminData = getLocalStorageItem('adminData') as any;
            const mergedData = mergeData(JSON.parse(currentAdminData), data?.detail);

            localStorage.setItem('adminData', JSON.stringify(mergedData))

            if (profileData?.mobile_number !== data?.detail?.mobile_number) {
              dispatch(
                sendOtpRequest({
                  mobile: formData?.mobile_phone,
                  profile_id: profileData?.id,
                  callback: () => {
                    setOpen(true)
                  },
                })
              )
            }
          },
          errorCallback: () => {
            dispatch(getProfileInfoRequest({ admin_id: getAdminData()?.id }))
          },
        })
      )
      setError('')
    }
    setError(errors)
  }

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      firstName: profileData?.first_name || '',
      lastName: profileData?.last_name || '',
      profile_picture: profileData?.profile_picture,
      email: profileData?.email || '',
      mobile_phone: formatPhoneNumber(profileData?.mobile_number) || '',
    }))
  }, [profileData])

  return (
    <Box pt={4}>
      {(profileLoader || editProfileLoader) && <FullScreenLoader />}
      <HelmetComponent title={'Admin - Profile'} />
      <Grid className={classes.paper}>
        <form className={classes.formClass}>
          <Box className={`${classes.profileBg} d-flex`}>
            <Box position="relative">
              {formData?.profile_picture ? (
                <img src={image || formData?.profile_picture} alt={'user'} className={classes.userprofile} />
              ) : (
                <Box className={`${classes.profileNameInitial} d-flex-all-center `}>
                  <Typography variant={'subtitle1'} component={'span'} className={classes.nameTextField}>
                    {formData?.firstName?.charAt(0)}
                    {formData?.lastName?.charAt(0)}
                  </Typography>
                </Box>
              )}
              <input ref={inputFileRef} accept="image/*" hidden={true} id="profile_picture" type="file" onChange={updateProfilePicture} />
              {/* TODO: not functional */}
              <label htmlFor="profile_picture">
                <img src={editIcon} alt={'user'} className={classes.editIcon} />
              </label>
            </Box>
          </Box>
          <Box className={classes.profileForm}>
            <Grid container={true} display={'flex'} p="40px" justifyContent="space-between" sx={{ mt: { xl: 0, md: 0, sm: 0 } }}>
              <Grid item={true} lg={5.9} md={5.7} sm={5.7} xs={12}>
                <InputLabel htmlFor="firstName" sx={{ mb: 1.2, mt: 1.2 }} className={classes.label}>
                  First Name <RequiredStar />
                </InputLabel>
                <TextField
                  InputLabelProps={{ shrink: false }}
                  id="firstName"
                  InputProps={{
                    classes: {
                      root: 'cssOutlinedInput',
                    },
                  }}
                  inputProps={{
                    maxLength: 30,
                  }}
                  onKeyPress={(e: any) => AvoidSpace(e)}
                  onChange={changeHandler}
                  value={formData?.firstName}
                  error={error?.firstName}
                  helperText={error?.firstName}
                  fullWidth={true}
                />
              </Grid>
              <Grid item={true} lg={5.9} md={5.7} sm={5.7} xs={12}>
                <InputLabel htmlFor="lastName" sx={{ mb: 1.2, mt: 1.2 }} className={classes.label}>
                  Last Name <RequiredStar />
                </InputLabel>
                <TextField
                  InputLabelProps={{ shrink: false }}
                  id="lastName"
                  InputProps={{
                    classes: {
                      root: 'cssOutlinedInput',
                    },
                  }}
                  onKeyPress={(e: any) => AvoidSpace(e)}
                  onChange={changeHandler}
                  value={formData?.lastName}
                  inputProps={{
                    maxLength: 30,
                  }}
                  error={error?.lastName}
                  helperText={error?.lastName}
                  fullWidth={true}
                />
              </Grid>
              <Grid item={true} lg={5.9} md={5.7} sm={5.7} xs={12} pb="20px">
                <InputLabel htmlFor="email" sx={{ mb: 1.2, mt: 1.2 }} className={classes.label}>
                  Email
                </InputLabel>
                <TextField
                  InputLabelProps={{ shrink: false }}
                  id="email"
                  disabled={true}
                  InputProps={{
                    classes: {
                      root: 'cssOutlinedInput',
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <MailOutlineOutlinedIcon className={classes.iconColor} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={changeHandler}
                  onKeyPress={(e: any) => AvoidSpace(e)}
                  value={formData?.email}
                  fullWidth={true}
                />
              </Grid>
              <Grid item={true} lg={5.9} md={5.7} sm={5.7} xs={12} pb="20px">
                <InputLabel htmlFor="mobile_phone" sx={{ mb: 1.2, mt: 1.2 }} className={classes.label}>
                  Mobile number <RequiredStar />
                </InputLabel>
                <TextField
                  placeholder={'(XXX)-XXX-XXXX'}
                  InputLabelProps={{ shrink: false }}
                  id="mobile_phone"
                  name="mobile_phone"
                  InputProps={{
                    classes: {
                      root: 'cssOutlinedInput',
                    },
                    startAdornment: (
                      <InputAdornment position="end">
                        <span className={classes.iconColor}>+1 &nbsp;</span>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <PhoneIphoneOutlinedIcon className={classes.iconColor} />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    maxLength: 10,
                  }}
                  onChange={changeHandler}
                  onKeyPress={(e: any) => AvoidSpace(e)}
                  value={formData?.mobile_phone}
                  fullWidth={true}
                  error={error?.mobile_phone}
                  helperText={error?.mobile_phone}
                />
              </Grid>
            </Grid>
          </Box>
          <Box p="40px 0">
            <Button
              disabled={
                formData?.firstName.trim() === profileData?.first_name &&
                formData?.lastName.trim() === profileData?.last_name &&
                formData?.mobile_phone.trim() === profileData?.mobile
              }
              variant={'contained'}
              color="secondary"
              className="border-radius-10"
              sx={{
                marginRight: '22px',
              }}
              onClick={updateProfile}
            >
              Update Profile
            </Button>
          </Box>
        </form>
      </Grid>
      <VerifyMobilePopUP open={open} setOpen={setOpen} formData={formData} />
    </Box>
  )
}

export default MyProfile
