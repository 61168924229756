import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Chip, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography, } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import debounce from 'lodash.debounce';
import SearchIcon from '@mui/icons-material/Search'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap, faPlus, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'

import theme from '../../../theme';
import { AvoidSpace } from '../../../utils/helper';
import PaginationComponent from '../../../components/common/Pagination'
import AddEditTreatmentPopUp from './treatmentPopUp'
import { getTreatmentListRequestAction, updateTreatmentAction, updateTreatmentDataValidationsAction } from '../../../redux/actions/treatmentAndProcedureCodes/treatmentAndProcedureCodesAction'
import { RootState } from '../../../redux/reducers/rootReducer'
import TableSkeleton from '../../../components/skeleton/Table'

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: "10rem",
            overflow: "auto",
            boxShadow: "10px 12px 25px 0px rgb(0 0 0 / 25%)",
        },
    },
};

const countryList = [
    {
        label: "Canada",
        value: "can"
    },
    {
        label: "USA",
        value: "usa"
    },
]

export const columns = [
    {
        label: 'Sr. No.',
        minWidth: 50,
    },
    {
        label: 'Recommendation Mapping',
        minWidth: 200,
        maxWidth: 200,
    },
    {
        label: 'Country',
        minWidth: 100,
        maxWidth: 170,
    },
    {
        label: 'Def Proc Code',
        minWidth: 100,
        maxWidth: 170,
    },
    {
        label: 'Hygiene Regimen',
        minWidth: 80,
        maxWidth: 170,
    },
    {
        label: 'Additional Codes',
        minWidth: 200,
        maxWidth: 200,
    },
    {
        label: 'Procedure Codes',
        minWidth: 330,
        maxWidth: 170,
    },
    {
        label: 'Action',
        minWidth: 90,
        maxWidth: 90,
    },
]

interface columnInterface {
    label?: string
    minWidth?: number
    maxWidth?: number
}

const useStyle = makeStyles((theme: any) => ({
    searchableInput: {
        padding: '0px 30px',
        margin: '10px 0px 0px 0px !important',
    },
    scheduleAppointment: {
        backgroundColor: theme.palette.common.white,
        height: "40px",
        width: "150px",
        "& .MuiInputBase-root": {
            borderRadius: "8px",
            height: "40px",
        },
        "& fieldset": {
            borderColor: 'transparent',
        },
    },
    addButton: {
        width: '110px',
        marginLeft: '20px',
        borderColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: 'transparent',
            borderColor: theme.palette.secondary.main,
        },
    },
    table: {
        borderCollapse: 'separate',
        boxShadow: 'none',
        borderSpacing: '0px 2px!important',
        '& tr:first-child': {
            borderSpacing: '0px 10px!important',
        },
    },
    thead: {
        backgroundColor: theme.palette.primary.main,
        '& th:first-child': {
            borderRadius: '10px 0 0 10px',
        },
        '& th:last-child': {
            borderRadius: '0 10px 10px 0',
        },
    },
    th: {
        padding: '10px 16px',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '150%',
        color: theme.palette.common.white,
        borderBottom: 'none',
        overflowX: 'auto',
        whiteSpace: 'nowrap',
    },
    td: {
        borderBottom: 'none',
        padding: '10px 16px',
        height: `60px !important`,
    },
    tr: {
        borderRadius: '10px !important',
        '& td:first-child': {
            borderRadius: '10px 0 0 10px !important',
        },
        '& td:last-child': {
            borderRadius: '0 10px 10px 0 !important',
        },
    },
    bodyRowBorder: {
        borderLeft: `1px dashed ${theme.palette.grey['A900']}`,
        paddingLeft: '20px !important',
    },
    headROwBorder: {
        borderLeft: `1px dashed ${theme.palette.common.white}`,
        paddingLeft: '20px',
    },
    moduleChip: {
        margin: '0 4px 6px 4px',
        padding: '5px 0',
        color: theme.palette.common.black,
        background: theme.palette.dividerColor
    },
}))

const TreatmentsList = () => {
    const classes = useStyle()
    const dispatch = useDispatch()
    const { palette } = theme
    const ref = useRef() as any;

    const {
        treatmentAndProcedureCodes: { getTreatmentListLoader, getTreatmentListData }
    } = useSelector((state: RootState) => state) as any;

    const [country, setCountry] = useState('')
    const [skip, setSkip] = useState(0)
    const [page, setPage] = useState(1)
    const [limit, setLimit]: any = useState(100)
    const [search, setSearch] = useState('')

    const [open, setOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        dispatch(getTreatmentListRequestAction({
            page: skip,
            size: limit,
            country: country,
            search
        }))
    }, [search, limit, skip, page, country])

    const changeHandler = debounce((e: any) => {
        if (e.target.value?.length >= 1 || e.target.value?.length === 0) {
            setSearch(e?.target?.value)
            setSkip(1)
            setPage(1)
        }
    }, 600) as any

    const handleCountryChange = (e: any) => {
        setCountry(e?.target?.value)
    }

    const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
        setSkip(value)
        setPage(value)
    }

    const handlePerPage = (e: any) => {
        setLimit(e?.target?.value)
        setSkip(1)
        setPage(1)
    }

    const handleClose = () => {
        setOpen(false)
        setIsEdit(false)

        const initialVal = {
            default_code: null,
            multiple_codes: null,
            additional_codes: null,
            country: 'CAN',
            title: null,
            hygiene_tag: '',
            tx_title: null,
        } as any;
        dispatch(updateTreatmentAction(initialVal))
        dispatch(updateTreatmentDataValidationsAction({}))
    }

    const handleEditData = (rowData: any) => {
        setIsEdit(true)
        setOpen(true)
        dispatch(updateTreatmentAction(rowData))
    }

    return (
        <React.Fragment>
            <Grid container={true} className={`d-flex ${classes.searchableInput} justify-content-between`}>
                <Grid item={true} xl={4} lg={4} md={5} sm={5} xs={10}>
                    <TextField
                        onChange={changeHandler}
                        onKeyPress={(e: any) => AvoidSpace(e)}
                        style={{ whiteSpace: 'nowrap' }}
                        variant="outlined"
                        placeholder="Search..."
                        size="small"
                        type="search"
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                root: 'cssOutlinedInput',
                            },
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                <Box display={'flex'}>
                    <FormControl sx={{ borderRadius: "8px" }}>
                        <Select
                            value={country}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            onChange={(e) => handleCountryChange(e)}
                            className={`${classes.scheduleAppointment} fw-regular f-w-350 border-radius-8`}
                            sx={{
                                '&:hover fieldset': {
                                    border: 'none',
                                },
                                '&.Mui-focused fieldset': {
                                    border: 'none',
                                },
                            }}
                            MenuProps={MenuProps}
                            disabled={getTreatmentListLoader}
                            renderValue={(value) => {
                                return (
                                    <Box sx={{ display: "flex", gap: 1.5 }}>
                                        <FontAwesomeIcon icon={faMap} style={{ fontSize: '20px', color: palette.divider }} />
                                        {countryList?.find((option) => option?.value === value)?.label || 'All'}
                                    </Box>
                                );
                            }}
                        >
                            <MenuItem
                                className={`fw-regular`}
                                value={''}
                            >
                                All
                            </MenuItem>
                            {countryList && countryList?.map((res: any) =>
                                <MenuItem
                                    key={res?.value}
                                    className={`fw-regular`}
                                    value={res?.value}
                                >
                                    {res?.label}
                                </MenuItem>
                            )}

                        </Select>
                    </FormControl>

                    <Button
                        className={`${classes.addButton}`}
                        variant='outlined'
                        onClick={() => setOpen(true)}
                        startIcon={<FontAwesomeIcon icon={faPlus} style={{ fontSize: '20px', color: palette.secondary.main }} />}
                        disabled={getTreatmentListLoader}
                    >
                        Add New
                    </Button>
                </Box>
            </Grid>

            <Grid className={`d-flex-all-center text-align-center`}>
                <TableContainer component={Paper} sx={{ margin: '30px 30px 10px' }}>
                    <Table aria-label='simple table' classes={{ root: classes.table }}>
                        <TableHead classes={{ root: classes.thead }}>
                            <TableRow>
                                {columns.map((column: columnInterface, i: number) => (
                                    <TableCell
                                        key={i}
                                        classes={{ root: classes.th }}
                                        align='left'
                                        style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                                    >
                                        <Box
                                            sx={{ borderLeft: `${i === 0 && 'none !important'}`, paddingLeft: `${i === 0 && '0px !important'}` }}
                                            className={`${classes.headROwBorder}`}
                                        >
                                            {column?.label}
                                        </Box>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <Box className='w-100' sx={{ padding: '5px 0', backgroundColor: 'transparent' }}></Box>
                        <TableBody sx={{ backgroundColor: theme.palette.common.white }}>
                            {getTreatmentListLoader ?
                                <TableSkeleton column={9} /> :
                                getTreatmentListData && getTreatmentListData?.data?.map((row: any, i: number) => (
                                    <TableRow key={i} classes={{ root: classes.tr }}>
                                        <TableCell classes={{ root: classes.td }} align='center'>
                                            {(page - 1) * limit + i < 9 ? `0${(page - 1) * limit + i + 1}` : `${(page - 1) * limit + i + 1}`}
                                        </TableCell>
                                        <TableCell classes={{ root: classes.td }} align='left'>
                                            <Box className={`d-flex align-center ${classes.bodyRowBorder}`}>
                                                {row?.title || '-'}
                                            </Box>
                                        </TableCell>
                                        <TableCell classes={{ root: classes.td }} align='left'>
                                            <Box className={`d-flex align-center ${classes.bodyRowBorder}`}>
                                                {row?.country?.toLowerCase() === 'can' ? 'Canada' : "USA"}
                                            </Box>
                                        </TableCell>
                                        <TableCell classes={{ root: classes.td }} align='left'>
                                            <Box className={`d-flex align-center ${classes.bodyRowBorder}`}>
                                                {row?.default_code?.code || '-'}
                                            </Box>
                                        </TableCell>
                                        <TableCell classes={{ root: classes.td }} align='left'>
                                            <Box className={`d-flex align-center ${classes.bodyRowBorder}`} textTransform={'capitalize'}>
                                                {row?.hygiene_tag || '-'}
                                            </Box>
                                        </TableCell>
                                        <TableCell classes={{ root: classes.td }} align='left'>
                                            <Box className={`d-flex align-center ${classes.bodyRowBorder}`} flexWrap={'wrap'}>
                                                {row?.additional_codes && row?.additional_codes?.length ?
                                                    row?.additional_codes?.map((res: any, index: number) =>
                                                        <>
                                                            {res?.code &&
                                                                <Tooltip title={res?.description}>
                                                                    <Chip
                                                                        key={index}
                                                                        variant={'outlined'}
                                                                        label={res?.code}
                                                                        className={`${classes.moduleChip} border-radius-30`}
                                                                        size={'medium'}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        </>
                                                    )
                                                    : '-'
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell classes={{ root: classes.td }} align='left'>
                                            <Box className={`d-flex align-center ${classes.bodyRowBorder}`} flexWrap={'wrap'}>
                                                {row?.multiple_codes && row?.multiple_codes?.length ?
                                                    row?.multiple_codes?.map((res: any, index: number) =>
                                                        <>
                                                            {res?.code &&
                                                                <Tooltip title={res?.description}>
                                                                    <Chip
                                                                        key={index}
                                                                        variant={'outlined'}
                                                                        label={res?.code}
                                                                        className={`${classes.moduleChip} border-radius-30`}
                                                                        size={'medium'}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        </>
                                                    )
                                                    : '-'
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell classes={{ root: classes.td }} align='center'>
                                            <Box className={`d-flex align-center justify-content-between ${classes.bodyRowBorder}`}>
                                                <Tooltip title={'Edit procedure details'}>
                                                    <ModeEditOutlinedIcon
                                                        sx={{
                                                            color: theme.palette.primary.main,
                                                            marginRight: '10px',
                                                        }}
                                                        className={'cursor-pointer'}
                                                        onClick={() => { handleEditData(row) }}
                                                    />
                                                </Tooltip>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                            {!getTreatmentListLoader && (!getTreatmentListData?.data || getTreatmentListData?.data?.length < 1) && (
                                <TableRow classes={{ root: classes.tr }} className=' w-100'>
                                    <TableCell classes={{ root: classes.td }} colSpan={8} align='center' className=' w-100'>
                                        <Typography component={'span'}>No data found</Typography>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            {!getTreatmentListLoader && getTreatmentListData?.meta?.total > limit && (
                <PaginationComponent
                    totalCount={getTreatmentListData?.meta?.total}
                    skip={skip}
                    limit={limit}
                    page={page}
                    handlePagination={handlePagination}
                    handlePerPage={handlePerPage}
                />
            )}

            <AddEditTreatmentPopUp open={open} setOpen={setOpen} handleClose={handleClose} isEdit={isEdit} skip={skip}
                limit={limit} />
        </React.Fragment>
    )
}

export default TreatmentsList
