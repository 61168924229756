import { FORGOT_PASSWORD_REQUEST, FORGOT_PASSWORD_REQUEST_SUCCESS, FORGOT_PASSWORD_REQUEST_FAILURE } from '../actionTypes'

import {
  ForgotPasswordRequestPayload,
  ForgotPasswordRequestSuccessPayload,
  ForgotPasswordFailurePayload,
  ForgotPasswordRequest,
  ForgotPasswordRequestSuccess,
  ForgotPasswordRequestFailure,
} from '../../schema/auth/forgotPasswordSchema'

export const forgotPasswordRequest = (payload: ForgotPasswordRequestPayload): ForgotPasswordRequest => ({
  type: FORGOT_PASSWORD_REQUEST,
  payload,
})

export const forgotPasswordRequestSuccess = (payload: ForgotPasswordRequestSuccessPayload): ForgotPasswordRequestSuccess => ({
  type: FORGOT_PASSWORD_REQUEST_SUCCESS,
  payload,
})

export const forgotPasswordRequestFailure = (payload: ForgotPasswordFailurePayload): ForgotPasswordRequestFailure => ({
  type: FORGOT_PASSWORD_REQUEST_FAILURE,
  payload,
})
