import { combineReducers } from 'redux'
import appointmentReducer from './appointment/appointmentReducer'
import changePasswordReducer from './auth/changePasswordReducer'
import forgotPasswordReducer from './auth/forgotPasswordReducer'
import LoginReducer from './auth/loginReducer'
import otpReducer from './auth/otpReducer'
import resetPasswordReducer from './auth/resetPasswordReducer'
import clinicReducer from './clinic/clinicReducer'
import clinicReqListReducer from './clinic/clinicReqReducer'
import cmsReducer from './cms/cmsReducer'
import contactUsReducer from './contactUs/contactUsReducer'
import memberReducer from './member/memberReducer'
import profileReducer from './myProfile/profileReducer'
import notificationReducer from './notification/notificationReducer'
import ownerReducer from './owner/ownerReducer'
import patientReducer from './patient/patientReducer'
import subscriptionPlansReducer from './subscriptionPlans/subscriptionPlansReducer'
import moduleReducer from './module/moduleReducer'
import resourceReducer from './Resources/resourcesReducer'
import treatmentAndProcedureCodesReducer from './treatmentAndProcedureCodes/treatmentAndProcedureCodesReducer'

const rootReducer = combineReducers({
  Login: LoginReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  clinicReq: clinicReqListReducer,
  clinic: clinicReducer,
  cms: cmsReducer,
  member: memberReducer,
  patient: patientReducer,
  appointment: appointmentReducer,
  owner: ownerReducer,
  profile: profileReducer,
  otp: otpReducer,
  changePassword: changePasswordReducer,
  subscriptionPlans: subscriptionPlansReducer,
  contactUs: contactUsReducer,
  notification: notificationReducer,
  module: moduleReducer,
  resource: resourceReducer,
  treatmentAndProcedureCodes: treatmentAndProcedureCodesReducer
})

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
