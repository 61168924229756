import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging'
import {} from 'firebase/messaging'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_ADMIN_FMS_API_KEY,
  authDomain: process.env.REACT_APP_ADMIN_FMS_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ADMIN_FMS_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ADMIN_FMS_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ADMIN_FMS_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ADMIN_FMS_APP_ID,
  measurementId: process.env.REACT_APP_ADMIN_FMS_MEASUREMENT_ID,
}

let firebaseApp
let messaging = null
isSupported()?.then(res => {
  if (res) {
    firebaseApp = initializeApp(firebaseConfig)
    messaging = getMessaging(firebaseApp)
  }
})

export const requestForToken = async () => {
  const hasFirebaseMessagingSupport = await isSupported()
  if (hasFirebaseMessagingSupport) {
    return await getToken(messaging, {
      vapidKey: process.env.REACT_APP_ADMIN_FMC_VAPID_KEY,
    })
      .then(currentToken => {
        if (currentToken) {
          console.log('currentToken', currentToken)
          localStorage.setItem('dt_admin', currentToken)
        }
        // else {
        //   errorToaster('No registration token available. Request permission to generate one.');
        // }
      })
      .catch(() => {
        console.log('Notification Error: Please allow notification permission')
        // errorToaster('Please allow notification permission');
      })
  }
}

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload)
    })
  })
