import {
  UPDATE_RESOURCE_DATA_REQUEST,
  UPDATE_RESOURCES_VALIDATIONS,
  STORE_RESOURCES_ID,
  GET_RESOURCE_DATA_REQUEST,
  GET_RESOURCE_DATA_SUCCESS,
  GET_RESOURCE_DATA_FAIL,
  GET_RESOURCE_CACHE_DATA_CLEAR_REQUEST,
  ADD_RESOURCE_DATA_REQUEST,
  ADD_RESOURCE_DATA_SUCCESS,
  ADD_RESOURCE_DATA_FAIL,
  PUT_RESOURCE_DATA_REQUEST,
  PUT_RESOURCE_DATA_SUCCESS,
  PUT_RESOURCE_DATA_FAIL,
  DELETE_RESOURCE_DATA_REQUEST,
  DELETE_RESOURCE_DATA_SUCCESS,
  DELETE_RESOURCE_DATA_FAIL,
  REMOVE_RESOURCE_THUMBNAIL_REQUEST,
  REMOVE_RESOURCE_THUMBNAIL_SUCCESS,
  REMOVE_RESOURCE_THUMBNAIL_FAIL
} from '../../actions/actionTypes'
import { ResourceInterface, ResourcesAction } from '../../schema/Resources/resourcesSchema'

const initialState: ResourceInterface = {
  resourceDataLocal: {
    thumbnail: '',
    category: '',
    title: '',
    attachment: '',
    description: '',
    portal: 'staff'
  },
  resourceLoader: false,
  resourceData: null,
  resourceMeta: null,
  resourceError: null,

  resourceId: null,

  addResourceLoader: false,
  addResourceData: null,
  addResourceError: null,

  putResourceLoader: false,
  putResourceData: null,
  putResourceError: null,

  deleteResourceLoader: false,
  deleteResourceData: null,
  deleteResourceError: null,

  removeResourceThumbnailLoader: false,
  removeResourceThumbnailData: null,
  removeResourceThumbnailError: null,

  validationErrors: {
    resourceDataLocal: {},
  }
}

const resourceReducer = (state = initialState, action: ResourcesAction) => {
  switch (action.type) {
    case UPDATE_RESOURCE_DATA_REQUEST:
      return {
        ...state,
        resourceDataLocal: action.payload,
      }
    //update resources errors
    case UPDATE_RESOURCES_VALIDATIONS:
      return {
        ...state,
        validationErrors: action.payload
      };
    //update resources errors
    case STORE_RESOURCES_ID:
      return {
        ...state,
        resourceId: action?.payload?.resourceId
      };
    //get data from API
    case GET_RESOURCE_DATA_REQUEST:
      return {
        ...state,
        resourceLoader: true,
      }
    case GET_RESOURCE_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        resourceData: null
      }
    case GET_RESOURCE_DATA_SUCCESS:
      return {
        ...state,
        resourceLoader: false,
        resourceData: action.payload?.resourceData,
        resourceMeta: action.payload?.resourceMeta,
        resourceError: null
      }
    case GET_RESOURCE_DATA_FAIL:
      return {
        ...state,
        resourceLoader: false,
        resourceData: null,
        resourceError: action.payload
      }
    //add resource data
    case ADD_RESOURCE_DATA_REQUEST:
      return {
        ...state,
        addResourceLoader: true,
      }
    case ADD_RESOURCE_DATA_SUCCESS:
      return {
        ...state,
        addResourceLoader: false,
        addResourceData: action.payload,
        addResourceError: null
      }
    case ADD_RESOURCE_DATA_FAIL:
      return {
        ...state,
        addResourceLoader: false,
        addResourceData: null,
        addResourceError: action.payload
      }
    //put resource data
    case PUT_RESOURCE_DATA_REQUEST:
      return {
        ...state,
        addResourceLoader: true,
      }
    case PUT_RESOURCE_DATA_SUCCESS:
      return {
        ...state,
        addResourceLoader: false,
        addResourceData: action.payload,
        addResourceError: null
      }
    case PUT_RESOURCE_DATA_FAIL:
      return {
        ...state,
        addResourceLoader: false,
        addResourceData: null,
        addResourceError: action.payload
      }
    //delete resource data
    case DELETE_RESOURCE_DATA_REQUEST:
      return {
        ...state,
        deleteResourceLoader: true,
      }
    case DELETE_RESOURCE_DATA_SUCCESS:
      return {
        ...state,
        deleteResourceLoader: false,
        deleteResourceData: action.payload,
        deleteResourceError: null
      }
    case DELETE_RESOURCE_DATA_FAIL:
      return {
        ...state,
        deleteResourceLoader: false,
        deleteResourceData: null,
        deleteResourceError: action.payload
      }
    //remove resource thumbnail
    case REMOVE_RESOURCE_THUMBNAIL_REQUEST:
      return {
        ...state,
        removeResourceThumbnailLoader: true,
      }
    case REMOVE_RESOURCE_THUMBNAIL_SUCCESS:
      return {
        ...state,
        removeResourceThumbnailLoader: false,
        removeResourceThumbnailData: action.payload,
        removeResourceThumbnailError: null
      }
    case REMOVE_RESOURCE_THUMBNAIL_FAIL:
      return {
        ...state,
        removeResourceThumbnailLoader: false,
        removeResourceThumbnailData: null,
        removeResourceThumbnailError: action.payload
      }
    default:
      return {
        ...state,
      }
  }
}

export default resourceReducer
