import validator from 'validator'

export const addEditTreatmentValidator = data => {
  let errors = {}
  if (data?.title === null || validator?.isEmpty(data?.title) || data?.title?.trim() === '') errors.title = 'Please enter title.'
  if (data?.tx_title === null) errors.tx_title = 'Please select treatment title.'
  if (data?.country === null) errors.country = 'Please select country.'
  if (data?.multiple_codes === null) errors.multiple_codes = 'This field is required'
  data?.multiple_codes?.map((res, index) => {
    let error = {}
    if (res?.description == null || validator.isEmpty(res?.description) || res?.description?.trim() === '') {
      error.description = 'This field is required'
    }
    if (Object.keys(error).length >= 0 && error.hasOwnProperty('description')) {
      error.errIndex = index
      errors[`${index}`] = error
    }
  })

  return { errors, isValid: Object.keys(errors).length <= 0 }
}
