import { all, fork } from 'redux-saga/effects'
import appointmentSaga from './appointment/appointmentSaga'
import changePasswordSaga from './auth/changePasswordSaga'
import forgotPasswordSaga from './auth/forgotPasswordSaga'
import loginSaga from './auth/loginSaga'
import otpSaga from './auth/otpSaga'
import resetPasswordSaga from './auth/resetPasswordSaga'
import getClinicReq from './clinic/clinicReqSaga'
import clinicSaga from './clinic/clinicSaga'
import cmsSaga from './cms/cmsSaga'
import contactUsSaga from './contactUs/contactUsSaga'
import memberSaga from './member/memberSaga'
import ownerSaga from './owner/ownerSaga'
import patientSaga from './patient/patientSaga'
import profileSaga from './profile/profileSaga'
import subscriptionPlansSaga from './subscriptionPlans/subscriptionPlansSaga'
import moduleSaga from './module/moduleSaga'
import resourcesSaga from './Resources/resourcesSaga'
import treatmentAndProcedureCodesSaga from './treatmentAndProcedureCodes/treatmentAndProcedureCodesSaga'

export function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(forgotPasswordSaga),
    fork(resetPasswordSaga),
    fork(getClinicReq),
    fork(clinicSaga),
    fork(cmsSaga),
    fork(contactUsSaga),
    fork(memberSaga),
    fork(patientSaga),
    fork(appointmentSaga),
    fork(ownerSaga),
    fork(profileSaga),
    fork(otpSaga),
    fork(changePasswordSaga),
    fork(subscriptionPlansSaga),
    fork(moduleSaga),
    fork(resourcesSaga),
    fork(treatmentAndProcedureCodesSaga),
  ])
}
