import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { LOGIN_REQUEST } from '../../actions/actionTypes'
import { loginRequestSuccess, loginRequestFailure } from '../../actions/authActions/loginAction'
import { errorToaster, getLocalStorageItem, successToaster } from '../../../utils/helper'

function* loginRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`v2/auth/login?is_owner=${payload?.payload?.loginPayload?.isOwner}`, payload?.payload?.loginPayload)
    yield call(payload?.payload?.callback, response?.data)
    yield put(loginRequestSuccess({ loginData: response?.data }))
    if (response?.data?.status === 200) {
      successToaster('Login Successful!')
    }
  } catch (e: any) {
    errorToaster(e?.message)
    if (payload?.payload?.errorCallback()) {
      payload?.payload?.errorCallback()
    }
    yield put(loginRequestFailure({ loginError: e }))
  }
}

function* postLoginSaga() {
  yield takeEvery(LOGIN_REQUEST, loginRequestSaga)
}

export default function* loginSaga() {
  yield all([postLoginSaga()])
}
