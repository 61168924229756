import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { CHANGE_PASSWORD_REQUEST } from '../../actions/actionTypes'
import { changePasswordRequestSuccess, changePasswordRequestFailure } from '../../actions/authActions/changePasswordAction'
import { errorToaster, successToaster } from '../../../utils/helper'

function* changePasswordRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v2/auth/password`, payload?.payload)
    yield call(payload?.payload?.callback, response?.data)
    yield put(changePasswordRequestSuccess({ changePasswordData: response?.data }))
    successToaster(response?.data?.meta?.message)
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(changePasswordRequestFailure({ changePasswordError: e?.detail }))
  }
}

function* postChangePasswordSaga() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST, changePasswordRequestSaga)
}

export default function* changePasswordSaga() {
  yield all([postChangePasswordSaga()])
}
