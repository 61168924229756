import moment from 'moment'
import { toast } from 'react-toastify'

export const url = process.env.REACT_APP_API_ENDPOINT || 'https://dev-oraq-apis.35.239.156.91.nip.io/'
export const formatPhoneNumber = (phoneNumberString: any) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return cleaned
}
// Local Storage stuff start
export const getLocalStorageItem = (key: any) => localStorage.getItem(key)
export const emailRegex = /^([A-Za-z]+[A-Za-z0-9]+[.-_])*[A-Za-z0-9]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

export const adminData = JSON.parse(getLocalStorageItem('adminData') as string)
export const getAdminData = () => {
  if (getLocalStorageItem('adminToken')) {
    return JSON.parse(getLocalStorageItem('adminData') as string)?.profile
  }
  return null
}

// Local Storage stuff end
export const notAllowNumericRegex = new RegExp(/[0-9]/)

export function containsSpecialChars(str: any) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  return specialChars.test(str)
}

export const lowercaseReg = new RegExp(/(?=.*?[a-z])/)
export const uppercaseReg = new RegExp(/(?=.*?[A-Z])/)
export const numReg = new RegExp(/(?=.*?[0-9])/)
export const icRegex = /I\.C\.(?!\.)/i
export const alphabetRegex = /[a-zA-Z]/

export const notAllowSpecialCharRegex = new RegExp(/[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/)
export const canadaPostalRegex = new RegExp(/[A-Za-z]\d[A-Za-z] ?\d[A-Za-z]\d/)
export const usPostalRegex = new RegExp(/^\d{5}(?:-\d{4})?$/)

export const allowNumberOnly = (event: any) => {
  const charCode = event.target.keyCode || event?.keyCode

  if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
    event.preventDefault()
  } else {
    event.target.value = event.target.value.replace(/[^0-9. ]/g, '')
  }
}

export const handleLogout = () => {
  localStorage.removeItem('adminToken')
  localStorage.removeItem('adminData')
  window.location.href = '/login'
  // <Navigate to="/login" />
}
export const usDateFormat = 'MM/DD/YYYY' as string
export const newUsDateFormat = 'MM-DD-YYYY' as string

export const dateFormat = (dateValue: any) => {
  if (dateValue) {
    return moment(dateValue).format(usDateFormat)
  }
  return ''
}

export const resourceDateFormat = (dateValue: any) => {
  if (dateValue) {
    return moment(dateValue).format(newUsDateFormat)
  }
  return ''
}

// for convert 24 hour to 12 hour with AM/PM
export const timeFormat = (timeString?: any) => {
  if (!timeString) {
    return ' - '
  }
  const [hourString, minute] = timeString.split(':')
  const hour = +hourString % 24
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? ' AM' : ' PM')
}

export function AvoidSpace(e: any) {
  if (e.target.value.length === 0 && e.which === 32 && e.keyCode == 32) {
    e.preventDefault()
  }
}

export const errorToaster = (message: string) => {
  toast.error(message || 'Something went wrong', {
    className: 'toaster-container-error',
    icon: false,
  })
}
export const successToaster = (message: string) => {
  toast.success(message, {
    className: 'toaster-container',
    icon: false,
  })
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

export const removeRefFromJson = (data: any) => {
  return JSON.parse(JSON.stringify(data)) as any
}
