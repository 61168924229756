import {
  GET_PATIENT_REQUEST,
  GET_PATIENT_REQUEST_SUCCESS,
  GET_PATIENT_REQUEST_FAILURE,
  VIEW_PATIENT_REQUEST,
  VIEW_PATIENT_REQUEST_SUCCESS,
  VIEW_PATIENT_REQUEST_FAILURE,
  GET_PATIENT_CACHE_DATA_CLEAR_REQUEST,
  VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST,
} from '../../actions/actionTypes'
import {
  GetPatientRequest,
  GetPatientRequestSuccess,
  GetPatientRequestFailure,
  GetPatientRequestPayload,
  GetPatientRequestSuccessPayload,
  GetPatientRequestFailurePayload,
  ViewPatientRequest,
  ViewPatientRequestSuccess,
  ViewPatientRequestFailure,
  ViewPatientRequestPayload,
  ViewPatientRequestSuccessPayload,
  ViewPatientRequestFailurePayload,
} from '../../schema/patient/patientSchema'

//get patient list
export const getPatientRequest = (payload: GetPatientRequestPayload): GetPatientRequest => ({
  type: GET_PATIENT_REQUEST,
  payload,
})
export const getPatientRequestSuccess = (payload: GetPatientRequestSuccessPayload): GetPatientRequestSuccess => ({
  type: GET_PATIENT_REQUEST_SUCCESS,
  payload,
})
export const getPatientRequestFailure = (payload: GetPatientRequestFailurePayload): GetPatientRequestFailure => ({
  type: GET_PATIENT_REQUEST_FAILURE,
  payload,
})
export const getPatientCacheDataClearRequest = (): any => ({
  type: GET_PATIENT_CACHE_DATA_CLEAR_REQUEST,
})

//view Patient
export const viewPatientRequest = (payload: ViewPatientRequestPayload): ViewPatientRequest => ({
  type: VIEW_PATIENT_REQUEST,
  payload,
})
export const viewPatientRequestSuccess = (payload: ViewPatientRequestSuccessPayload): ViewPatientRequestSuccess => ({
  type: VIEW_PATIENT_REQUEST_SUCCESS,
  payload,
})
export const viewPatientRequestFailure = (payload: ViewPatientRequestFailurePayload): ViewPatientRequestFailure => ({
  type: VIEW_PATIENT_REQUEST_FAILURE,
  payload,
})
export const viewPatientCacheDataClearRequest = (): any => ({
  type: VIEW_PATIENT_CACHE_DATA_CLEAR_REQUEST,
})
