import {
  CHANGE_CONTACT_US,
  UPDATE_CONTACT_US,
  GET_CONTACT_REQUEST,
  GET_CONTACT_REQUEST_SUCCESS,
  GET_CONTACT_REQUEST_FAILURE,
  GET_CONTACT_CACHE_DATA_CLEAR_REQUEST,
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_REQUEST_SUCCESS,
  UPDATE_CONTACT_REQUEST_FAILURE
} from '../actionTypes'
import {
  UpdateRContactUsRequest,
  ChangeContactUsRequest,
  ChangeContactUsRequestPayload,
  UpdateContactUsRequestPayload,
  GetContactRequest,
  GetContactRequestSuccess,
  GetContactRequestFailure,
  getContactRequestPayload,
  getContactRequestSuccessPayload,
  getContactFailurePayload,
  ContactUpdateRequest,
  ContactUpdateRequestSuccess,
  ContactUpdateRequestFailure,
  ContactStatusUpdateRequestPayload,
  ContactUpdateRequestSuccessPayload,
  ContactUpdateFailurePayload,
} from '../../schema/contactUS/contactUsSchema'

export const updateContactUs = (payload: UpdateContactUsRequestPayload): UpdateRContactUsRequest => ({
  type: UPDATE_CONTACT_US,
  payload,
})

export const changeContactUs = (payload: ChangeContactUsRequestPayload): ChangeContactUsRequest => ({
  type: CHANGE_CONTACT_US,
  payload,
})

//get contact details
export const getContactRequest = (): any => ({
  type: GET_CONTACT_REQUEST,
})
export const getContactRequestSuccess = (payload: getContactRequestSuccessPayload): GetContactRequestSuccess => ({
  type: GET_CONTACT_REQUEST_SUCCESS,
  payload,
})
export const getContactRequestFailure = (payload: getContactFailurePayload): GetContactRequestFailure => ({
  type: GET_CONTACT_REQUEST_FAILURE,
  payload,
})
export const getContactCacheDataClearRequest = (): any => ({
  type: GET_CONTACT_CACHE_DATA_CLEAR_REQUEST,
})

//update contact details
export const updateContactRequest = (payload: ContactStatusUpdateRequestPayload): ContactUpdateRequest => ({
  type: UPDATE_CONTACT_REQUEST,
  payload,
})
export const updateContactRequestSuccess = (payload: ContactUpdateRequestSuccessPayload): ContactUpdateRequestSuccess => ({
  type: UPDATE_CONTACT_REQUEST_SUCCESS,
  payload,
})
export const updateContactRequestFailure = (payload: ContactUpdateFailurePayload): ContactUpdateRequestFailure => ({
  type: UPDATE_CONTACT_REQUEST_FAILURE,
  payload,
})
