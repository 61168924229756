import { all, put, call, takeEvery } from 'redux-saga/effects'
import {
  GET_OWNER_REQUEST,
  VIEW_OWNER_REQUEST,
  UPDATE_OWNER_STATUS_REQUEST,
  GET_CLINIC_LIST_BY_OWNER_REQUEST,
} from '../../actions/actionTypes'
import API from '../../../utils/api'
import {
  getOwnerRequestSuccess,
  getOwnerRequestFailure,
  getOwnerCacheDataClearRequest,
  viewOwnerRequestSuccess,
  viewOwnerRequestFailure,
  viewOwnerCacheDataClearRequest,
  OwnerUpdateStatusRequestSuccess,
  OwnerUpdateStatusRequestFailure,
  getClinicListByOwnerRequestSuccess,
  getClinicListByOwnerRequestFailure,
} from '../../actions/owner/ownerAction'
import { errorToaster } from '../../../utils/helper'

// Get Owner
function* getOwnerRequestSaga(payload: any): any {
  yield put(getOwnerCacheDataClearRequest())
  try {
    const params = [
      { key: 'search', value: payload?.payload?.search },
      { key: 'page', value: payload?.payload?.page },
      { key: 'size', value: payload?.payload?.size },
      { key: 'status', value: payload?.payload?.status },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `/v2/owner/list/all${tmpUrl}`
    let url = newUrl
    const response = yield API.get(url)
    yield put(getOwnerRequestSuccess({ getOwnerData: response?.data?.detail, getOwnerMeta: response?.data?.meta }))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(getOwnerRequestFailure({ getOwnerError: e?.message }))
  }
}
// view owner details
function* viewOwnerRequestSaga(payload: any): any {
  yield put(viewOwnerCacheDataClearRequest())
  try {
    const response = yield API.get(`/v2/owner/${payload?.payload?.owner_id}`)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(
      viewOwnerRequestSuccess({
        viewOwnerData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      viewOwnerRequestFailure({
        viewOwnerError: e?.message,
      })
    )
  }
}
// update owner status
function* updateOwnerStatusRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v2/owner/${payload.payload?.owner_id}/status`, payload.payload)
    yield call(payload.payload.callback, response.data)
    // if needed in future
    // toast.success(response.data?.meta?.message)
    yield put(
      OwnerUpdateStatusRequestSuccess({
        ownerStatusUpdateData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      OwnerUpdateStatusRequestFailure({
        ownerStatusUpdateError: e?.message,
      })
    )
  }
}

//get clinic list by owner
function* getClinicListByOwnerRequestSaga(payload: any): any {
  try {
    const params = [
      { key: 'search', value: payload?.payload?.search },
      { key: 'page', value: payload?.payload?.page },
      { key: 'size', value: payload?.payload?.size },
      { key: 'status', value: payload?.payload?.status },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `/v2/owner/${payload?.payload?.owner_id}/clinics${tmpUrl}`
    let url = newUrl
    const response = yield API.get(url)
    yield put(
      getClinicListByOwnerRequestSuccess({ getClinicListOwnerData: response?.data?.detail, getClinicListOwnerMeta: response?.data?.meta })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(getClinicListByOwnerRequestFailure({ getClinicListOwnerError: e?.message }))
  }
}

function* getOwnerSaga() {
  yield takeEvery(GET_OWNER_REQUEST, getOwnerRequestSaga)
}
function* viewOwnerSaga() {
  yield takeEvery(VIEW_OWNER_REQUEST, viewOwnerRequestSaga)
}
function* updateOwnerStatusSaga() {
  yield takeEvery(UPDATE_OWNER_STATUS_REQUEST, updateOwnerStatusRequestSaga)
}
function* getClinicListByOwnerSaga() {
  yield takeEvery(GET_CLINIC_LIST_BY_OWNER_REQUEST, getClinicListByOwnerRequestSaga)
}

export default function* ownerSaga() {
  yield all([getOwnerSaga(), viewOwnerSaga(), updateOwnerStatusSaga(), getClinicListByOwnerSaga()])
}
