import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import {
  GET_CLINIC_REQ_LIST_REQUEST,
  CLINIC_REQ_DETAILS_REQUEST,
  APPROVE_CLINIC_REQUEST,
  REJECT_CLINIC_REQUEST,
  GET_PMS_PRACTICE_REQUEST,
} from '../../actions/actionTypes'
import {
  clinicReqListRequestSuccess,
  clinicReqListRequestFailure,
  getClinicReqListCacheDataClearRequest,
  clinicReqDetailsRequestSuccess,
  clinicReqDetailsRequestFailure,
  getClinicReqDetailsCacheDataClearRequest,
  approveClinicRequestSuccess,
  approveClinicRequestFailure,
  rejectClinicRequestSuccess,
  rejectClinicRequestFailure,
  getPmsPracticeRequestSuccess,
  getPmsPracticeRequestFailure,
} from '../../actions/clinic/clinicReqAction'
import { errorToaster } from '../../../utils/helper'

// get clinic request list
function* getClinicReqListRequestSaga(payload: any): any {
  try {
    yield put(getClinicReqListCacheDataClearRequest())
    const params = [
      { key: 'search', value: payload.payload.search },
      { key: 'page', value: payload.payload.page },
      { key: 'size', value: payload.payload.size },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `/v2/clinic/request/all${tmpUrl}`
    const response = yield API.get(newUrl)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(
      clinicReqListRequestSuccess({
        getClinicReqData: response.data?.detail,
        getClinicReqMeta: response.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(clinicReqListRequestFailure({ getClinicReqError: e }))
  }
}

// get clinic request details
function* getClinicReqDetailsRequestSaga(payload: any): any {
  try {
    yield put(getClinicReqDetailsCacheDataClearRequest())
    const response = yield API.get(`/v2/clinic/request/details/${payload?.payload?.clinic_id}`)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data?.detail)
    }
    yield put(clinicReqDetailsRequestSuccess({ getClinicReqDetailsData: response?.data?.detail }))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(clinicReqDetailsRequestFailure({ getClinicReqDetailsError: e }))
  }
}

//approve clinic request

function* approveClinicRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v2/clinic/${payload?.payload?.data?.clinic_id}/approve`, payload.payload.data)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(approveClinicRequestSuccess({ approveClinicData: response?.data }))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(approveClinicRequestFailure({ approveClinicError: e }))
  }
}

// reject clinic request

function* rejectClinicRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`/v2/clinic/request/reject/${payload?.payload?.clinic_id}`, payload.payload)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(rejectClinicRequestSuccess({ rejectClinicData: response?.data }))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(rejectClinicRequestFailure({ rejectClinicError: e }))
  }
}

// get practice id using master id
function* getPmsPracticeRequestSaga(payload: any): any {
  try {
    const response = yield API.get(`v2/clinic/pms/practice?master_id=${payload?.payload?.master_id} `)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(getPmsPracticeRequestSuccess({ getPmsPracticeData: response?.data?.detail }))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(getPmsPracticeRequestFailure({ getPmsPracticeError: e }))
  }
}
function* getClinicReqListSaga() {
  yield takeEvery(GET_CLINIC_REQ_LIST_REQUEST, getClinicReqListRequestSaga)
}

function* getClinicReqDetailsSaga() {
  yield takeEvery(CLINIC_REQ_DETAILS_REQUEST, getClinicReqDetailsRequestSaga)
}

function* approveClinicSaga() {
  yield takeEvery(APPROVE_CLINIC_REQUEST, approveClinicRequestSaga)
}
function* rejectClinicSaga() {
  yield takeEvery(REJECT_CLINIC_REQUEST, rejectClinicRequestSaga)
}
function* getPmsPracticeSaga() {
  yield takeEvery(GET_PMS_PRACTICE_REQUEST, getPmsPracticeRequestSaga)
}

export default function* getClinicReq() {
  yield all([getClinicReqListSaga(), getClinicReqDetailsSaga(), approveClinicSaga(), rejectClinicSaga(), getPmsPracticeSaga()])
}
