import {
  GET_PROFILE_INFO_REQUEST,
  GET_PROFILE_INFO_REQUEST_SUCCESS,
  GET_PROFILE_INFO_REQUEST_FAILURE,
  GET_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
  EDIT_PROFILE_INFO_REQUEST,
  EDIT_PROFILE_INFO_REQUEST_SUCCESS,
  EDIT_PROFILE_INFO_REQUEST_FAILURE,
  EDIT_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST,
} from '../../actions/actionTypes'
import { GetProfileInfoInterface, ProfileInfoActions } from '../../schema/myProfile/profileSchema'

const initialState: GetProfileInfoInterface = {
  profileLoader: false,
  profileData: null,
  profileError: '',
  editProfileLoader: false,
  editProfileData: null,
  editProfileError: null,
}

const profileReducer = (state = initialState, action: ProfileInfoActions) => {
  switch (action.type) {
    case GET_PROFILE_INFO_REQUEST:
      return {
        ...state,
        profileLoader: true,
      }
    case GET_PROFILE_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        profileLoader: false,
        profileData: action?.payload?.profileData,
        profileError: null,
      }
    case GET_PROFILE_INFO_REQUEST_FAILURE:
      return {
        ...state,
        profileLoader: false,
        profileData: null,
        profileError: action?.payload?.profileError,
      }
    case GET_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        profileData: null,
      }
    case EDIT_PROFILE_INFO_REQUEST:
      return {
        ...state,
        editProfileLoader: true,
      }
    case EDIT_PROFILE_INFO_REQUEST_SUCCESS:
      return {
        ...state,
        editProfileLoader: false,
        editProfileData: action?.payload?.editProfileData,
        editProfileError: null,
      }
    case EDIT_PROFILE_INFO_REQUEST_FAILURE:
      return {
        ...state,
        editProfileLoader: false,
        editProfileData: null,
        editProfileError: action?.payload?.editProfileError,
      }
    case EDIT_PROFILE_INFO_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        editProfileData: null,
      }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default profileReducer
