import {
  UPDATE_RESOURCE_DATA_REQUEST,
  UPDATE_RESOURCES_VALIDATIONS,
  GET_RESOURCE_DATA_REQUEST,
  GET_RESOURCE_DATA_SUCCESS,
  GET_RESOURCE_DATA_FAIL,
  GET_RESOURCE_CACHE_DATA_CLEAR_REQUEST,
  ADD_RESOURCE_DATA_REQUEST,
  ADD_RESOURCE_DATA_SUCCESS,
  ADD_RESOURCE_DATA_FAIL,
  PUT_RESOURCE_DATA_REQUEST,
  PUT_RESOURCE_DATA_SUCCESS,
  PUT_RESOURCE_DATA_FAIL,
  DELETE_RESOURCE_DATA_REQUEST,
  DELETE_RESOURCE_DATA_SUCCESS,
  DELETE_RESOURCE_DATA_FAIL,
  REMOVE_RESOURCE_THUMBNAIL_REQUEST,
  REMOVE_RESOURCE_THUMBNAIL_SUCCESS,
  REMOVE_RESOURCE_THUMBNAIL_FAIL,
  STORE_RESOURCES_ID
} from '../actionTypes'
import {
  AddResourceTypeFailure,
  AddResourceTypeFailurePayload,
  AddResourceTypeRequest,
  AddResourceTypeRequestPayload,
  AddResourceTypeSuccess,
  AddResourceTypeSuccessPayload,
  DeleteResourceTypeFailure,
  DeleteResourceTypeFailurePayload,
  DeleteResourceTypeRequest,
  DeleteResourceTypeRequestPayload,
  DeleteResourceTypeSuccess,
  DeleteResourceTypeSuccessPayload,
  GetResourceTypeFailure,
  GetResourceTypeFailurePayload,
  GetResourceTypeRequest,
  GetResourceTypeRequestPayload,
  GetResourceTypeSuccess,
  GetResourceTypeSuccessPayload,
  PutResourceTypeFailure,
  PutResourceTypeFailurePayload,
  PutResourceTypeRequest,
  PutResourceTypeRequestPayload,
  PutResourceTypeSuccess,
  PutResourceTypeSuccessPayload,
  removeResourceThumbnailTypeFailure,
  removeResourceThumbnailTypeFailurePayload,
  removeResourceThumbnailTypeRequest,
  removeResourceThumbnailTypeRequestPayload,
  removeResourceThumbnailTypeSuccess,
  removeResourceThumbnailTypeSuccessPayload,
  StoreResourceIdRequest,
  StoreResourceRequestPayload,
  UpdateResourcesRequest,
  UpdateResourcesRequestPayload,
  UpdateResourcesValidationRequest,
  UpdateResourcesValidationRequestPayload
} from '../../schema/Resources/resourcesSchema'

export const updateResourceAction = (payload: UpdateResourcesRequestPayload): UpdateResourcesRequest => ({
  type: UPDATE_RESOURCE_DATA_REQUEST,
  payload,
})

//update errors
export const updateResourcesValidationsAction = (payload: UpdateResourcesValidationRequestPayload): UpdateResourcesValidationRequest => ({
  type: UPDATE_RESOURCES_VALIDATIONS,
  payload
});

//store resource id
export const storeResourceIdAction = (payload: StoreResourceRequestPayload): StoreResourceIdRequest => ({
  type: STORE_RESOURCES_ID,
  payload
});

// Get Resource data api actions
export const getResourceListTypeRequestAction = (payload: GetResourceTypeRequestPayload): GetResourceTypeRequest =>
({
  type: GET_RESOURCE_DATA_REQUEST,
  payload
} as any);

export const getResourceListTypeSuccessAction = (payload: GetResourceTypeSuccessPayload): GetResourceTypeSuccess => {
  return {
    type: GET_RESOURCE_DATA_SUCCESS,
    payload
  };
};

export const getResourceListTypeFailureAction = (payload: GetResourceTypeFailurePayload): GetResourceTypeFailure =>
({
  type: GET_RESOURCE_DATA_FAIL,
  payload
} as any);

export const getResourceListCacheDataClearRequest = (): any => ({
  type: GET_RESOURCE_CACHE_DATA_CLEAR_REQUEST
});

// Add Resource data api actions
export const addResourceListTypeRequestAction = (payload: AddResourceTypeRequestPayload): AddResourceTypeRequest =>
({
  type: ADD_RESOURCE_DATA_REQUEST,
  payload
} as any);

export const addResourceListTypeSuccessAction = (payload: AddResourceTypeSuccessPayload): AddResourceTypeSuccess => {
  return {
    type: ADD_RESOURCE_DATA_SUCCESS,
    payload
  };
};

export const addResourceListTypeFailureAction = (payload: AddResourceTypeFailurePayload): AddResourceTypeFailure =>
({
  type: ADD_RESOURCE_DATA_FAIL,
  payload
} as any);

// Update Resource data api actions
export const putResourceListTypeRequestAction = (payload: PutResourceTypeRequestPayload): PutResourceTypeRequest =>
({
  type: PUT_RESOURCE_DATA_REQUEST,
  payload
} as any);

export const putResourceListTypeSuccessAction = (payload: PutResourceTypeSuccessPayload): PutResourceTypeSuccess => {
  return {
    type: PUT_RESOURCE_DATA_SUCCESS,
    payload
  };
};

export const putResourceListTypeFailureAction = (payload: PutResourceTypeFailurePayload): PutResourceTypeFailure =>
({
  type: PUT_RESOURCE_DATA_FAIL,
  payload
} as any);

// Delete Resource data api actions
export const deleteResourceListTypeRequestAction = (payload: DeleteResourceTypeRequestPayload): DeleteResourceTypeRequest =>
({
  type: DELETE_RESOURCE_DATA_REQUEST,
  payload
} as any);

export const deleteResourceListTypeSuccessAction = (payload: DeleteResourceTypeSuccessPayload): DeleteResourceTypeSuccess => {
  return {
    type: DELETE_RESOURCE_DATA_SUCCESS,
    payload
  };
};

export const deleteResourceListTypeFailureAction = (payload: DeleteResourceTypeFailurePayload): DeleteResourceTypeFailure =>
({
  type: DELETE_RESOURCE_DATA_FAIL,
  payload
} as any);

// Remove Resource thumbnail api actions
export const removeResourceThumbnailTypeRequestAction = (payload: removeResourceThumbnailTypeRequestPayload): removeResourceThumbnailTypeRequest =>
({
  type: REMOVE_RESOURCE_THUMBNAIL_REQUEST,
  payload
} as any);

export const removeResourceThumbnailTypeSuccessAction = (payload: removeResourceThumbnailTypeSuccessPayload): removeResourceThumbnailTypeSuccess => {
  return {
    type: REMOVE_RESOURCE_THUMBNAIL_SUCCESS,
    payload
  };
};

export const removeResourceThumbnailTypeFailureAction = (payload: removeResourceThumbnailTypeFailurePayload): removeResourceThumbnailTypeFailure =>
({
  type: REMOVE_RESOURCE_THUMBNAIL_FAIL,
  payload
} as any);
