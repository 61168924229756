import {
  GET_OWNER_REQUEST,
  GET_OWNER_REQUEST_SUCCESS,
  GET_OWNER_REQUEST_FAILURE,
  GET_OWNER_CACHE_DATA_CLEAR_REQUEST,
  VIEW_OWNER_REQUEST,
  VIEW_OWNER_REQUEST_SUCCESS,
  VIEW_OWNER_REQUEST_FAILURE,
  VIEW_OWNER_CACHE_DATA_CLEAR_REQUEST,
  UPDATE_OWNER_STATUS_REQUEST,
  UPDATE_OWNER_STATUS_REQUEST_SUCCESS,
  UPDATE_OWNER_STATUS_REQUEST_FAILURE,
  UPDATE_OWNER_STATUS_LOCAL,
  GET_CLINIC_LIST_BY_OWNER_REQUEST,
  GET_CLINIC_LIST_BY_OWNER_REQUEST_SUCCESS,
  GET_CLINIC_LIST_BY_OWNER_REQUEST_FAILURE,
} from '../actionTypes'
import {
  GetOwnerRequest,
  GetOwnerRequestSuccess,
  GetOwnerRequestFailure,
  GetOwnerRequestPayload,
  GetOwnerRequestSuccessPayload,
  GetOwnerRequestFailurePayload,
  ViewOwnerRequest,
  ViewOwnerRequestSuccess,
  ViewOwnerRequestFailure,
  ViewOwnerRequestPayload,
  ViewOwnerRequestSuccessPayload,
  ViewOwnerFailurePayload,
  OwnerStatusUpdateRequest,
  OwnerStatusUpdateRequestSuccess,
  OwnerStatusUpdateRequestFailure,
  OwnerStatusUpdateRequestPayload,
  OwnerStatusUpdateRequestSuccessPayload,
  OwnerStatusUpdateFailurePayload,
  updateOwnerStatusLocalPayload,
  UpdateOwnerStatusLocal,
  GetClinicListByOwnerRequest,
  GetClinicListByOwnerRequestSuccess,
  GetClinicListByOwnerRequestFailure,
  GetClinicListByOwnerRequestPayload,
  GetClinicListByOwnerRequestSuccessPayload,
  GetClinicListByOwnerRequestFailurePayload,
} from '../../schema/owner/ownerSchema'

// Get Owner
export const getOwnerRequest = (payload: GetOwnerRequestPayload): GetOwnerRequest => ({
  type: GET_OWNER_REQUEST,
  payload,
})
export const getOwnerRequestSuccess = (payload: GetOwnerRequestSuccessPayload): GetOwnerRequestSuccess => ({
  type: GET_OWNER_REQUEST_SUCCESS,
  payload,
})
export const getOwnerRequestFailure = (payload: GetOwnerRequestFailurePayload): GetOwnerRequestFailure => ({
  type: GET_OWNER_REQUEST_FAILURE,
  payload,
})
export const getOwnerCacheDataClearRequest = (): any => ({
  type: GET_OWNER_CACHE_DATA_CLEAR_REQUEST,
})

//view Owner
export const viewOwnerRequest = (payload: ViewOwnerRequestPayload): ViewOwnerRequest => ({
  type: VIEW_OWNER_REQUEST,
  payload,
})
export const viewOwnerRequestSuccess = (payload: ViewOwnerRequestSuccessPayload): ViewOwnerRequestSuccess => ({
  type: VIEW_OWNER_REQUEST_SUCCESS,
  payload,
})
export const viewOwnerRequestFailure = (payload: ViewOwnerFailurePayload): ViewOwnerRequestFailure => ({
  type: VIEW_OWNER_REQUEST_FAILURE,
  payload,
})
export const viewOwnerCacheDataClearRequest = (): any => ({
  type: VIEW_OWNER_CACHE_DATA_CLEAR_REQUEST,
})

export const updateOwnerStatusLocalRequest = (payload: updateOwnerStatusLocalPayload): UpdateOwnerStatusLocal => ({
  type: UPDATE_OWNER_STATUS_LOCAL,
  payload,
})
//update Owner status
export const OwnerUpdateStatusRequest = (payload: OwnerStatusUpdateRequestPayload): OwnerStatusUpdateRequest => ({
  type: UPDATE_OWNER_STATUS_REQUEST,
  payload,
})
export const OwnerUpdateStatusRequestSuccess = (payload: OwnerStatusUpdateRequestSuccessPayload): OwnerStatusUpdateRequestSuccess => ({
  type: UPDATE_OWNER_STATUS_REQUEST_SUCCESS,
  payload,
})
export const OwnerUpdateStatusRequestFailure = (payload: OwnerStatusUpdateFailurePayload): OwnerStatusUpdateRequestFailure => ({
  type: UPDATE_OWNER_STATUS_REQUEST_FAILURE,
  payload,
})

//get clinic details list by owner

export const getClinicListByOwnerRequest = (payload: GetClinicListByOwnerRequestPayload): GetClinicListByOwnerRequest => ({
  type: GET_CLINIC_LIST_BY_OWNER_REQUEST,
  payload,
})
export const getClinicListByOwnerRequestSuccess = (
  payload: GetClinicListByOwnerRequestSuccessPayload
): GetClinicListByOwnerRequestSuccess => ({
  type: GET_CLINIC_LIST_BY_OWNER_REQUEST_SUCCESS,
  payload,
})
export const getClinicListByOwnerRequestFailure = (
  payload: GetClinicListByOwnerRequestFailurePayload
): GetClinicListByOwnerRequestFailure => ({
  type: GET_CLINIC_LIST_BY_OWNER_REQUEST_FAILURE,
  payload,
})
