import { CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_REQUEST_SUCCESS, CHANGE_PASSWORD_REQUEST_FAILURE } from '../../actions/actionTypes'
import { ChangePasswordInterface, ChangePasswordActions } from '../../schema/auth/changePasswordSchema'

const initialState: ChangePasswordInterface = {
  changePasswordLoader: false,
  changePasswordData: null,
  changePasswordError: '',
}

const changePasswordReducer = (state = initialState, action: ChangePasswordActions) => {
  switch (action.type) {
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        changePasswordLoader: true,
      }
    case CHANGE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
        changePasswordLoader: false,
        changePasswordData: action?.payload,
        changePasswordError: null,
      }
    case CHANGE_PASSWORD_REQUEST_FAILURE:
      return {
        ...state,
        changePasswordLoader: false,
        changePasswordData: null,
        changePasswordError: action?.payload?.changePasswordError,
      }
    default:
      return {
        ...state,
      }
  }
}

export default changePasswordReducer
