import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { FORGOT_PASSWORD_REQUEST } from '../../actions/actionTypes'
import { forgotPasswordRequestSuccess, forgotPasswordRequestFailure } from '../../actions/authActions/forgotPasswordAction'
import { errorToaster, successToaster } from '../../../utils/helper'

function* forgotPasswordRequestSaga(payload: any): any {
  try {
    const response = yield API.post(`/v2/auth/forgot/password`, payload.payload)
    successToaster(response.data?.meta?.message)
    yield call(payload.payload.callback, response.data)
    yield put(
      forgotPasswordRequestSuccess({
        forgotPasswordData: response.data,
      })
    )
  } catch (e: any) {
    //TODO: update toast according to status code
    errorToaster(e?.message)
    yield put(
      forgotPasswordRequestFailure({
        forgotPasswordError: e?.message,
      })
    )
  }
}

function* postForgotPasswordSaga() {
  yield takeEvery(FORGOT_PASSWORD_REQUEST, forgotPasswordRequestSaga)
}

export default function* forgotPasswordSaga() {
  yield all([postForgotPasswordSaga()])
}
