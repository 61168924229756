import {
    GET_MODULES_REQUEST,
    GET_MODULES_REQUEST_SUCCESS,
    GET_MODULES_REQUEST_FAILURE,
} from '../actionTypes'
import {
    GetModulesRequest,
    GetModulesRequestSuccess,
    GetModulesRequestFailure,
    GetModulesRequestSuccessPayload,
    GetModulesRequestFailurePayload,
} from '../../schema/modules/modulesSchema'


// get all module
export const getModulesRequest = (): GetModulesRequest => ({
    type: GET_MODULES_REQUEST
})
export const getModulesRequestSuccess = (payload: GetModulesRequestSuccessPayload): GetModulesRequestSuccess => ({
    type: GET_MODULES_REQUEST_SUCCESS,
    payload
})
export const getModulesRequestFailure = (payload: GetModulesRequestFailurePayload): GetModulesRequestFailure => ({
    type: GET_MODULES_REQUEST_FAILURE,
    payload
})


