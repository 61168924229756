import { faDownload, faMap, faPlus, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined'
import SearchIcon from '@mui/icons-material/Search'
import { Box, Button, FormControl, Grid, InputAdornment, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { makeStyles } from '@mui/styles'
import debounce from 'lodash.debounce'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FullScreenLoader from '../../../components/common/FullScreenLoader'
import PaginationComponent from '../../../components/common/Pagination'
import TableSkeleton from '../../../components/skeleton/Table'
import {
  downloadCsvFileRequestAction,
  getProcedureCodeListRequestAction,
  updateProcedureDataValidationsAction,
  updateTreatmentProcedureAction,
  uploadCsvFileRequestAction,
} from '../../../redux/actions/treatmentAndProcedureCodes/treatmentAndProcedureCodesAction'
import { RootState } from '../../../redux/reducers/rootReducer'
import theme from '../../../theme'
import { AvoidSpace } from '../../../utils/helper'
import { countryList, getYesNo, MenuProps } from '../../../utils/ProcedureTreatmentConstant/ProcedureTreatmentConstant'
import AddEditProcedurePopup from './procedureCodePopUp'

export const columns = [
  {
    label: 'Sr. No.',
    minWidth: 50,
  },
  {
    label: 'Procedure Code',
    minWidth: 150,
    maxWidth: 170,
  },
  {
    label: 'Fees',
    minWidth: 130,
    maxWidth: 170,
  },
  {
    label: 'Country',
    minWidth: 50,
  },
  {
    label: 'Type',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    label: 'Description',
    minWidth: 330,
    maxWidth: 170,
  },
  {
    label: 'Action',
    minWidth: 90,
    maxWidth: 90,
  },
]

interface columnInterface {
  label?: string
  minWidth?: number
  maxWidth?: number
}

const useStyle = makeStyles((theme: any) => ({
  searchableInput: {
    padding: '0px 30px',
    margin: '10px 0px 0px 0px !important',
  },
  scheduleAppointment: {
    backgroundColor: theme.palette.common.white,
    height: '40px',
    width: '150px',
    '& .MuiInputBase-root': {
      borderRadius: '8px',
      height: '40px',
    },
    '& fieldset': {
      borderColor: 'transparent',
    },
  },
  addButton: {
    borderColor: theme.palette.secondary.main,
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: theme.palette.secondary.main,
    },
  },
  table: {
    borderCollapse: 'separate',
    boxShadow: 'none',
    borderSpacing: '0px 2px!important',
    '& tr:first-child': {
      borderSpacing: '0px 10px!important',
    },
  },
  thead: {
    backgroundColor: theme.palette.primary.main,
    '& th:first-child': {
      borderRadius: '10px 0 0 10px',
    },
    '& th:last-child': {
      borderRadius: '0 10px 10px 0',
    },
  },
  th: {
    padding: '10px 16px',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '150%',
    color: theme.palette.common.white,
    borderBottom: 'none',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  },
  td: {
    borderBottom: 'none',
    padding: '10px 16px',
    height: `60px !important`,
  },
  tr: {
    borderRadius: '10px !important',
    '& td:first-child': {
      borderRadius: '10px 0 0 10px !important',
    },
    '& td:last-child': {
      borderRadius: '0 10px 10px 0 !important',
    },
  },
  bodyRowBorder: {
    borderLeft: `1px dashed ${theme.palette.grey['A900']}`,
    paddingLeft: '20px !important',
  },
  headROwBorder: {
    borderLeft: `1px dashed ${theme.palette.common.white}`,
    paddingLeft: '20px',
  },
}))

const ProceduresCodesList = () => {
  const classes = useStyle()
  const dispatch = useDispatch()
  const { palette } = theme
  const [country, setCountry] = useState('')
  const [skip, setSkip] = useState(0)
  const [page, setPage] = useState(1)
  const [limit, setLimit]: any = useState(100)
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const {
    treatmentAndProcedureCodes: {
      getProcedureCodeListLoader,
      getProcedureCodeListData,
      downloadCsvLoader,
      uploadCsvLoader,
      downloadCsvData,
    },
  } = useSelector((state: RootState) => state) as any

  useEffect(() => {
    dispatch(
      getProcedureCodeListRequestAction({
        page: skip,
        size: limit,
        country,
        search,
      })
    )
  }, [search, limit, skip, page, country])

  const changeHandler = debounce((e: any) => {
    if (e.target.value?.length >= 1 || e.target.value?.length === 0) {
      setSearch(e?.target?.value)
      setSkip(1)
      setPage(1)
    }
  }, 600) as any

  const handleCountryChange = (e: any) => {
    setCountry(e?.target?.value)
  }
  const handlePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setSkip(value)
    setPage(value)
  }
  const handlePerPage = (e: any) => {
    setLimit(e?.target?.value)
    setSkip(1)
    setPage(1)
  }
  const handleClose = () => {
    setOpen(false)
    setIsEdit(false)
    const initialVal = {
      country: null,
      code_type: null,
      is_additional: null,
      code: null,
      fees: null,
      description: null,
    } as any
    dispatch(updateTreatmentProcedureAction(initialVal))
    dispatch(updateProcedureDataValidationsAction({}))
  }
  const handleEditData = (rowData: any) => {
    setIsEdit(true)
    setOpen(true)
    const procedureData = {
      ...rowData,
      is_additional: getYesNo(rowData?.is_additional),
    }
    dispatch(updateTreatmentProcedureAction(procedureData))
  }

  const downloadCSV = () => {
    dispatch(downloadCsvFileRequestAction())
  }
  const form = new FormData()
  const uploadCSV = (e: any) => {
    const imageData = e.target.files[0]

    form.append('file', imageData)

    if (imageData && imageData?.type) {
      dispatch(
        uploadCsvFileRequestAction({
          file: form,
          callback: () => {
            dispatch(
              getProcedureCodeListRequestAction({
                page: skip,
                size: limit,
                country,
                search,
              })
            )
          },
        })
      )
      e.target.value = null // Clear the file input field
    }
  }

  return (
    <React.Fragment>
      {getProcedureCodeListLoader || downloadCsvLoader || (uploadCsvLoader && <FullScreenLoader />)}

      <Grid container={true} className={`d-flex ${classes.searchableInput} justify-content-between`} rowSpacing={2}>
        <Grid item={true} xl={6} lg={5} md={12} sm={12} xs={12} display={'flex'} justifyContent={'space-between'}>
          <TextField
            className="text"
            sx={{
              width: { lg: '100%', xs: '60%' },
            }}
            onChange={changeHandler}
            onKeyPress={(e: any) => AvoidSpace(e)}
            style={{ whiteSpace: 'nowrap' }}
            variant="outlined"
            placeholder="Search..."
            size="small"
            type="search"
            InputProps={{
              classes: {
                root: 'cssOutlinedInput',
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <FormControl sx={{ borderRadius: '8px', marginLeft: '30px' }}>
            <Select
              value={country}
              displayEmpty={true}
              inputProps={{ 'aria-label': 'Without label' }}
              onChange={(e) => handleCountryChange(e)}
              className={`${classes.scheduleAppointment} fw-regular f-w-350 border-radius-8`}
              sx={{
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
              }}
              disabled={getProcedureCodeListLoader}
              MenuProps={MenuProps}
              renderValue={(value) => {
                return (
                  <Box sx={{ display: 'flex', gap: 1.5 }}>
                    <FontAwesomeIcon icon={faMap} style={{ fontSize: '20px', color: palette.divider }} />
                    {countryList?.find((option: any) => option?.value === value)?.label || 'All'}
                  </Box>
                )
              }}
            >
              <MenuItem className={`fw-regular`} value={''}>
                All
              </MenuItem>
              {countryList &&
                countryList?.map((res: any) => (
                  <MenuItem key={res?.value} className={`fw-regular`} value={res?.value}>
                    {res?.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item={true} xl={6} lg={7} md={12} sm={12} xs={12} display={'flex'} justifyContent={{ lg: 'flex-end', xs: 'flex-start' }}>
          <Button
            className={`${classes.addButton}`}
            sx={{ width: '110px', cursor: getProcedureCodeListLoader ? '' : 'pointer' }}
            variant="outlined"
            onClick={() => !getProcedureCodeListLoader && setOpen(true)}
            startIcon={<FontAwesomeIcon icon={faPlus} className="f-20" />}
            disabled={getProcedureCodeListLoader}
          >
            Add New
          </Button>

          <input accept=".csv" hidden={true} id="csv" type="file" onChange={(e: any) => !getProcedureCodeListLoader && uploadCSV(e)} />

          <label htmlFor="csv" className="d-flex flex-col align-center cursor-pointer">
            <Box
              className={`${classes.addButton} d-flex align-center justify-content-between`}
              padding={'5px 15px'}
              fontSize={'14px'}
              fontWeight={600}
              borderRadius={'10px'}
              ml={'20px'}
              width={'130px'}
              height={'36.5px'}
              border={'1px solid'}
              color={getProcedureCodeListLoader ? `${palette.otherColor.disableColor} !important` : `${palette.secondary.main} !important`}
              borderColor={
                getProcedureCodeListLoader ? `${palette.otherColor.disableColor} !important` : `${palette.secondary.main} !important`
              }
              sx={{ cursor: getProcedureCodeListLoader ? '' : 'pointer' }}
            >
              <FontAwesomeIcon icon={faUpload} className="f-20" />
              Upload CSV
            </Box>
          </label>

          <Button
            className={`${classes.addButton}`}
            sx={{ width: '145px', marginLeft: '20px', cursor: getProcedureCodeListLoader || downloadCsvLoader ? '' : 'pointer' }}
            variant="outlined"
            onClick={() => (!getProcedureCodeListLoader || !downloadCsvLoader) && downloadCSV()}
            startIcon={<FontAwesomeIcon icon={faDownload} className="f-20" />}
            disabled={getProcedureCodeListLoader || downloadCsvLoader}
          >
            Download CSV
          </Button>
        </Grid>
      </Grid>

      <Grid className={`d-flex-all-center text-align-center`}>
        <TableContainer component={Paper} sx={{ margin: '30px 30px 10px' }}>
          <Table aria-label="simple table" classes={{ root: classes.table }}>
            <TableHead classes={{ root: classes.thead }}>
              <TableRow>
                {columns.map((column: columnInterface, i: number) => (
                  <TableCell
                    key={i}
                    classes={{ root: classes.th }}
                    align="left"
                    style={{ minWidth: column.minWidth, maxWidth: column.maxWidth }}
                  >
                    <Box
                      sx={{ borderLeft: `${i === 0 && 'none !important'}`, paddingLeft: `${i === 0 && '0px !important'}` }}
                      className={`${classes.headROwBorder}`}
                    >
                      {column?.label}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <Box className="w-100" sx={{ padding: '5px 0', backgroundColor: 'transparent' }} />
            <TableBody sx={{ backgroundColor: theme.palette.common.white }}>
              {getProcedureCodeListLoader ? (
                <TableSkeleton column={8} />
              ) : (
                getProcedureCodeListData?.data?.map((row: any, i: number) => (
                  <TableRow key={i} classes={{ root: classes.tr }}>
                    <TableCell classes={{ root: classes.td }} align="center">
                      {(page - 1) * limit + i < 9 ? `0${(page - 1) * limit + i + 1}` : `${(page - 1) * limit + i + 1}`}
                    </TableCell>
                    <TableCell classes={{ root: classes.td }} align="left">
                      <Box className={`d-flex align-center ${classes.bodyRowBorder}`}>{row?.code}</Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.td }} align="left">
                      <Box className={`d-flex align-center ${classes.bodyRowBorder}`}>
                        {/* $ {row?.fees?.toFixed(2)} */}$ {row?.fees}
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.td }} align="left">
                      <Box className={`d-flex align-center ${classes.bodyRowBorder}`}>
                        {row?.country?.toLowerCase() === 'can' ? 'Canada' : 'USA'}
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.td }} align="left">
                      <Box className={`d-flex align-center ${classes.bodyRowBorder}`} sx={{ textTransform: 'capitalize' }}>
                        {row.code_type}
                      </Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.td }} align="left">
                      <Box className={`d-flex align-center ${classes.bodyRowBorder}`}>{row?.description}</Box>
                    </TableCell>
                    <TableCell classes={{ root: classes.td }} align="center">
                      <Box className={`d-flex align-center justify-content-between ${classes.bodyRowBorder}`}>
                        <Tooltip title={'Edit procedure details'}>
                          <ModeEditOutlinedIcon
                            sx={{
                              color: theme.palette.primary.main,
                              marginRight: '10px',
                            }}
                            className={'cursor-pointer'}
                            onClick={() => {
                              handleEditData(row)
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))
              )}
              {!getProcedureCodeListLoader && (!getProcedureCodeListData?.data || getProcedureCodeListData?.data?.length < 1) && (
                <TableRow classes={{ root: classes.tr }} className=" w-100">
                  <TableCell classes={{ root: classes.td }} colSpan={7} align="center" className=" w-100">
                    <Typography component={'span'}>No data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      {getProcedureCodeListData?.meta?.total >= 25 && !getProcedureCodeListLoader && (
        <PaginationComponent
          totalCount={getProcedureCodeListData?.meta?.total}
          skip={skip}
          limit={limit}
          page={page}
          handlePagination={handlePagination}
          handlePerPage={handlePerPage}
        />
      )}

      <AddEditProcedurePopup
        open={open}
        handleClose={handleClose}
        isEdit={isEdit}
        setOpen={setOpen}
        skip={skip}
        limit={limit}
        search={search}
      />
    </React.Fragment>
  )
}

export default ProceduresCodesList
