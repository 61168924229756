import { all, call, put, takeEvery } from 'redux-saga/effects'
import API from '../../../utils/api'
import { errorToaster } from '../../../utils/helper'
import {
  GET_SUBSCRIPTION_PLANS_REQUEST,
  GET_TRAIL_DAYS_REQUEST,
  PUT_SUBSCRIPTION_PLANS_REQUEST,
  PUT_TRAIL_DAYS_REQUEST,
} from '../../actions/actionTypes'
import {
  getSubscriptionPlansFailure,
  getSubscriptionPlansSuccess,
  getTrailDaysRequestFailure,
  getTrailDaysRequestSuccess,
  putSubscriptionPlansFailure,
  putSubscriptionPlansSuccess,
  putTrailDaysRequestFailure,
  putTrailDaysRequestSuccess,
} from '../../actions/subscriptionPlans/subscriptionPlansAction'

function* getSubscriptionPlans(payload: any): any {
  try {
    const response = yield API.get(`admin/subscription/plan`)
    const emptyPlan = [
      {
        ID: '',
        plan_description: '<p><ul><li>Plan features</ul></li></p>',
        pms_integration: false,
        price: {
          month: null,
          year: null,
        },
        stripe_price_id: '',
        stripe_product_id: '',
        title: 'Plan Title',
      },
    ] as any
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, (response?.data?.detail || []).concat(emptyPlan))
    }
    yield put(getSubscriptionPlansSuccess((response?.data?.detail || []).concat(emptyPlan)))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getSubscriptionPlansFailure({
        getSubscriptionPlanError: e?.message,
      })
    )
  }
}

function* putSubscriptionPlans(payload: any): any {
  try {
    const response = payload.payload.addMode
      ? yield API.post(`admin/subscription/plan`, payload.payload.data)
      : yield API.put(`admin/subscription/plan/${payload.payload?.data.product_id}`, payload.payload.data)

    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data?.detail)
    }
    yield put(putSubscriptionPlansSuccess(response?.data?.detail))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      putSubscriptionPlansFailure({
        putSubscriptionPlanError: e?.message,
      })
    )
  }
}
// get trail days data

function* getTrailDays(payload: any): any {
  try {
    const response = yield API.get(`admin/subscription/common_components`)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data?.detail)
    }
    yield put(getTrailDaysRequestSuccess(response?.data?.detail))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getTrailDaysRequestFailure({
        trailDaysError: e?.message,
      })
    )
  }
}
// put trail days data

function* putTrailDays(payload: any): any {
  try {
    const response = yield API.put(`admin/subscription/common_components/${payload.payload.component_id}`, payload.payload)

    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data?.detail)
    }
    yield put(putTrailDaysRequestSuccess(response?.data?.detail))
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      putTrailDaysRequestFailure({
        trailDaysError: e?.message,
      })
    )
  }
}
function* getSubscriptionPlansSaga() {
  yield takeEvery(GET_SUBSCRIPTION_PLANS_REQUEST, getSubscriptionPlans)
}

function* putSubscriptionPlansSaga() {
  yield takeEvery(PUT_SUBSCRIPTION_PLANS_REQUEST, putSubscriptionPlans)
}
function* getTrailDaysSaga() {
  yield takeEvery(GET_TRAIL_DAYS_REQUEST, getTrailDays)
}
function* putTrailDaysSaga() {
  yield takeEvery(PUT_TRAIL_DAYS_REQUEST, putTrailDays)
}
export default function* subscriptionPlansSaga() {
  yield all([getSubscriptionPlansSaga(), putSubscriptionPlansSaga(), getTrailDaysSaga(), putTrailDaysSaga()])
}
