import { Divider, Grid, Tab, Tabs } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

import HelmetComponent from '../../components/common/HelmetComponent'
import theme from '../../theme'
import ProceduresCodesList from './procedureCodesList'
import TreatmentsList from './treatmentList'

const useStyle = makeStyles((theme: any) => ({
  container: {
    background: theme.palette.common.white,
  },
}))
const TreatmentAndProcedure = () => {
  const classes = useStyle()
  const { palette } = theme
  const ref = useRef() as any
  const [category, setCategory] = React.useState('procedure')

  const handleCategoryChange = (event: React.SyntheticEvent, newValue: string) => {
    setCategory(newValue)
  }

  return (
    <React.Fragment>
      <Grid className={`${classes.container} border-radius-10`} m="40px 30px 20px 30px" ref={ref}>
        <HelmetComponent title={'Recommendations Mapping'} />
        {/* {(resourceLoader || removeResourceThumbnailLoader) && <FullScreenLoader />} */}
        <Grid container={true} className={`d-flex border-radius-10`} columnSpacing={1.5}>
          <Grid item={true} lg={12} md={12} sm={12} xs={12}>
            <Tabs
              onChange={handleCategoryChange}
              value={category}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
              <Tab value="procedure" label="Procedure Codes" />
              <Tab value="treatment" label="Recommendations Mapping" />
            </Tabs>
          </Grid>
        </Grid>
        <Divider sx={{ borderColor: palette.dividerColor }} />
      </Grid>

      {category === 'procedure' && <ProceduresCodesList />}

      {category === 'treatment' && <TreatmentsList />}
    </React.Fragment>
  )
}

export default TreatmentAndProcedure
