import {
  GET_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_REQUEST_SUCCESS,
  GET_APPOINTMENT_REQUEST_FAILURE,
  GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
} from '../actionTypes'
import {
  GetAppointmentRequest,
  GetAppointmentRequestFailure,
  GetAppointmentRequestSuccess,
  GetAppointmentRequestPayload,
  GetAppointmentRequestSuccessPayload,
  GetAppointmentRequestFailurePayload,
} from '../../schema/appointment/appointmentScheme'

// Get appointment
export const getAppointmentRequest = (payload: GetAppointmentRequestPayload): GetAppointmentRequest => ({
  type: GET_APPOINTMENT_REQUEST,
  payload,
})
export const getAppointmentRequestSuccess = (payload: GetAppointmentRequestSuccessPayload): GetAppointmentRequestSuccess => ({
  type: GET_APPOINTMENT_REQUEST_SUCCESS,
  payload,
})
export const getAppointmentRequestFailure = (payload: GetAppointmentRequestFailurePayload): GetAppointmentRequestFailure => ({
  type: GET_APPOINTMENT_REQUEST_FAILURE,
  payload,
})
export const getAppointmentCacheDataClearRequest = (): any => ({
  type: GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
})
