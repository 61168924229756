import {
  GET_CLINIC_REQUEST,
  GET_CLINIC_REQUEST_SUCCESS,
  GET_CLINIC_REQUEST_FAILURE,
  GET_CLINIC_CACHE_DATA_CLEAR_REQUEST,
  VIEW_CLINIC_REQUEST,
  VIEW_CLINIC_REQUEST_SUCCESS,
  VIEW_CLINIC_REQUEST_FAILURE,
  VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST,
  UPDATE_CLINIC_STATUS_REQUEST,
  UPDATE_CLINIC_STATUS_REQUEST_SUCCESS,
  UPDATE_CLINIC_STATUS_REQUEST_FAILURE,
  UPDATE_CLINIC_STATUS_LOCAL,
  GET_PRACTICE_INFORMATION_REQUEST,
  GET_PRACTICE_INFORMATION_REQUEST_SUCCESS,
  GET_PRACTICE_INFORMATION_REQUEST_FAIL,
  PUT_MSA_AGREEMENT_REQUEST,
  PUT_MSA_AGREEMENT_REQUEST_SUCCESS,
  PUT_MSA_AGREEMENT_REQUEST_FAIL,
  CHANGE_MSA_AGREEMENT,
  UPDATE_MSA_AGREEMENT_VALIDATION,
  GET_MSA_AGREEMENT_REQUEST,
  GET_MSA_AGREEMENT_REQUEST_SUCCESS,
  GET_MSA_AGREEMENT_REQUEST_FAIL,
} from '../../actions/actionTypes'

import { ClinicInterface, clinicActions } from '../../schema/clinic/clinicSchema'

const initialState: ClinicInterface = {
  getClinicLoader: false,
  getClinicData: null,
  getClinicError: null,
  viewClinicLoader: false,
  viewClinicData: null,
  viewClinicError: null,
  clinicStatusUpdateLoader: false,
  clinicStatusUpdateData: null,
  clinicStatusUpdateError: null,
  getPracticeInformationLoader: false,
  getPracticeInformationData: {
    email: null,
    pm_software: null,
    imaging_software: null,
    scheduling_software: null,
    intra_oral_3d_scanner: null,
    practice_days_no: null,
    dentists_no: null,
    assistants_no: null,
    hygienists_no: null,
    npe_patients_no: null,
    recall_patient_no: null,
    exam_duration_new_patient: [],
    is_photos_taken: [],
    other_technologies_used: null,
    extra_comments: null,
    how_preintake_data_collected: [],
    how_often_md_history_updated: [],
  },
  getPracticeInformationError: null,
  putMsaAgreementLoader: false,
  putMsaAgreementData: null,
  putMsaAgreementError: null,
  msaAgreementLocal: {
    file: null,
    fileValidator: null,
    is_msa_agreed: false,
    customer_legal_name: null,
    address: null,
    customer_representative_name: null,
    phone_number: null,
    customer_representative_email: null,
    clinic_signature_name: null,
    clinic_title: null,
    postal_code: null,
    attention: null,
    admin_email: null,
    admin_signature_name: null,
    admin_title: null,
  },
  msaAgreementError: {},
  getMsaAgreementLoader: false,
  getMsaAgreementData: null,
  getMsaAgreementError: null,
}
const clinicReducer = (state = initialState, action: clinicActions) => {
  switch (action.type) {
    case GET_CLINIC_REQUEST:
      return {
        ...state,
        getClinicLoader: true,
      }
    case GET_CLINIC_REQUEST_SUCCESS:
      return {
        ...state,
        getClinicLoader: false,
        getClinicData: action.payload?.getClinicData,
        getClinicMeta: action.payload?.getClinicMeta,
        getClinicError: null,
      }

    case GET_CLINIC_REQUEST_FAILURE:
      return {
        ...state,
        getClinicLoader: false,
        getClinicData: null,
        getClinicError: action.payload.getClinicError,
      }
    case UPDATE_CLINIC_STATUS_LOCAL:
      let getClinic = state.getClinicData
      getClinic = getClinic?.map((res: any, i: number) => {
        if (i === action?.payload?.index) {
          return {
            ...res,
            status: res?.status === 'Active' ? 'Inactive' : 'Active',
          }
        }
        return res
      })
      return {
        ...state,
        getClinicData: getClinic,
      }

    case GET_CLINIC_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getClinicData: null,
      }
    case VIEW_CLINIC_REQUEST:
      return {
        ...state,
        viewClinicLoader: true,
      }
    case VIEW_CLINIC_REQUEST_SUCCESS:
      return {
        ...state,
        viewClinicLoader: false,
        viewClinicData: action.payload?.viewClinicData,
        viewClinicError: null,
      }
    case VIEW_CLINIC_REQUEST_FAILURE:
      return {
        ...state,
        viewClinicLoader: false,
        viewClinicData: null,
        viewClinicError: action.payload.viewClinicError,
      }
    case VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        viewClinicData: null,
      }
    case UPDATE_CLINIC_STATUS_REQUEST:
      return {
        ...state,
        clinicStatusUpdateLoader: true,
      }
    case UPDATE_CLINIC_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        clinicStatusUpdateLoader: false,
        clinicStatusUpdateData: action.payload,
        clinicStatusUpdateError: null,
      }
    case UPDATE_CLINIC_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        clinicStatusUpdateLoader: false,
        clinicStatusUpdateData: null,
        clinicStatusUpdateError: action.payload.clinicStatusUpdateError,
      }
    case GET_PRACTICE_INFORMATION_REQUEST:
      return {
        ...state,
        getPracticeInformationLoader: true,
        getPracticeInformationData: null,
        getPracticeInformationError: null,
      }
    case GET_PRACTICE_INFORMATION_REQUEST_SUCCESS:
      return {
        ...state,
        getPracticeInformationLoader: false,
        getPracticeInformationData: action.payload,
        getPracticeInformationError: null,
      }
    case GET_PRACTICE_INFORMATION_REQUEST_FAIL:
      return {
        ...state,
        getPracticeInformationLoader: false,
        getPracticeInformationData: null,
        getPracticeInformationError: action.payload,
      }
    case PUT_MSA_AGREEMENT_REQUEST:
      return {
        ...state,
        putMsaAgreementLoader: true,
        putMsaAgreementData: null,
        putMsaAgreementError: null,
      }
    case PUT_MSA_AGREEMENT_REQUEST_SUCCESS:
      return {
        ...state,
        putMsaAgreementLoader: false,
        putMsaAgreementData: action.payload,
        putMsaAgreementError: null,
      }
    case PUT_MSA_AGREEMENT_REQUEST_FAIL:
      return {
        ...state,
        putMsaAgreementLoader: false,
        putMsaAgreementData: null,
        putMsaAgreementError: action.payload,
      }
    case CHANGE_MSA_AGREEMENT:
      return {
        ...state,
        msaAgreementLocal: action.payload,
      }
    case UPDATE_MSA_AGREEMENT_VALIDATION:
      return {
        ...state,
        msaAgreementError: action.payload?.msaAgreementError,
      }
    case GET_MSA_AGREEMENT_REQUEST:
      return {
        ...state,
        getMsaAgreementLoader: true,
        getMsaAgreementData: null,
        getMsaAgreementError: null,
      }
    case GET_MSA_AGREEMENT_REQUEST_SUCCESS:
      return {
        ...state,
        getMsaAgreementLoader: false,
        getMsaAgreementData: action.payload,
        getMsaAgreementError: null,
      }
    case GET_MSA_AGREEMENT_REQUEST_FAIL:
      return {
        ...state,
        getMsaAgreementLoader: false,
        getMsaAgreementData: null,
        getMsaAgreementError: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
export default clinicReducer
