import {
  GET_MEMBER_REQUEST,
  GET_MEMBER_REQUEST_SUCCESS,
  GET_MEMBER_REQUEST_FAILURE,
  VIEW_MEMBER_REQUEST,
  VIEW_MEMBER_REQUEST_FAILURE,
  VIEW_MEMBER_REQUEST_SUCCESS,
  GET_MEMBER_CACHE_DATA_CLEAR_REQUEST,
  VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST,
} from '../actionTypes'

import {
  GetMemberRequestPayload,
  GetMemberRequestSuccessPayload,
  GetMemberRequestFailurePayload,
  GetMemberRequest,
  GetMemberRequestSuccess,
  GetMemberRequestFailure,
  ViewMemberRequest,
  ViewMemberRequestSuccess,
  ViewMemberRequestFailure,
  ViewMemberRequestPayload,
  ViewMemberRequestSuccessPayload,
  ViewMemberRequestFailurePayload,
} from '../../schema/member/memberSchema'

// Get member
export const getMemberRequest = (payload: GetMemberRequestPayload): GetMemberRequest => ({
  type: GET_MEMBER_REQUEST,
  payload,
})
export const getMemberRequestSuccess = (payload: GetMemberRequestSuccessPayload): GetMemberRequestSuccess => ({
  type: GET_MEMBER_REQUEST_SUCCESS,
  payload,
})
export const getMemberRequestFailure = (payload: GetMemberRequestFailurePayload): GetMemberRequestFailure => ({
  type: GET_MEMBER_REQUEST_FAILURE,
  payload,
})
export const getMemberCacheDataClearRequest = (): any => ({
  type: GET_MEMBER_CACHE_DATA_CLEAR_REQUEST,
})

// View member detail
export const viewMemberDetailRequest = (payload: ViewMemberRequestPayload): ViewMemberRequest => ({
  type: VIEW_MEMBER_REQUEST,
  payload,
})
export const viewMemberDetailRequestSuccess = (payload: ViewMemberRequestSuccessPayload): ViewMemberRequestSuccess => ({
  type: VIEW_MEMBER_REQUEST_SUCCESS,
  payload,
})
export const viewMemberDetailRequestFailure = (payload: ViewMemberRequestFailurePayload): ViewMemberRequestFailure => ({
  type: VIEW_MEMBER_REQUEST_FAILURE,
  payload,
})
export const viewMemberCacheDataClearRequest = (): any => ({
  type: VIEW_MEMBER_CACHE_DATA_CLEAR_REQUEST,
})
