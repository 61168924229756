import validator from "validator";
import { notAllowSpecialCharRegex } from "../../utils/helper";
import { notAllowSpecialChar, requireMessage, validOTP } from "../validationMassages";


export const mobileVerifyValidator = (data) => {
    let errors = {};

    if (validator.isEmpty(data.otp))
        errors.otp = requireMessage('OTP');
    if (data?.otp !== "" && notAllowSpecialCharRegex.test(data?.otp))
        errors.otp = notAllowSpecialChar("OTP");
    if (data.otp.length !== 6) errors.otp = validOTP;

    return { errors, isValid: Object.keys(errors).length <= 0 };
};