import { all, call, put, takeEvery } from 'redux-saga/effects'
import { successToaster } from './../../../utils/helper';
import API from '../../../utils/api'
import {
  GET_CMS_REQUEST, UPDATE_CMS_STATUS_REQUEST, VIEW_CMS_REQUEST,
} from '../../actions/actionTypes'
import {
  getCmsRequestSuccess,
  getCmsRequestFailure,
  getCmsCacheDataClearRequest,
  viewCmsCacheDataClearRequest,
  viewCmsRequestSuccess,
  viewCmsRequestFailure,
  cmsUpdateStatusRequestSuccess,
  cmsUpdateStatusRequestFailure,
} from '../../actions/cms/cmsAction'
import { errorToaster } from '../../../utils/helper'

// get cms request list
function* getCmsRequestSaga(payload: any): any {
  yield put(getCmsCacheDataClearRequest())
  try {
    const params = [
      { key: 'search', value: payload.payload.search },
      { key: 'page', value: payload.payload.page },
      { key: 'size', value: payload.payload.size },
      { key: 'cms_id', value: payload.payload.size },
      { key: 'country', value: payload.payload.size },
    ]
    let tmpUrl = `?`
    params?.map((p: any) => {
      if (tmpUrl.length > 1) {
        if (p.value) {
          tmpUrl = `${tmpUrl}&${p.key}=${p.value}`
        }
      } else {
        if (p.value) {
          tmpUrl = `${tmpUrl}${p.key}=${p.value}`
        }
      }
      return true
    })
    const newUrl = `v2/admin/cms/list`
    let url = newUrl
    const response = yield API.get(url)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    }
    yield put(
      getCmsRequestSuccess({
        getCmsData: response.data?.detail,
        getCmsMeta: response.data?.meta,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      getCmsRequestFailure({
        getCmsError: e?.message,
      })
    )
  }
}

//view cms
function* viewCmsRequestSaga(payload: any): any {
  yield put(viewCmsCacheDataClearRequest())
  try {
    const response = yield API.get(`v2/admin/cms?cms_id=${payload?.payload?.cms_id}&country=${payload?.payload?.country}`)
    if (payload?.payload?.callback) {
      yield call(payload?.payload?.callback, response?.data)
    } // if needed in future
    // successToaster(response.data?.meta?.message)
    yield put(
      viewCmsRequestSuccess({
        viewCmsData: response?.data?.detail,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      viewCmsRequestFailure({
        viewCmsError: e?.message,
      })
    )
  }
}

//update cms status
function* updateCmsStatusRequestSaga(payload: any): any {
  try {
    const response = yield API.put(`v2/admin/cms/${payload?.payload?.cms_id}`, payload.payload)
    if (payload?.payload?.callback) {
      successToaster(response.data?.meta?.message)
      yield call(payload?.payload?.callback)
    }
    yield put(
      cmsUpdateStatusRequestSuccess({
        cmsStatusUpdateData: response.data,
      })
    )
  } catch (e: any) {
    errorToaster(e?.message)
    yield put(
      cmsUpdateStatusRequestFailure({
        cmsStatusUpdateError: e?.message,
      })
    )
  }
}

function* getCmsSaga() {
  yield takeEvery(GET_CMS_REQUEST, getCmsRequestSaga)
}

function* viewCmsSaga() {
  yield takeEvery(VIEW_CMS_REQUEST, viewCmsRequestSaga)
}

function* updateCmsStatusSaga() {
  yield takeEvery(UPDATE_CMS_STATUS_REQUEST, updateCmsStatusRequestSaga)
}

export default function* cmsSaga() {
  yield all([
    getCmsSaga(),
    viewCmsSaga(),
    updateCmsStatusSaga()
  ])
}
