import React, { useEffect } from 'react'
import { makeStyles } from '@mui/styles'
import { Box, Divider, FormControl, FormControlLabel, FormLabel, Grid, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import ReactSelect from 'react-select';

import QIcon from "../../../assets/images/QIcon.png"
import theme from '../../../theme'
import { RootState } from '../../../redux/reducers/rootReducer'
import { addEditTreatmentRequestAction, getAllProcedureDataRequestAction, getTreatmentListRequestAction, updateTreatmentAction, updateTreatmentDataValidationsAction } from '../../../redux/actions/treatmentAndProcedureCodes/treatmentAndProcedureCodesAction'
import { MenuProps, countryList, dropdownTypeList, treatmentTitle } from '../../../utils/ProcedureTreatmentConstant/ProcedureTreatmentConstant'
import { addEditTreatmentValidator } from '../../../validation/treatmentAndProcedureCodes/addEditTreatmentValidator'

const customStyles = (val?: string) => {
    return {
        control: (provided: Record<string, unknown>) => ({
            ...provided,
            boxShadow: 'none !important',
            borderRadius: '4px',
            maxHeight: '40px',
            height: '40px',
            minHeight: '40px',
            alignItems: 'center',
            alignContent: 'center',
            marginTop: '8px',
            '&:hover': {
                border: `1px solid ${theme.palette.secondary.main} !important`,
                boxShadow: 'none !important',
            },
            '&:focus': {
                border: `1px solid ${theme.palette.secondary.main} !important`,
                boxShadow: 'none !important',
            },
            '&:active': {
                border: `1px solid ${theme.palette.secondary.main} !important`,
                boxShadow: 'none !important',
            },
        }),
        menu: (provided: any) => ({
            ...provided,
            borderRadius: '10px',
            boxShadow: 'none',
            zIndex: 2024,
            height: '200px'

        }),
        menuList: (provided: any) => ({
            ...provided,
            maxHeight: '200px',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused && '#CFEFEA',
            color: state.isFocused && `${theme.palette.common.black}!important`,
            '&:hover': {
                backgroundColor: state.isFocused && '#CFEFEA',
            },
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            flexWrap: "nowrap !important",
            maxWidth: "100%",
            overflow: "scroll",
            fontFamily: "FavoritPro-Regular !important",
            color: theme.palette.common.black50,
            "::-webkit-scrollbar": {
                display: " none",
            },
        }),
        multiValue: (base: any) => ({
            ...base,
            minWidth: "auto !important",
            fontFamily: "FavoritPro-Regular !important",
            color: theme.palette.common.black50,
        }),
        menuPortal: (provided: any) => ({ ...provided, zIndex: 2025 }),
    }
}

const useStyles = makeStyles((theme: any) => ({
    cancelButton: {
        background: theme.palette.common.white,
        color: theme.palette.secondary.main,
        borderColor: theme.palette.secondary.main,
        height: '40px',
        boxShadow: 'none !important',
        '&:hover': {
            background: theme.palette.common.white,
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
        },
    },
    confirmButton: {
        background: theme.palette.secondary.main,
        color: theme.palette.common.white,
        height: '40px',
        boxShadow: 'none !important',
        '&:hover': {
            background: theme.palette.secondary.main,
            color: theme.palette.common.white,
        },
    },
    dialogTitle: {
        padding: '0 0 0 8px',
        fontSize: '16px'
    },
    label: {
        marginBottom: '8px',
        color: theme.palette.common.black50
    },
    textField: {
        '& .MuiInputBase-input': {
            fontFamily: "FavoritPro-Regular"
        },
        "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none",
        },
    },
    radioButton: {
        display: 'flex',
        flexDirection: 'row',
        '& .Mui-checked': {
            color: theme.palette.secondary.main
        }
    },
    dropdownClass: {
        height: "40px",
        textTransform: "capitalize",
    },
    DialogActionsClass: {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
    }
}))

type Props = {
    open: boolean
    setOpen: any
    handleClose: any
    isEdit: boolean,
    skip: number
    limit: number
}

const AddEditTreatmentPopUp = (props: Props) => {
    const { open, setOpen, handleClose, isEdit, skip, limit } = props as Props
    const classes = useStyles()
    const palette = theme.palette
    const dispatch = useDispatch()

    const {
        treatmentAndProcedureCodes: { treatmentDataLocal, treatmentDataError }
    } = useSelector((state: RootState) => state) as any;

    const { getAllProcedureData } = useSelector((state: RootState) => state.treatmentAndProcedureCodes) as any;

    useEffect(() => {
        if (treatmentDataLocal?.country !== null) {
            const isAdditionalNewData = {
                country: treatmentDataLocal?.country,
                is_additional: true
            }
            dispatch(getAllProcedureDataRequestAction(isAdditionalNewData))

            const newData = {
                country: treatmentDataLocal?.country,
                is_additional: false
            }
            dispatch(getAllProcedureDataRequestAction(newData))
        }
    }, [treatmentDataLocal?.country])

    const handleChange = (e: any) => {
        const { value, name } = e?.target as any

        let updatedTreatmentData = treatmentDataLocal

        if (name === 'default_code') {
            const filterData = getAllProcedureData?.allProcedureData?.filter((filterData: any) => filterData?.code === value)
            dispatch(updateTreatmentAction({
                ...updatedTreatmentData,
                [name]: filterData[0]
            }))
        } else {
            dispatch(updateTreatmentAction({
                ...updatedTreatmentData,
                [name]: value
            }))
        }

        // for the update error
        const treatmentDataValid = addEditTreatmentValidator(updatedTreatmentData) as any;

        if (treatmentDataError?.[name]) {
            dispatch(
                updateTreatmentDataValidationsAction({
                    ...treatmentDataError,
                    [name]: treatmentDataValid[name],
                })
            );
        }
    }

    const handleChangeMultiple = (e: any, index: number) => {
        const { value, name } = e?.target as any

        const updatedMultipleCodes = treatmentDataLocal?.multiple_codes?.map((codeData: any, i: number) => {
            if (index === i) {
                return {
                    ...codeData,
                    description: value
                };
            }
            return codeData;
        });

        const updatedTreatmentData = {
            ...treatmentDataLocal,
            multiple_codes: updatedMultipleCodes
        };

        const custom_title = {} as any;
        updatedMultipleCodes?.forEach((item: any) => {
            custom_title[item.code] = item.description;
        });

        const addCustomTitle = {
            ...updatedTreatmentData,
            custom_title
        };

        dispatch(updateTreatmentAction(addCustomTitle));

        // for the update error
        const treatmentDataValid = addEditTreatmentValidator(updatedTreatmentData) as any;

        if (treatmentDataError?.[index]) {
            dispatch(
                updateTreatmentDataValidationsAction({
                    ...treatmentDataError,
                    [`${index}`]: treatmentDataValid?.errors[`${index}`]?.[name] ? treatmentDataValid?.errors[`${index}`]?.[name] : ''
                })
            );
        }
    }

    const ModifyForSelectArrayMultiple = (data: any) => {
        return data?.map((res: any) => {
            return {
                description: `${res?.code}` || null,
                code: `${res?.code}` || null,
            };
        });
    };

    const handleMultipleSelectChange = (selectedOption: any) => {
        const filterData = getAllProcedureData?.allProcedureData?.filter((filterData: any) =>
            (selectedOption?.map((selectData: any) => selectData?.code) || [])?.includes(filterData?.code))

        if (selectedOption !== null) {
            dispatch(updateTreatmentAction({
                ...treatmentDataLocal,
                multiple_codes: filterData
            }));
        }
        const treatmentDataValid = addEditTreatmentValidator(treatmentDataLocal) as any;

        if (treatmentDataError?.['multiple_codes']) {
            dispatch(
                updateTreatmentDataValidationsAction({
                    ...treatmentDataError,
                    'multiple_codes': treatmentDataValid['multiple_codes'],
                })
            );
        }
    };

    const handleAdditionalSelectChange = (selectedOption: any) => {
        const filterData = getAllProcedureData?.getAllAdditionalProcedureData?.filter((filterData: any) =>
            (selectedOption?.map((selectData: any) => selectData?.code) || [])?.includes(filterData?.code))

        if (selectedOption !== null) {
            dispatch(updateTreatmentAction({
                ...treatmentDataLocal,
                additional_codes: filterData
            }));
        }
    };

    const handleConfirm = () => {
        let { errors, isValid } = addEditTreatmentValidator(treatmentDataLocal)
        dispatch(updateTreatmentDataValidationsAction(errors))

        if (isValid) {
            dispatch(addEditTreatmentRequestAction({
                treatmentDataLocal: treatmentDataLocal,
                callback: () => {
                    dispatch(getTreatmentListRequestAction({
                        page: skip,
                        size: limit,
                    }))
                }
            }))
            setOpen(false)

            const initialVal = {
                default_code: null,
                multiple_codes: null,
                additional_codes: null,
                country: 'CAN',
                title: null,
                hygiene_tag: '',
                tx_title: null,
            } as any;
            dispatch(updateTreatmentDataValidationsAction({}))
            dispatch(updateTreatmentAction(initialVal))
        }
    }

    return (
        <div>
            <Dialog
                sx={{
                    '& .MuiDialogContent-root': {
                        padding: '0 16px 20px 16px !important',
                    },
                    '& .MuiPaper-root ': {
                        width: '471px !important',
                        background: theme.palette.common.white,
                        boxShadow: ' 0px 10px 20px rgba(0, 0, 0, 0.07)',
                        borderRadius: '8px !important',
                        display: 'flex !important',
                        alignItems: 'center !important',
                        justifyContent: 'center !important',
                    },
                }}
                open={open}
                onClose={() => handleClose()}
            >

                <DialogActions className={classes.DialogActionsClass}>
                    <Box className={'d-flex align-center w-100'} padding={'16px'}>
                        <img src={QIcon} alt='QIcon' height={'20px'} width={'20px'} />
                        <DialogTitle className={`${classes.dialogTitle} fw-medium`} id="alert-dialog-title">
                            {isEdit ? "Edit Recommendation Mapping" : "Recommendation Mapping"}
                        </DialogTitle>
                    </Box>
                    <Divider sx={{ width: '100%', color: palette.text.secondary }} />
                </DialogActions>

                <DialogContent sx={{ width: '100%' }} >
                    <Grid container={true} spacing={2} mt={'1px'} maxHeight={'500px'}>
                        <Grid item={true} xs={12}>
                            <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                                Recommendation Title
                            </FormLabel>
                            <TextField
                                fullWidth
                                color="secondary"
                                name="title"
                                onChange={(e) => handleChange(e)}
                                value={treatmentDataLocal?.title}
                                placeholder='Enter title...'
                                sx={{
                                    marginTop: '8px',
                                    '& .Mui-focused': {
                                        borderColor: `${palette.secondary.main} !important`
                                    }
                                }}>
                            </TextField>
                            {treatmentDataError?.title
                                && <Typography color={theme.palette.error.main} className='f-12 lh-14'>
                                    {treatmentDataError?.title}
                                </Typography>
                            }
                        </Grid>

                        <Grid item={true} xs={12} sx={{ paddingTop: '8px !important' }}>
                            <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                                Country
                            </FormLabel>
                            <FormControl fullWidth sx={{ borderRadius: "8px", marginTop: '8px' }}>
                                <Select
                                    name="country"
                                    value={treatmentDataLocal?.country}
                                    displayEmpty
                                    inputProps={{ "aria-label": "Without label" }}
                                    onChange={(e) => handleChange(e)}
                                    className={`${classes.dropdownClass} fw-regular f-w-350 border-radius-8`}
                                    sx={{
                                        '&:hover fieldset': {
                                            borderColor: palette.secondary.main
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: `${palette.secondary.main} !important`
                                        },
                                    }}
                                    MenuProps={MenuProps}
                                    disabled={isEdit}
                                >
                                    {countryList && countryList?.map((res: any) =>
                                        <MenuItem
                                            key={res?.value}
                                            className={`fw-regular`}
                                            value={res?.value}
                                        >
                                            {res?.label}
                                        </MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                            {treatmentDataError?.country
                                && <Typography color={theme.palette.error.main} className='f-12 lh-14'>
                                    {treatmentDataError?.country}
                                </Typography>
                            }
                        </Grid>

                        <Grid item={true} xs={12} sx={{ paddingTop: '8px !important' }}>
                            <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                                Hygiene Tag
                            </FormLabel>
                            <FormControl fullWidth sx={{ borderRadius: "8px", marginTop: '8px' }}>
                                <Select
                                    value={treatmentDataLocal?.hygiene_tag}
                                    displayEmpty
                                    name='hygiene_tag'
                                    inputProps={{ "aria-label": "Without label" }}
                                    onChange={(e) => handleChange(e)}
                                    className={`${classes.dropdownClass} fw-regular f-w-350 border-radius-8`}
                                    sx={{
                                        '&:hover fieldset': {
                                            borderColor: palette.secondary.main
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: `${palette.secondary.main} !important`
                                        },
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        className={`fw-regular`}
                                        value={''}
                                    >
                                        Select
                                    </MenuItem>
                                    {dropdownTypeList && dropdownTypeList?.map((res: any) =>
                                        <MenuItem
                                            key={res?.value}
                                            className={`fw-regular`}
                                            value={res?.value}
                                        >
                                            {res?.label}
                                        </MenuItem>
                                    )}

                                </Select>
                            </FormControl>
                        </Grid>

                        {treatmentDataLocal?.country !== '' &&
                            <Grid item={true} xs={12}>
                                <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                                    All Procedure Codes
                                </FormLabel>

                                <ReactSelect
                                    value={ModifyForSelectArrayMultiple(treatmentDataLocal?.multiple_codes)}
                                    onChange={handleMultipleSelectChange}
                                    getOptionLabel={(option: any) => option?.description}
                                    getOptionValue={(option: any) => option?.code}
                                    options={getAllProcedureData?.allProcedureData}
                                    isClearable={true}
                                    isSearchable={true}
                                    isMulti={true}
                                    styles={customStyles()}
                                    className='reactSelectInput'

                                />
                                {treatmentDataError?.multiple_codes
                                    && <Typography color={theme.palette.error.main} className='f-12 lh-14'>
                                        {treatmentDataError?.multiple_codes}
                                    </Typography>
                                }
                            </Grid>
                        }

                        <Grid item={true} xs={12} sx={{ paddingTop: '8px !important' }}>
                            <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                                Default Procedure Code
                            </FormLabel>
                            <FormControl fullWidth sx={{ borderRadius: "8px", marginTop: '8px' }}>
                                <Select
                                    value={treatmentDataLocal?.default_code?.code}
                                    displayEmpty
                                    name='default_code'
                                    inputProps={{ "aria-label": "Without label" }}
                                    onChange={(e) => handleChange(e)}
                                    className={`${classes.dropdownClass} fw-regular f-w-350 border-radius-8`}
                                    sx={{
                                        '&:hover fieldset': {
                                            borderColor: palette.secondary.main
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: `${palette.secondary.main} !important`
                                        },
                                    }}
                                    renderValue={(select: any) => { return select ? select : 'Select' }}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem
                                        className={`fw-regular`}
                                        value={''}
                                    >
                                        Select
                                    </MenuItem>
                                    {treatmentDataLocal && treatmentDataLocal?.multiple_codes?.map((res: any) =>
                                        <MenuItem
                                            key={res?.code}
                                            className={`fw-regular`}
                                            value={res?.code}
                                        >
                                            {res?.description}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item={true} xs={12}>
                            <FormControl>
                                <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                                    Treatment Title
                                </FormLabel>
                                <RadioGroup
                                    name="tx_title"
                                    className={`${classes.radioButton} fw-regular`}
                                    value={treatmentDataLocal?.tx_title}
                                    onChange={(e) => handleChange(e)}
                                >
                                    {treatmentTitle?.map((res: any) =>
                                        <FormControlLabel key={res?.value} value={res?.value} control={<Radio />} label={res?.label} />
                                    )}
                                </RadioGroup>
                            </FormControl>
                            {treatmentDataError?.tx_title
                                && <Typography color={theme.palette.error.main} className='f-12 lh-14'>
                                    {treatmentDataError?.tx_title}
                                </Typography>
                            }
                        </Grid>

                        <Grid item={true} xs={12}>
                            <Divider />
                        </Grid>

                        {treatmentDataLocal?.tx_title === 'other' &&
                            treatmentDataLocal && treatmentDataLocal?.multiple_codes?.map((res: any, index: number) =>
                                <>
                                    <Grid item={true} xs={4}>
                                        <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                                            Procedure Code
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            color="secondary"
                                            name='code'
                                            value={res?.code}
                                            disabled
                                            sx={{
                                                marginTop: '8px',
                                                '& .Mui-focused': {
                                                    borderColor: `${palette.secondary.main} !important`
                                                }
                                            }}
                                        >
                                        </TextField>
                                    </Grid>

                                    <Grid item={true} xs={8}>
                                        <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                                            Description
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            color="secondary"
                                            name='description'
                                            onChange={(e) => handleChangeMultiple(e, index)}
                                            value={res?.description}
                                            sx={{
                                                marginTop: '8px',
                                                '& .Mui-focused': {
                                                    borderColor: `${palette.secondary.main} !important`
                                                }
                                            }}
                                            error={treatmentDataError[index]?.description}
                                            helperText={treatmentDataError[index]?.description}
                                        >
                                        </TextField>
                                    </Grid>
                                </>
                            )
                        }

                        <Grid item={true} xs={12}>
                            <FormLabel className={`fw-regular`} sx={{ color: `${palette.common.black} !important` }}>
                                Additional Code
                            </FormLabel>

                            <ReactSelect
                                value={ModifyForSelectArrayMultiple(treatmentDataLocal?.additional_codes)}
                                onChange={handleAdditionalSelectChange}
                                getOptionLabel={(option: any) => option?.description}
                                getOptionValue={(option: any) => option?.code}
                                options={getAllProcedureData?.getAllAdditionalProcedureData}
                                isClearable={true}
                                isSearchable={true}
                                isMulti={true}
                                styles={customStyles()}
                                className='reactSelectInput'
                                menuPortalTarget={document.body}
                            />
                        </Grid>
                    </Grid>

                </DialogContent>

                <DialogActions sx={{ padding: '16px 21px', display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <Divider sx={{ width: '100%', color: palette.text.secondary }} />

                    <Box className={`d-flex align-center justify-content-between w-100`} mt={1.5}>
                        <Button
                            onClick={() => handleClose()}
                            className={`${classes.cancelButton} border-radius-10 text-decoration-none`}
                            variant='outlined'
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={() => handleConfirm()}
                            className={`${classes.confirmButton} border-radius-10 text-decoration-none`}>
                            {isEdit ? "Save" : "Add"}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default AddEditTreatmentPopUp
