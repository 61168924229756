import { RESET_PASSWORD_REQUEST, RESET_PASSWORD_REQUEST_SUCCESS, RESET_PASSWORD_REQUEST_FAILURE } from '../actionTypes'

import {
  ResetPasswordRequestPayload,
  ResetPasswordRequestSuccessPayload,
  ResetPasswordRequestFailurePayload,
  ResetPasswordRequest,
  ResetPasswordRequestSuccess,
  ResetPasswordRequestFailure,
} from '../../schema/auth/resetPasswordSchema'

export const resetPasswordRequest = (payload: ResetPasswordRequestPayload): ResetPasswordRequest => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
})

export const resetPasswordRequestSuccess = (payload: ResetPasswordRequestSuccessPayload): ResetPasswordRequestSuccess => ({
  type: RESET_PASSWORD_REQUEST_SUCCESS,
  payload,
})

export const resetPasswordRequestFailure = (payload: ResetPasswordRequestFailurePayload): ResetPasswordRequestFailure => ({
  type: RESET_PASSWORD_REQUEST_FAILURE,
  payload,
})
