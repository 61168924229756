import {
  GET_CLINIC_REQUEST,
  GET_CLINIC_REQUEST_SUCCESS,
  GET_CLINIC_REQUEST_FAILURE,
  GET_CLINIC_CACHE_DATA_CLEAR_REQUEST,
  VIEW_CLINIC_REQUEST,
  VIEW_CLINIC_REQUEST_SUCCESS,
  VIEW_CLINIC_REQUEST_FAILURE,
  VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST,
  UPDATE_CLINIC_STATUS_LOCAL,
  UPDATE_CLINIC_STATUS_REQUEST,
  UPDATE_CLINIC_STATUS_REQUEST_SUCCESS,
  UPDATE_CLINIC_STATUS_REQUEST_FAILURE,
  GET_PRACTICE_INFORMATION_REQUEST,
  GET_PRACTICE_INFORMATION_REQUEST_SUCCESS,
  GET_PRACTICE_INFORMATION_REQUEST_FAIL,
  PUT_MSA_AGREEMENT_REQUEST,
  PUT_MSA_AGREEMENT_REQUEST_SUCCESS,
  PUT_MSA_AGREEMENT_REQUEST_FAIL,
  CHANGE_MSA_AGREEMENT,
  UPDATE_MSA_AGREEMENT_VALIDATION,
  GET_MSA_AGREEMENT_REQUEST,
  GET_MSA_AGREEMENT_REQUEST_SUCCESS,
  GET_MSA_AGREEMENT_REQUEST_FAIL,
} from '../actionTypes'
import {
  GetClinicRequest,
  GetClinicRequestSuccess,
  GetClinicRequestFailure,
  getClinicRequestPayload,
  getClinicRequestSuccessPayload,
  getClinicFailurePayload,
  ViewClinicRequest,
  ViewClinicRequestSuccess,
  ViewClinicRequestFailure,
  ViewClinicRequestPayload,
  ViewClinicRequestSuccessPayload,
  ViewClinicFailurePayload,
  updateClinicStatusLocalPayload,
  UpdateClinicStatusLocal,
  ClinicStatusUpdateRequestPayload,
  ClinicStatusUpdateRequestSuccessPayload,
  ClinicStatusUpdateFailurePayload,
  ClinicStatusUpdateRequest,
  ClinicStatusUpdateRequestSuccess,
  ClinicStatusUpdateRequestFailure,
  GetPracticeInformationRequestPayload,
  GetPracticeInformationRequest,
  GetPracticeInformationRequestSuccessPayload,
  GetPracticeInformationRequestSuccess,
  GetPracticeInformationRequestFailurePayload,
  GetPracticeInformationRequestFailure,
  PutMsaAgreementRequestPayload,
  PutMsaAgreementRequest,
  PutMsaAgreementRequestSuccessPayload,
  PutMsaAgreementRequestSuccess,
  PutMsaAgreementRequestFailurePayload,
  PutMsaAgreementRequestFailure,
  ChangeMsaAgreementPayload,
  ChangeMsaAgreementRequest,
  UpdateMsaAgreementValidationPayload,
  UpdateMsaAgreementValidationRequest,
  GetMsaAgreementRequestPayload,
  GetMsaAgreementRequest,
  GetMsaAgreementRequestSuccessPayload,
  GetMsaAgreementRequestSuccess,
  GetMsaAgreementRequestFailurePayload,
  GetMsaAgreementRequestFailure,
} from '../../schema/clinic/clinicSchema'

//get clinic list
export const getClinicRequest = (payload: getClinicRequestPayload): GetClinicRequest => ({
  type: GET_CLINIC_REQUEST,
  payload,
})
export const getClinicRequestSuccess = (payload: getClinicRequestSuccessPayload): GetClinicRequestSuccess => ({
  type: GET_CLINIC_REQUEST_SUCCESS,
  payload,
})
export const getClinicRequestFailure = (payload: getClinicFailurePayload): GetClinicRequestFailure => ({
  type: GET_CLINIC_REQUEST_FAILURE,
  payload,
})
export const getClinicCacheDataClearRequest = (): any => ({
  type: GET_CLINIC_CACHE_DATA_CLEAR_REQUEST,
})

export const updateClinicStatusLocalRequest = (payload: updateClinicStatusLocalPayload): UpdateClinicStatusLocal => ({
  type: UPDATE_CLINIC_STATUS_LOCAL,
  payload,
})
//view clinic
export const viewClinicRequest = (payload: ViewClinicRequestPayload): ViewClinicRequest => ({
  type: VIEW_CLINIC_REQUEST,
  payload,
})
export const viewClinicRequestSuccess = (payload: ViewClinicRequestSuccessPayload): ViewClinicRequestSuccess => ({
  type: VIEW_CLINIC_REQUEST_SUCCESS,
  payload,
})
export const viewClinicRequestFailure = (payload: ViewClinicFailurePayload): ViewClinicRequestFailure => ({
  type: VIEW_CLINIC_REQUEST_FAILURE,
  payload,
})
export const viewClinicCacheDataClearRequest = (): any => ({
  type: VIEW_CLINIC_CACHE_DATA_CLEAR_REQUEST,
})

//update clinic status
export const clinicUpdateStatusRequest = (payload: ClinicStatusUpdateRequestPayload): ClinicStatusUpdateRequest => ({
  type: UPDATE_CLINIC_STATUS_REQUEST,
  payload,
})
export const clinicUpdateStatusRequestSuccess = (payload: ClinicStatusUpdateRequestSuccessPayload): ClinicStatusUpdateRequestSuccess => ({
  type: UPDATE_CLINIC_STATUS_REQUEST_SUCCESS,
  payload,
})
export const clinicUpdateStatusRequestFailure = (payload: ClinicStatusUpdateFailurePayload): ClinicStatusUpdateRequestFailure => ({
  type: UPDATE_CLINIC_STATUS_REQUEST_FAILURE,
  payload,
})

// practice information
export const getPracticeInformationRequest = (payload: GetPracticeInformationRequestPayload): GetPracticeInformationRequest => ({
  type: GET_PRACTICE_INFORMATION_REQUEST,
  payload,
})
export const getPracticeInformationRequestSuccess = (
  payload: GetPracticeInformationRequestSuccessPayload
): GetPracticeInformationRequestSuccess => ({
  type: GET_PRACTICE_INFORMATION_REQUEST_SUCCESS,
  payload,
})
export const getPracticeInformationRequestFailure = (
  payload: GetPracticeInformationRequestFailurePayload
): GetPracticeInformationRequestFailure => ({
  type: GET_PRACTICE_INFORMATION_REQUEST_FAIL,
  payload,
})

// MSA Agreement
export const putMsaAgreementRequest = (payload: PutMsaAgreementRequestPayload): PutMsaAgreementRequest => ({
  type: PUT_MSA_AGREEMENT_REQUEST,
  payload,
})
export const putMsaAgreementRequestSuccess = (payload: PutMsaAgreementRequestSuccessPayload): PutMsaAgreementRequestSuccess => ({
  type: PUT_MSA_AGREEMENT_REQUEST_SUCCESS,
  payload,
})
export const putMsaAgreementRequestFailure = (payload: PutMsaAgreementRequestFailurePayload): PutMsaAgreementRequestFailure => ({
  type: PUT_MSA_AGREEMENT_REQUEST_FAIL,
  payload,
})

export const changeMsaAgreementRequest = (payload: ChangeMsaAgreementPayload): ChangeMsaAgreementRequest => ({
  type: CHANGE_MSA_AGREEMENT,
  payload,
})
export const updateMsaAgreementValidationRequest = (payload: UpdateMsaAgreementValidationPayload): UpdateMsaAgreementValidationRequest => ({
  type: UPDATE_MSA_AGREEMENT_VALIDATION,
  payload,
})

export const getMsaAgreementRequest = (payload: GetMsaAgreementRequestPayload): GetMsaAgreementRequest => ({
  type: GET_MSA_AGREEMENT_REQUEST,
  payload,
})
export const getMsaAgreementRequestSuccess = (payload: GetMsaAgreementRequestSuccessPayload): GetMsaAgreementRequestSuccess => ({
  type: GET_MSA_AGREEMENT_REQUEST_SUCCESS,
  payload,
})
export const getMsaAgreementRequestFailure = (payload: GetMsaAgreementRequestFailurePayload): GetMsaAgreementRequestFailure => ({
  type: GET_MSA_AGREEMENT_REQUEST_FAIL,
  payload,
})
