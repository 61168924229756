import {
    GET_MODULES_REQUEST,
    GET_MODULES_REQUEST_SUCCESS,
    GET_MODULES_REQUEST_FAILURE,
} from '../../actions/actionTypes'
import {
    ModuleInterface,
    getModulesAction
} from '../../schema/modules/modulesSchema'

const initialState: ModuleInterface = {
    moduleLoader: false,
    moduleData: null,
    moduleError: null,
}

const moduleReducer = (
    state = initialState,
    action: getModulesAction
) => {
    switch (action.type) {
        case GET_MODULES_REQUEST:
            return {
                ...state,
                moduleLoader: true
            }
        case GET_MODULES_REQUEST_SUCCESS:
            return {
                ...state,
                moduleLoader: false,
                moduleData: action?.payload?.moduleData,
                moduleError: null
            }
        case GET_MODULES_REQUEST_FAILURE:
            return {
                ...state,
                moduleLoader: false,
                moduleData: null,
                moduleError: action?.payload?.moduleError
            }
        default:
            return {
                ...state
            };
    }
}

export default moduleReducer