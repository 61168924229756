import {
  GET_APPOINTMENT_REQUEST,
  GET_APPOINTMENT_REQUEST_SUCCESS,
  GET_APPOINTMENT_REQUEST_FAILURE,
  GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST,
} from '../../actions/actionTypes'
import { AppointmentInterface, AppointmentActions } from '../../schema/appointment/appointmentScheme'

const initialState: AppointmentInterface = {
  getAppointmentLoader: false,
  getAppointmentData: null,
  getAppointmentError: null,
}

const appointmentReducer = (state = initialState, action: AppointmentActions) => {
  switch (action.type) {
    case GET_APPOINTMENT_REQUEST:
      return {
        ...state,
        getAppointmentLoader: true,
      }
    case GET_APPOINTMENT_REQUEST_SUCCESS:
      return {
        ...state,
        getAppointmentLoader: false,
        getAppointmentData: action?.payload?.getAppointmentData,
        getAppointmentMeta: action.payload?.getAppointmentMeta,
        getAppointmentError: null,
      }
    case GET_APPOINTMENT_REQUEST_FAILURE:
      return {
        ...state,
        getAppointmentLoader: false,
        getAppointmentData: null,
        getAppointmentError: action?.payload?.getAppointmentError,
      }
    case GET_APPOINTMENT_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getAppointmentData: null,
      }
    default:
      return {
        ...state,
      }
  }
}

export default appointmentReducer
