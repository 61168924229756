import {
  GET_OWNER_REQUEST,
  GET_OWNER_REQUEST_SUCCESS,
  GET_OWNER_REQUEST_FAILURE,
  GET_OWNER_CACHE_DATA_CLEAR_REQUEST,
  VIEW_OWNER_REQUEST,
  VIEW_OWNER_REQUEST_SUCCESS,
  VIEW_OWNER_REQUEST_FAILURE,
  VIEW_OWNER_CACHE_DATA_CLEAR_REQUEST,
  UPDATE_OWNER_STATUS_REQUEST,
  UPDATE_OWNER_STATUS_REQUEST_SUCCESS,
  UPDATE_OWNER_STATUS_REQUEST_FAILURE,
  UPDATE_OWNER_STATUS_LOCAL,
  GET_CLINIC_LIST_BY_OWNER_REQUEST,
  GET_CLINIC_LIST_BY_OWNER_REQUEST_FAILURE,
  GET_CLINIC_LIST_BY_OWNER_REQUEST_SUCCESS,
} from '../../actions/actionTypes'
import { OwnerInterface, OwnerActions } from '../../schema/owner/ownerSchema'

const initialState: OwnerInterface = {
  getOwnerLoader: false,
  getOwnerData: null,
  getOwnerError: null,
  viewOwnerLoader: false,
  viewOwnerData: null,
  viewOwnerError: null,
  ownerStatusUpdateLoader: false,
  ownerStatusUpdateData: null,
  ownerStatusUpdateError: null,
  getClinicListOwnerLoader: false,
  getClinicListOwnerData: null,
  getClinicListOwnerError: null,
}

const ownerReducer = (state = initialState, action: OwnerActions) => {
  switch (action.type) {
    case GET_OWNER_REQUEST:
      return {
        ...state,
        getOwnerLoader: true,
      }
    case GET_OWNER_REQUEST_SUCCESS:
      return {
        ...state,
        getOwnerLoader: false,
        getOwnerData: action?.payload?.getOwnerData,
        getOwnerMeta: action?.payload?.getOwnerMeta,
        getOwnerError: null,
      }
    case GET_OWNER_REQUEST_FAILURE:
      return {
        ...state,
        getOwnerLoader: false,
        getOwnerData: null,
        getOwnerError: action?.payload?.getOwnerError,
      }
    case GET_OWNER_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getOwnerData: null,
      }
    case VIEW_OWNER_REQUEST:
      return {
        ...state,
        viewOwnerLoader: true,
      }
    case VIEW_OWNER_REQUEST_SUCCESS:
      return {
        ...state,
        viewOwnerLoader: false,
        viewOwnerData: action.payload?.viewOwnerData,
        viewOwnerError: null,
      }
    case VIEW_OWNER_REQUEST_FAILURE:
      return {
        ...state,
        viewOwnerLoader: false,
        viewOwnerData: null,
        viewOwnerError: action.payload.viewOwnerError,
      }
    case VIEW_OWNER_CACHE_DATA_CLEAR_REQUEST:
      return {
        ...state,
        getOwnerData: null,
      }
    case UPDATE_OWNER_STATUS_LOCAL:
      let getOwner = state.getOwnerData
      getOwner = getOwner?.map((res: any, i: number) => {
        if (i === action?.payload?.index) {
          return {
            ...res,
            status: res?.status === 'Active' ? 'Inactive' : 'Active',
          }
        }
        return res
      })
      return {
        ...state,
        getOwnerData: getOwner,
      }
    case UPDATE_OWNER_STATUS_REQUEST:
      return {
        ...state,
        ownerStatusUpdateLoader: true,
      }
    case UPDATE_OWNER_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        ownerStatusUpdateLoader: false,
        ownerStatusUpdateData: action.payload,
        ownerStatusUpdateError: null,
      }
    case UPDATE_OWNER_STATUS_REQUEST_FAILURE:
      return {
        ...state,
        ownerStatusUpdateLoader: false,
        ownerStatusUpdateData: null,
        ownerStatusUpdateError: action.payload.ownerStatusUpdateError,
      }
    case GET_CLINIC_LIST_BY_OWNER_REQUEST:
      return {
        ...state,
        getClinicListOwnerLoader: true,
      }
    case GET_CLINIC_LIST_BY_OWNER_REQUEST_SUCCESS:
      return {
        ...state,
        getClinicListOwnerLoader: false,
        getClinicListOwnerData: action?.payload?.getClinicListOwnerData,
        getClinicListOwnerMeta: action?.payload?.getClinicListOwnerMeta,
        getClinicListOwnerError: null,
      }
    case GET_CLINIC_LIST_BY_OWNER_REQUEST_FAILURE:
      return {
        ...state,
        getClinicListOwnerLoader: false,
        getClinicListOwnerData: null,
        getClinicListOwnerError: action?.payload?.getClinicListOwnerError,
      }
    default:
      return {
        ...state,
      }
  }
}

export default ownerReducer
